import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { fetchPendingConsentsThunk, updatePatientConsentChoiceThunk, updateConsentAlertFlagThunk } from '~/redux/thunks/users';
import { signedInByToken } from '~/utils/I18n/FromRails';
import UserHelper from '~/redux/modules/users/UserHelper';
import GuardianConsentPendingContainer from './GuardianConsentPending/GuardianConsentPendingContainer';
import ConsentsManagerPresenter from './ConsentsManagerPresenter';
import MinorToMajorModal from '../../shared/components/MinorToMajorModal/MinorToMajorModal';

const mapStateToProps = (state) => (
  {
    pendingConsents: UserHelper.userPendingConsents(state),
    isPatient: UserHelper.currentUserIsPatient(state),
    isMinor: UserHelper.currentUserIsMinor(state),
    guardianEmail: UserHelper.getGuardianEmail(state),
    currentUser: UserHelper.currentUser(state),
  }
);

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchPendingConsentsThunk,
      updatePatientConsentChoiceThunk,
      updateConsentAlertFlagThunk,
    },
    dispatch,
  ),
});

export class ConsentsManagerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { fetchedConsents: false };
  }

  static propTypes = {
    pendingConsents: PropTypes.array,
    isPatient: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired,
    isMinor: PropTypes.bool.isRequired,
    guardianEmail: PropTypes.string,
    actions: PropTypes.shape({
      fetchPendingConsentsThunk: PropTypes.func.isRequired,
      updatePatientConsentChoiceThunk: PropTypes.func.isRequired,
      updateConsentAlertFlagThunk: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    pendingConsents: [],
    isPatient: false,
    isMinor: false,
    guardianEmail: '',
  };

  shouldFetchConsents = () => !!(this.props.isPatient && this.props.currentUser.id)

  componentDidMount() {
    if (this.shouldFetchConsents() && !signedInByToken) {
      this.setState({ fetchedConsents: true });
      this.props.actions.fetchPendingConsentsThunk(this.props.isMinor);
    }
  }

  componentDidUpdate() {
    if (!this.state.fetchedConsents && this.shouldFetchConsents() && !signedInByToken) {
      this.setState({ fetchedConsents: true });
      this.props.actions.fetchPendingConsentsThunk(this.props.isMinor);
    }
  }

  render() {
    const { pendingConsents, isMinor, guardianEmail, actions } = this.props;
    return (
      <div>
        <GuardianConsentPendingContainer />
        <ConsentsManagerPresenter
          pendingConsents={pendingConsents}
          isMinor={isMinor}
          guardianEmail={guardianEmail}
          actions={actions}
        />
        {this.props.isPatient && <MinorToMajorModal />}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsManagerContainer);
