import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment, { isMoment } from 'moment';
import { Tooltip } from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { values, isEmpty } from 'lodash';
import { Alert, Button } from '@glooko/common-ui';
import { PDF_TIMEFRAMES, CUSTOM_RANGE, SECONDS_IN_DAY } from '~/bundles/shared/constants/time';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import detectBrowser from '~/utils/detectBrowser';
import { readingsTypeChangedThunk, updatePdfWizardTimeframeThunk } from '~/redux/thunks/page';
import { wizardFetchFavorites, wizardSaveFavorite, wizardUpdateFavorite } from '~/redux/thunks/favorites/favorites';
import { updateTimestampsPdfWizard } from '~/redux/modules/page/page';
import { fetchDeviceTimeOffsetsThunk } from '~/redux/thunks/deviceSettings';
import { fetchSubscription } from '~/services/subscriptionApi';
import ProviderGroupSiteHelper from '~/redux/modules/providerGroupSite/ProviderGroupSiteHelper';
import ImageStore from '~/utils/ImageStore';
import { createPDF, createPDFWithLoader } from '~/services/graphsApi';
import Colors from '~/../assets/styles/export/colors';
import { trackPdfWizardFavoriteSelected, trackCreatedPdfReport } from '~/services/eventLogging';
import { signedInByToken } from '~/utils/I18n/FromRails';
import UserHelper from '~/redux/modules/users/UserHelper';
import GraphOptionsItem from '../GraphOptions/GraphOptionsItem/GraphOptionsItem';
import SelectFavoriteReport from './SelectFavoriteReport/SelectFavoriteReport';
import Style from './PdfWizard.scss';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import PdfWizardDateRangeSelectContainer from
  '~/bundles/shared/components/ControllerBarPresenter/PdfWizardDateRangeSelectContainer/PdfWizardDateRangeSelectContainer';
import { GreyOverlay } from '~/bundles/shared/components/Overlay/Overlay';
import { pageCountCalculator } from
  '~/bundles/pdf/components/WeekView/WeekViewPdfContainer/WeekViewPdfContainer';
import PdfWizardCalendar from './PdfWizardCalendar/PdfWizardCalendar';
import { WEEK_VIEW_PDF_MEDIUM } from
  '~/bundles/shared/components/graphs/hoursGraphConfig';

const translateMapper = (t, key, params) => {
  switch (key) {
    // pages
    case 'calendar':
      return t('calendar');
    case 'bgOverlay':
      return t('bgOverlay');
    case 'cgmOverlay':
      return t('cgmOverlay');
    case 'bgSummary':
      return t('bgSummary');
    case 'cgmSummary':
      return t('cgmSummary');
    case 'logbook':
      return t('logbook');
    case 'overview':
      return t('overview');
    case 'dailyOverview':
      return t('dailyOverview');
    case 'insights':
      return t('insights');
    case 'devices':
      return t('devices');
    case 'weekView':
      return t('weekView');
    // other
    case 'page':
      // i18n tasks hint: t('page_other');
      return t('page', params);
    default:
      return null;
  }
};

const options = [
  {
    id: 'bgSummary',
    img: ImageStore.summary,
    class: 'bgSummary',
    hidden: () => false,
    reportTypeName: 'summary_bg',
  }, {
    id: 'cgmSummary',
    img: ImageStore.summary,
    class: 'cgmSummary',
    hidden: () => true,
    reportTypeName: 'summary_cgm',
  }, {
    id: 'logbook',
    img: ImageStore.logbook,
    class: 'logbook',
    hidden: () => false,
    reportTypeName: 'logbook',
  }, {
    id: 'overview',
    img: ImageStore.overview,
    class: 'overview',
    hidden: () => false,
    reportTypeName: 'overview',
  }, {
    id: 'dailyOverview',
    img: ImageStore.dailyOverview,
    class: 'dailyOverview',
    hidden: () => false,
    reportTypeName: 'daily_overview',
  }, {
    id: 'weekView',
    img: ImageStore.dailyOverview,
    class: 'weekView',
    hidden: () => false,
    reportTypeName: 'week_view',
  }, {
    id: 'bgOverlay',
    img: ImageStore.overlay,
    class: 'bgOverlay',
    hidden: () => false,
    reportTypeName: 'overlay_bg',
  }, {
    id: 'cgmOverlay',
    img: ImageStore.overlay,
    class: 'cgmOverlay',
    hidden: () => true,
    reportTypeName: 'overlay_cgm',
  }, {
    id: 'calendar',
    img: ImageStore.calendar,
    class: 'calendar',
    hidden: () => false,
    reportTypeName: 'calendar',
  }, {
    id: 'insights',
    img: ImageStore.insights,
    class: 'insights',
    hidden: (props) => !props.hasPump,
    reportTypeName: 'insights',
  }, {
    id: 'devices',
    img: ImageStore.devices,
    class: 'devices',
    hidden: () => false,
    reportTypeName: 'devices',
  },
];

const BgOnlyReports = ['overlay_bg', 'summary_bg'];
const CgmOnlyReports = ['overlay_cgm', 'summary_cgm'];

const getPatientGlookoCode = (state) => {
  if (UserHelper.currentUserIsPatient(state)) {
    return UserHelper.currentUser(state).glookoCode;
  } else if (UserHelper.currentPatient(state)) {
    return UserHelper.currentPatient(state).glookoCode;
  }
  return null;
};

const mapStateToProps = (state) => {
  const hasCgm = UserHelper.hasCgmDevice(state);
  const hasBg = UserHelper.hasBG(state);
  const hasPump = UserHelper.hasPump(state);
  const isOnlyBg = (hasPump || hasBg) && !hasCgm;
  const isOnlyCgm = !(hasPump || hasBg) && hasCgm;

  return {
    endDate: state.page.endDatePdfWizard || state.page.endDate || moment.utc().toISOString(),
    startDate: state.page.startDatePdfWizard || state.page.startDate || moment.utc().toISOString(),
    maxDate: state.page.endDatePDF,
    currentUserIsProvider: !UserHelper.currentUserIsPatient(state),
    patientId: UserHelper.displayUser(state).id,
    patientFullName: UserHelper.userFullName(state),
    glookoCode: getPatientGlookoCode(state),
    userDateOfBirth: UserHelper.userDateOfBirth(state),
    userTypeOfDiabetes: UserHelper.userTypeOfDiabetes(state),
    syncTimestamps: UserHelper.lastSyncTimestampStore(state),
    datepickerEnabled: state.page.pdfWizardDatepickerEnabled,
    timeFrameIndexPdfWizard: state.page.timeFrameIndexPdfWizard,
    favorites: state.favorites.favorites,
    hasCgm,
    hasBg,
    hasPump,
    isOnlyBg,
    isOnlyCgm,
    deviceTimeOffsets: state.devices.deviceTimeOffsets,
    timeFramePdfWizard: state.page.timeFramePdfWizard,
    pageID: state.page.id,
    isPgsIpgRestricted: ProviderGroupSiteHelper.hasSubscriptionFeature(state, 'IPG Restriction') ||
      ProviderGroupSiteHelper.hasSubscriptionFeature(state, 'Glooko Sponsored Solution'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      readingsTypeChangedThunk,
      updateTimestampsPdfWizard,
      wizardFetchFavorites,
      wizardSaveFavorite,
      wizardUpdateFavorite,
      updatePdfWizardTimeframeThunk,
      fetchDeviceTimeOffsetsThunk,
    },
    dispatch,
  ),
});

const MAX_DATE_RANGE = 90;

class PdfWizard extends React.Component {
  static propTypes = {
    datepickerEnabled: PropTypes.bool,
    t: PropTypes.func.isRequired,
    patientFullName: PropTypes.string.isRequired,
    glookoCode: PropTypes.string.isRequired,
    userDateOfBirth: PropTypes.string,
    userTypeOfDiabetes: PropTypes.string,
    visibilityCallback: PropTypes.func.isRequired,
    hasBg: PropTypes.bool.isRequired,
    hasCgm: PropTypes.bool.isRequired,
    hasPump: PropTypes.bool.isRequired,
    isOnlyBg: PropTypes.bool.isRequired,
    isOnlyCgm: PropTypes.bool.isRequired,
    visible: PropTypes.bool.isRequired,
    patientId: PropTypes.string.isRequired,
    currentUserIsProvider: PropTypes.bool.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      readingsTypeChangedThunk: PropTypes.func.isRequired,
      updateTimestampsPdfWizard: PropTypes.func.isRequired,
      updatePdfWizardTimeframeThunk: PropTypes.func.isRequired,
      wizardFetchFavorites: PropTypes.func.isRequired,
      fetchDeviceTimeOffsetsThunk: PropTypes.func.isRequired,
    }),
    favorites: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      reportTypes: PropTypes.arrayOf(PropTypes.string),
      color: PropTypes.bool,
      numberOfDays: PropTypes.number,
      id: PropTypes.string.isRequired,
    })),
    editFavouriteMode: PropTypes.bool.isRequired,
    initialSelectedOptions: PropTypes.shape({}).isRequired,
    deviceTimeOffsets: PropTypes.array.isRequired,
    timeFramePdfWizard: PropTypes.number.isRequired,
    pageID: PropTypes.string.isRequired,
    isPgsIpgRestricted: PropTypes.bool,
    createPdfWithSpinner: PropTypes.bool,
  };

  static defaultProps = {
    userDateOfBirth: null,
    datepickerEnabled: true,
    maxDate: null,
    syncTimestamps: null,
    userTypeOfDiabetes: null,
    currentUserIsProvider: true,
    onDatePickerDatesChange: () => { },
    onDatePickerFocusChange: () => { },
    t: () => { },
    actions: {
      readingsTypeChangedThunk: () => { },
      updateTimestampsPdfWizard: () => { },
      updatePdfWizardTimeframeThunk: () => { },
      wizardFetchFavorites: () => { },
      fetchMeterTimeOffsetsThunk: () => { },
    },
    favorites: [],
    editFavouriteMode: false,
    initialSelectedOptions: {},
    isPgsIpgRestricted: false,
    createPdfWithSpinner: !detectBrowser.isIE(),
  };

  constructor(props) {
    super(props);
    this.state = {
      ...(options.reduce((accumulator, currentItem) => ({
        ...accumulator,
        [currentItem.id]: false,
      }), {})),
      html: false,
      numOfPages: 0,
      bgSummaryChecked: this.props.hasBg,
      cgmSummaryChecked: this.props.hasCgm,
      bgOverlayChecked: this.props.isOnlyBg,
      cgmOverlayChecked: this.props.hasCgm,
      bgSummaryHover: true,
      bgOverlayHover: true,
      calendarHover: true,
      logbookHover: true,
      overviewHover: true,
      dailyOverviewHover: true,
      weekViewHover: true,
      insightsHover: true,
      devicesHover: true,
      bgSummaryShowSelection: false,
      bgOverlayShowSelection: false,
      estimatedReportLength: 0,
      color: true,
      comment: '',
      startDateOnDatepicker: moment.utc(this.props.startDate),
      endDateOnDatepicker: moment.utc(this.props.endDate),
      isFavChecked: false,
      inputName: '',
      isFavSaved: false,
      activeFav: {
        reportTypes: [],
        numberOfDays: -1,
        name: '',
        color: null,
      },
      nameEditEnabled: false,
      nameEdited: '',
      checkingIpgRestricted: false,
      isPatientIpgRestricted: false,
      createPdfClicked: false,
      weekViewGraphSize: WEEK_VIEW_PDF_MEDIUM,
    };

    this.handleFavName = this.handleFavName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectFavoriteReport = this.selectFavoriteReport.bind(this);
    this.buildCorrectSelectionsForFavoriteSetting = this.buildCorrectSelectionsForFavoriteSetting.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.actions.wizardFetchFavorites();
    this.checkIfPatientIpgRestricted(this.props.patientId);
  }

  componentDidMount() {
    const { glookoCode, pageID } = this.props;
    if (pageID === 'PAGE_SUMMARY') return;
    if (glookoCode) { this.props.actions.fetchDeviceTimeOffsetsThunk({ patient: glookoCode }); }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { initialSelectedOptions } = nextProps;
    if (!this.props.visible && nextProps.visible && nextProps.editFavouriteMode) {
      this.selectFavoriteReport(initialSelectedOptions);
      this.setState({
        inputName: initialSelectedOptions.name,
        nameEdited: initialSelectedOptions.name,
        nameEditEnabled: !initialSelectedOptions.name.length,
      });
      if (initialSelectedOptions && initialSelectedOptions.numberOfDays) {
        this.props.actions.updatePdfWizardTimeframeThunk(initialSelectedOptions.numberOfDays);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;
    const isStartDateUpdated = moment(prevProps.startDate).isSame(startDate);
    const isEndDateUpdated = moment(prevProps.endDate).isSame(endDate);
    if (!isStartDateUpdated || !isEndDateUpdated) {
      this.updatePageCount();
    }
  }

  displaySummaryCommentBox = () => {
    if (this.props.editFavouriteMode) {
      return true;
    } else if (this.state.bgSummaryShowSelection) {
      return false;
    } else if (!this.state.bgSummaryHover) {
      return false;
    }
    return true;
  }

  changeProperty = (event) => {
    const name = event.target.getAttribute('name');
    const current = this.state[name];
    const id = event.target.id;
    const nameConv = `${name}ShowSelection`;

    const { isOnlyBg, isOnlyCgm } = this.props;
    if (name === 'checkbox') {
      const currentItemType = `${id}Checked`;
      this.setState({
        [currentItemType]: !this.state[currentItemType],
      }, () => this.deselectCurrentItem(id));
      if (this.state[`${id.replace('cgm', 'bg')}ShowSelection`]) {
        this.setState({
          [id]: !this.state[id],
        });
      }
    }
    if (name === 'bgSummary' || name === 'bgOverlay') {
      if (this.state[`${name}Checked`] || isOnlyBg) {
        this.setState({
          [nameConv]: !this.state[nameConv],
          [name]: true,
          hideCommentBox: !this.state[nameConv],
        }, () => this.updatePageCount());
      }
      if (this.state[`${name.replace('bg', 'cgm')}Checked`] || isOnlyCgm) {
        this.setState({
          [nameConv]: !this.state[nameConv],
          [name.replace('bg', 'cgm')]: true,
        }, () => this.updatePageCount());
      }
      if (this.state[nameConv]) {
        this.setState({
          [nameConv]: !this.state[nameConv],
          [name]: false,
          [name.replace('bg', 'cgm')]: false,
        }, () => this.updatePageCount());
      }
    } else {
      this.setState({ [name]: !current }, () => this.updatePageCount());
    }
  }

  itemRanges = (id) => {
    switch (id) {
      case 'bgOverlay':
      case 'cgmOverlay':
      case 'bgSummary':
      case 'cgmSummary':
      case 'devices': {
        return 1;
      }
      case 'insights': {
        return 3;
      }
      case 'calendar': {
        return moment.utc(this.props.endDate).endOf('isoWeek').diff(
          moment.utc(this.props.startDate).startOf('isoWeek'),
          'weeks',
        ) + 1;
      }
      case 'logbook': {
        return Math.ceil((moment.utc(this.props.endDate).diff(
          moment.utc(this.props.startDate),
          'days') + 1) / 7);
      }
      case 'overview': {
        return Math.ceil((moment.utc(this.props.endDate).diff(
          moment.utc(this.props.startDate),
          'days') + 1) / 30);
      }
      case 'dailyOverview': {
        const maxDate = moment.min(
          moment.utc(this.props.syncTimestamps.lastSyncTimestamp || this.props.endDate).endOf('day'),
          moment.utc(this.props.endDate).endOf('day'),
        );
        return moment.utc(maxDate).diff(
          moment.utc(this.props.startDate),
          'days') + 1;
      }
      case 'weekView': {
        const end = moment.utc(this.props.endDate).endOf('day').add(1, 'second');
        const start = moment.utc(this.props.startDate).startOf('day');

        return pageCountCalculator(
          end.diff(start, 'days'), this.state.weekViewGraphSize,
        );
      }
      default: {
        return 0;
      }
    }
  };

  updateHover = (event) => {
    const name = event.target.getAttribute('name');
    if (event.type === 'mouseenter') {
      this.setState({ [`${name}Hover`]: false });
    } else if (event.type === 'mouseleave') {
      this.setState({ [`${name}Hover`]: true });
    }
  }

  isHovering(id) {
    return this.state[`${id}Hover`];
  }

  hiddenPageCountForOption = (id) => {
    if (id === 'bgSummary') {
      return !this.state.bgSummaryShowSelection;
    }
    if (id === 'bgOverlay') {
      return !this.state.bgOverlayShowSelection;
    }
    return !this.state[id];
  }

  toggleHover = (id) => {
    const hoverStyle = this.isStacked(id) ? Style.hoverS : Style.hover;
    return !this.hiddenPageCountForOption(id) ? Style.hideHover : hoverStyle;
  }

  toggleLabelColor(id) {
    return this.hiddenPageCountForOption(id) ? Style.itemLabel : Style.itemLabelSelected;
  }

  createReportButtonDisabled = () => {
    const listOfItems = this.filterItemsFromState();
    return (
      values(listOfItems).filter((s) => s).length < 1 ||
      this.state.checkingIpgRestricted ||
      (this.state.createPdfClicked && this.state.isPatientIpgRestricted)
    );
  }

  createReportButtonStyle() {
    return this.createReportButtonDisabled() ? Style.btnCreateDisabled : Style.btnCreate;
  }

  isStacked(id) {
    return this.numberOfPages(id) >= 2;
  }

  numberOfPages = (id) => {
    let overall = 0;
    const idCgm = `${id.replace('bg', 'cgm')}`;
    const idCgmChecked = `${idCgm}Checked`;
    const idChecked = `${id}Checked`;

    if (id === 'bgSummary' || id === 'bgOverlay') {
      if (this.state[idChecked]) {
        overall += this.itemRanges(id);
      }
      if (this.state[idCgmChecked]) {
        overall += this.itemRanges(idCgm);
      }
      if (!this.state[idChecked] && !this.state[idCgmChecked]) {
        overall = 1;
      }
    } else {
      overall += this.itemRanges(id);
    }
    return overall;
  }

  updatePageCount = () => {
    const reportLength = options.reduce((accumulator, option) => (
      accumulator + (this.state[option.id] ? this.itemRanges(option.id) : 0)
    ), 0);
    this.setState({ estimatedReportLength: reportLength });
  }

  itemIconApperance = (id) => {
    const itemName = id.replace('cgm', '').replace('bg', '');
    const itemNameFixed = itemName[0].toLowerCase() + itemName.substring(1);
    const getImage = this.isStacked(id) ? `${itemNameFixed}Stack` : itemNameFixed;
    return ImageStore[getImage];
  }

  filterItemsFromState() {
    return (options.reduce((accumulator, currentItem) => ({
      ...accumulator,
      [currentItem.id]: this.state[currentItem.id],
    }), {}));
  }

  apiCallParams = () => ({
    patient: this.props.patientId,
    endDate: moment.utc(this.props.endDate).toISOString(),
    startDate: moment.utc(this.props.startDate).toISOString(),
    ...this.filterItemsFromState(),
    weekViewGraphSize: this.state.weekViewGraphSize,
    html: this.state.html,
    color: this.state.color,
    quickNote: this.state.bgSummaryShowSelection ? this.state.comment : '',
  });

  eventPayload = (params) => ({
    time_range_end: params.endDate,
    time_range_start: params.startDate,
    time_range: PDF_TIMEFRAMES[this.props.timeFrameIndexPdfWizard].statName,
    report_content: Object.keys(params).filter((key) => params[key] === true && key !== 'color'),
  });

  async checkIfPatientIpgRestricted(patient) {
    if (this.props.isPgsIpgRestricted && this.props.currentUserIsProvider) {
      this.setState({ checkingIpgRestricted: true });
      const response = await fetchSubscription({ patient });
      this.setState({ checkingIpgRestricted: false });
      if (response?.data?.ipgNoInsuletPump || response?.data?.gssNoDevice) {
        this.setState({ isPatientIpgRestricted: true });
      }
    }
  }

  createPdfInNewWindow = () => {
    const { isPgsIpgRestricted } = this.props;
    const { isPatientIpgRestricted } = this.state;
    this.setState({ createPdfClicked: true });
    if (isPgsIpgRestricted && isPatientIpgRestricted) {
      return;
    }

    this.initiateCreatePdf();
  }

  initiateCreatePdf = () => {
    const params = this.apiCallParams();
    trackCreatedPdfReport(this.eventPayload(params));

    if (this.props.createPdfWithSpinner) {
      createPDFWithLoader(params);
      return;
    }

    createPDF(params);
  }

  deselectCurrentItem = (id) => {
    const item = id.replace(/cgm|bg/, '');
    if (!this.state[`bg${item}Checked`] && !this.state[`cgm${item}Checked`]) {
      this.setState({ [`bg${item}ShowSelection`]: false }, () => this.updatePageCount());
    }
  }

  toggleCheckBg = (event) => {
    const id = event.target.id;
    this.setState({ [`${id}Checked`]: !this.state[`${id}Checked`] });
  }

  toggleCheckCgm = () => {
    this.setState({ isCheckedCgm: !this.state.isCheckedCgm });
  }

  setWeekViewGraphSize = (event) => {
    this.setState({ weekViewGraphSize: event.currentTarget.value });
  }

  displayPageCount = (id) => {
    if (this.props.editFavouriteMode) {
      return false;
    } else if (!this.isHovering(id)) {
      return true;
    } else if (this.hiddenPageCountForOption(id)) {
      return false;
    }
    return true;
  }

  togglePrintMode = (mode) => {
    this.setState({ color: mode });
  }

  handleText = (event) => {
    this.setState({ comment: event.target.value });
  }

  visibilityCallback = () => {
    this.props.visibilityCallback(false);
  }

  renderBgCgmCheckbox = (id) => {
    const cgmId = id.replace('bg', 'cgm');
    if (id === 'bgSummary' || id === 'bgOverlay') {
      return (
        <div
          name={id}
          class={classNames({
            [Style.checkboxContainer]: !this.isStacked(id),
            [Style.checkboxContainerStacked]: this.isStacked(id),
            [Style.hiddenCgm]: this.props.isOnlyBg && !this.props.editFavouriteMode,
            [Style.hiddenBg]: this.props.isOnlyCgm && !this.props.editFavouriteMode,
          })}
          hidden={this.isHovering(id)}
        >
          <div
            class={classNames({
              [Style.checkboxSet]: true,
            })}
            id={id}
            onClick={this.toggleCheckBg}
            role="presentation"
          >
            <img
              id={id}
              name="checkbox"
              src={this.state[`${id}Checked`] ?
                ImageStore.selected1 :
                ImageStore.deselected1}
              alt=""
            />
            <span
              id={id}
              name="checkbox"
              class={Style.checkboxLabel}
              onClick={this.toggleCheckBg}
              role="presentation"
            >
              {UserHelper.retrieveLabel('bg')}
            </span>
          </div>
          <div
            id={cgmId}
            class={classNames({
              [Style.checkboxSet]: true,
            })}
            onClick={this.toggleCheckCgm}
            role="presentation"
          >
            <img
              id={cgmId}
              name="checkbox"
              src={this.state[`${cgmId}Checked`] ?
                ImageStore.selected1 :
                ImageStore.deselected1}
              alt=""
            />
            <span
              id={cgmId}
              name="checkbox"
              class={Style.checkboxLabel}
              onClick={this.toggleCheckCgm}
              role="presentation"
            >
              {UserHelper.retrieveLabel('cgm')}
            </span>
          </div>
          <div class={Style.overlay} />
        </div>
      );
    }
    return null;
  }

  // Will be enabled for release 24.5
  // renderWeekViewOptionSelection = (id) => {
  //   if (id !== 'weekView') return null;

  //   const clazz = classNames(Style.weekViewOptionSelectionContainer, {
  //     [Style.stacked]: this.isStacked(id),
  //   });

  //   return (
  //     <div
  //       className={clazz}
  //       hidden={this.isHovering(id)}
  //     >
  //       <RadioGroup
  //         className={Style.weekViewOptions}
  //         name="controlled"
  //         onChange={this.setWeekViewGraphSize}
  //         value={this.state.weekViewGraphSize}
  //       >
  //         <Radio label={this.props.t('compact')} value={WEEK_VIEW_PDF_SMALL} />
  //         <Radio label={this.props.t('standard')} value={WEEK_VIEW_PDF_MEDIUM} />
  //         <Radio label={this.props.t('expanded')} value={WEEK_VIEW_PDF_LARGE} />
  //       </RadioGroup>
  //       <div className={Style.overlay} />
  //     </div>
  //   );
  // }

  // Temporary replacement for the above
  renderWeekViewOptionSelection = () => null;

  renderDatePicker = () => {
    const start = moment.utc(this.props.startDate).format(TIME_FORMATS.MM_DD_YYYY);
    const end = moment.utc(this.props.endDate).format(TIME_FORMATS.MM_DD_YYYY);

    const validators = [
      {
        rule: (startDateValue, endDateValue) => {
          const datesSelected = isMoment(startDateValue) && isMoment(endDateValue);
          const rangeOverflow = datesSelected && endDateValue.diff(startDateValue, 'days') >= MAX_DATE_RANGE;

          return !rangeOverflow;
        },
        message: this.props.t('maxDateRangeError', { max_date_range: MAX_DATE_RANGE }),
      },
    ];

    if (this.props.datepickerEnabled) {
      return (
        <div class={Style.currentDateRange}>
          <PdfWizardCalendar
            updateTimestampsPdfWizard={this.props.actions.updateTimestampsPdfWizard}
            startDateInitialValue={moment.utc(this.props.startDate)}
            endDateInitialValue={moment.utc(this.props.endDate)}
            maxDate={this.props.maxDate}
            blockOutDates={this.props.currentUserIsProvider}
            validators={validators}
          />
        </div>
      );
    }
    return (
      <div class={Style.currentDateRange}>{start} - {end}</div>
    );
  }

  renderSummaryCommentInput() {
    return (
      <div class={Style.summaryCommentBoxContainer}>
        <div
          class={Style.summaryCommentBox}
          hidden={this.displaySummaryCommentBox()}
        >
          <textarea
            value={this.state.comment}
            placeholder={this.props.t('commentDefault')}
            onChange={this.handleText}
          />
        </div>
      </div>
    );
  }

  renderReportTypeItem() {
    const { t } = this.props;
    return (
      <div class={Style.itemsContainer}>
        {options.map((item) => (
          <div
            class={item.class}
            key={item.id}
            hidden={(item.id == 'insights') ?
              (!this.props.editFavouriteMode && item.hidden(this.props)) :
              item.hidden(this.props)}
          >
            <div
              class={Style.itemContainer}
              data-testid={`pdf-wizard-item-${item.id}`}
              name={item.id}
              onClick={this.changeProperty}
              onMouseEnter={this.updateHover}
              onMouseLeave={this.updateHover}
              role="presentation"
            >
              <div
                name={item.id}
                class={this.toggleLabelColor(item.id)}
              >
                {translateMapper(t, item.id)}
              </div>
              <img
                class={this.isStacked(item.id) ?
                  Style.itemStacked :
                  Style.item}
                data-testid={`pdf-wizard-stacked-${item.id}`}
                name={item.id}
                src={this.itemIconApperance(item.id)}
                alt=""
              />
              {this.renderBgCgmCheckbox(item.id)}
              {this.renderWeekViewOptionSelection(item.id)}
              <img
                class={this.isStacked(item.id) ?
                  Style.selectStack :
                  Style.selectSingle}
                data-testid={`pdf-wizard-selected-${item.id}`}
                name={item.id}
                src={this.isStacked(item.id) ?
                  ImageStore.stackSelected :
                  ImageStore.selected}
                alt=""
                hidden={this.hiddenPageCountForOption(item.id)}
              />
              <img
                class={this.toggleHover(item.id)}
                name={item.id}
                src={this.isStacked(item.id) ?
                  ImageStore.stack :
                  ImageStore.hovering}
                alt=""
                hidden={this.isHovering(item.id)}
              />
              <div
                name={item.id}
                class={this.isStacked(item.id) ?
                  Style.itemPageCount :
                  Style.itemPageCountSingle}
                hidden={!this.displayPageCount(item.id)}
              >
                {translateMapper(t, 'page', { count: this.numberOfPages(item.id) })}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  editNameChanged = (event) => {
    const newName = event.target.value;
    const nameTaken = this.props.favorites.find((fav) => this.props.initialSelectedOptions.id !== fav.id && fav.name === newName);
    this.setState({
      nameEditOKButtonEnabled: !nameTaken && newName.length,
      nameEdited: newName,
    });
  }

  enableNameEdit = () => {
    this.setState({
      nameEditEnabled: true,
      nameEditOKButtonEnabled: true,
    });
  }

  editNameOKClick = () => {
    this.setState({
      nameEditEnabled: false,
      inputName: this.state.nameEdited,
    });
  }

  renderManageFavoriteName = () => {
    const { t } = this.props;

    return (
      <div class={Style.titleNameInfoContainer}>
        <div class={Style.pdfWizardTitle}>{t('managePdf')}</div>
        <div class={classNames(Style.editFavoriteName, { [Style.error]: this.state.nameEditEnabled && !this.state.nameEditOKButtonEnabled })}>
          <div class={Style.NameofFavoritePro}>
            {t('nameProfile')}
          </div>
          <img
            src={ImageStore.editBlue}
            alt="edit"
            onClick={this.enableNameEdit}
            class={classNames({ [Style.hidden]: this.state.nameEditEnabled })}
          />
          <button
            type="button"
            class={classNames({ [Style.hidden]: !this.state.nameEditEnabled || !this.state.nameEditOKButtonEnabled })}
            onClick={this.editNameOKClick}
          >
            OK
          </button>
          <input
            type="text"
            placeholder={t('name')}
            disabled={!this.state.nameEditEnabled}
            value={this.state.nameEdited}
            onChange={this.editNameChanged}
            ref={(input) => this.state.nameEditEnabled && !this.state.nameEdited.length && input && input.focus()}
          />
          <p class={classNames({ [Style.hidden]: !this.state.nameEditEnabled || this.state.nameEditOKButtonEnabled })}>
            {this.state.nameEdited.length ? t('nameTaken') : t('nameRequired')}
          </p>
        </div>
      </div>);
  }

  renderTitleNameAndUserInfo() {
    const {
      t,
      patientFullName,
      userDateOfBirth,
      userTypeOfDiabetes,
    } = this.props;
    const patientDOBDisplay = userDateOfBirth ?
      moment.utc(userDateOfBirth).format(TIME_FORMATS.MM_DD_YY) :
      t('noDateSet');

    return (
      <div class={Style.titleNameInfoContainer}>
        <div class={Style.pdfWizardTitle}>{t('createPdf')}</div>
        <div class={Style.pdfWizardDetails}>
          <div class={Style.patientName}>{patientFullName}</div>
          <div class={Style.patientInfo}>
            <div class={Style.textColor}>{t('dob')} </div>
            <div class={Style.dateOfBirth}>
              {patientDOBDisplay}
            </div>
          </div>
          <div class={Style.patientInfo}>
            <div class={Style.textColor}>{t('diabetes')} </div>
            <div class={Style.typeOfDiabetes}> {UserHelper.retrieveLabel(userTypeOfDiabetes || 'none')}</div>
          </div>
        </div>
      </div>
    );
  }

  renderReportTypeContainer() {
    const { t, editFavouriteMode } = this.props;
    const availableTimeFrames =
      editFavouriteMode ?
        PDF_TIMEFRAMES.filter((timeframe) => (timeframe.value !== CUSTOM_RANGE)) :
        PDF_TIMEFRAMES;
    return (
      <div class={Style.reportTypeContainer}>
        <div class={Style.box}>
          <div class={Style.timeLabel}>{t('time')} </div>
          <PdfWizardDateRangeSelectContainer availableTimeFrames={availableTimeFrames} />
          {this.renderDatePicker()}
        </div>
        {this.renderPotentialDeviceTimeIssueBanner()}
        {this.renderReportTypeItem()}
      </div>
    );
  }

  renderReportLengthAndPrintMode() {
    const { t } = this.props;
    return (
      <div class={classNames(Style.lowerLeftContainer, { [Style.inEdit]: this.props.editFavouriteMode })}>
        <div class={classNames(Style.textColor, { [Style.hidden]: this.props.editFavouriteMode }, Style.reportLength)}>
          <div class={Style.reportLengthLabel}>{t('reportLength')}</div>
          <div class={Style.patientInfo}>{this.state.estimatedReportLength}</div>
        </div>
        <div class={Style.printMode} role="presentation">
          <span class={Style.printModeLabel}>{t('printMode')}</span>
          <div class={Style.radioContainer}>
            <div
              onClick={() => this.togglePrintMode(false)}
              role="presentation"
            >
              <img
                class={Style.blackAndWhite}
                src={this.state.color ?
                  ImageStore.deselected2 :
                  ImageStore.selected2}
                alt=""
              />
              <span class={Style.radioLabelBlackAndWhite}>
                {t('blackAndWhite')}
              </span>
            </div>
            <div
              onClick={() => this.togglePrintMode(true)}
              role="presentation"
            >
              <img
                class={Style.color}
                src={this.state.color ?
                  ImageStore.selected2 :
                  ImageStore.deselected2}
                alt=""
              />
              <span class={Style.radioLabelColor}>
                {t('color')}
              </span>
            </div>
          </div>
        </div>
        {this.renderSummaryCommentInput()}
      </div>
    );
  }

  toggleFavChange = () => {
    this.setState({
      isFavChecked: !this.state.isFavChecked,
    });
  }

  handleFavName = (event) => {
    event.preventDefault();
    this.setState({
      name: event.target.name,
    });
  }

  handleChange(event) {
    this.setState({ inputName: event.target.value });
  }

  updateFavoriteSaveButtonEnabled = () => !this.state.nameEditEnabled && options.find((item) => this.state[item.id]) && this.state.inputName.length;

  updateFavorite = () => {
    if (!this.updateFavoriteSaveButtonEnabled()) {
      return;
    }
    const reportTypesArray = options.filter((item) => this.state[item.id]).map((item) => item.reportTypeName);
    const numOfDays = PDF_TIMEFRAMES[this.props.timeFrameIndexPdfWizard].value;
    if (!isEmpty(reportTypesArray)) {
      const favoriteParams = {
        name: this.state.inputName,
        color: this.state.color,
        numberOfDays: numOfDays,
        reportTypes: reportTypesArray,
      };
      if (this.props.initialSelectedOptions.id) {
        this.props.actions.wizardUpdateFavorite({ ...favoriteParams, id: this.props.initialSelectedOptions.id });
      } else {
        this.props.actions.wizardSaveFavorite(favoriteParams);
      }
      this.visibilityCallback();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const names = this.props.favorites.map((fav) => fav.name);
    if (names.indexOf(this.state.inputName) === -1 && this.state.inputName) {
      const reportTypesArray = options.filter((item) => this.state[item.id]).map((item) => item.reportTypeName);
      const numOfDays = Math.round(
        (moment.utc(this.props.endDate).unix() - moment.utc(this.props.startDate).unix()) / SECONDS_IN_DAY,
      );
      this.setState({ isFavSaving: true });
      if (!isEmpty(reportTypesArray)) {
        const favoriteParams = {
          name: this.state.inputName,
          color: this.state.color,
          numberOfDays: numOfDays,
          reportTypes: reportTypesArray,
        };
        this.props.actions.wizardSaveFavorite(favoriteParams);
        this.setState({
          activeFav: favoriteParams,
          color: favoriteParams.color,
        });
      }
      setTimeout(() => {
        this.setState({ isFavSaved: false, isFavSaving: false, isFavChecked: false, inputName: '' });
      }, 3000);
    }
  }

  renderCloseButton() {
    const { t } = this.props;

    return (
      <div
        class={Style.btnClose}
        onClick={this.visibilityCallback}
        role="presentation"
      >
        <img src={ImageStore.close} alt="X" />
        <span class={Style.labelClose}>{t('close')}</span>
      </div>
    );
  }

  saveFavoriteTooltip() {
    const { t, favorites } = this.props;

    let saveNewFavoriteTooltip = null;

    if (this.state.isFavChecked && !this.state.isFavSaving) {
      const names = favorites.map((fav) => fav.name);
      const isDuplicatedName = (names.indexOf(this.state.inputName) !== -1) ? t('nameTaken') : null;
      saveNewFavoriteTooltip = !this.state.inputName ? t('nameRequired') : isDuplicatedName;
    }

    return saveNewFavoriteTooltip;
  }

  renderCreateReport() {
    const { t, isPgsIpgRestricted } = this.props;
    const { isPatientIpgRestricted, createPdfClicked } = this.state;
    const showIpgRestricted = isPgsIpgRestricted && isPatientIpgRestricted && createPdfClicked;

    const reportTypesArray = options.filter((item) => this.state[item.id]).map((item) => item.reportTypeName);
    const createPdfBtn = this.props.editFavouriteMode || (this.state.isFavChecked && !this.state.isFavSaved && !isEmpty(reportTypesArray)) ? null :
      (<div>
      {showIpgRestricted &&
        <span className={Style.createPdfError}>{t('patientIpgRestricted')}</span>}
      <button
        key="Title"
        onClick={this.createPdfInNewWindow}
        class={this.createReportButtonStyle()}
        disabled={this.createReportButtonDisabled()}
      >
        {t('createPdf')}
      </button>
       </div>);
    const editFavouriteBtn = this.props.editFavouriteMode ? [
      <button
        key="Cancel"
        onClick={this.visibilityCallback}
        class={classNames(Style.btnCreate, Style.btnCancel)}
      > {t('cancel')}
      </button>,
      <button
        key="Save"
        onClick={this.updateFavorite}
        disabled={!this.updateFavoriteSaveButtonEnabled()}
        class={Style.btnCreate}
      > {t('save')}
      </button>,
    ] : null;
    const favSavedMessage = this.state.isFavSaved ?
      (<div class={classNames({
        [Style.favoritesCheckBox]: true,
        [Style.favSaved]: true,
      })}
      >
        <img class={Style.checkIcon} src={ImageStore.checkmarkAppsBlue} alt="X" />
        {t('profileSaved')}
       </div>) : null;
    const saveFavoriteVisible =
      !this.props.editFavouriteMode &&
      this.props.currentUserIsProvider &&
      !this.state.isFavSaved &&
      this.state.estimatedReportLength !== 0 &&
      PDF_TIMEFRAMES[this.props.timeFrameIndexPdfWizard].value !== CUSTOM_RANGE;

    return (
      <div class={Style.favoritesCheckBoxContainer}>
        {createPdfBtn}
        {editFavouriteBtn}
        <div class={classNames({
          [Style.favoritesCheckBox]: true,
          [Style.favNotChecked]: !this.state.isFavChecked,
        })}
        >
          <GraphOptionsItem
            label={t('saveFavorites')}
            id={t('favorite')}
            selected={this.state.isFavChecked}
            visible={saveFavoriteVisible}
            onClick={this.toggleFavChange}
            fontColor={Colors.fontRegular}
          />
          {!this.props.editFavouriteMode && this.state.isFavChecked ?
            <div class={classNames({
              [Style.favoritesName]: true,
              [Style.hidden]: this.state.isFavSaved || isEmpty(reportTypesArray),
            })}
            >
              <form class={Style.formWrapper} onSubmit={this.handleSubmit}>
                <div>
                  <input
                    type="text"
                    placeholder={t('selectName')}
                    value={this.state.inputName}
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    data-event="click"
                    data-tooltip-content={this.saveFavoriteTooltip()}
                    disabled={this.state.isFavSaving || !this.state.inputName}
                    data-tooltip-id='inputNameWarningTooltip'
                    data-tooltip-place='bottom'
                    data-tooltip-variant='light'
                  >
                    {t('OK')}
                  </button>
                </div>
                <Tooltip
                  id="inputNameWarningTooltip"
                  className={Style.tooltip}
                  classNameArrow={Style.tooltipArrow}
                  globalEventOff="click"
                  role="tooltip"
                />
              </form>
            </div> : null}
          {favSavedMessage}
        </div>
      </div>
    );
  }

  buildCorrectSelectionsForFavoriteSetting(data) {
    const { hasBg, hasCgm, editFavouriteMode } = this.props;
    const selectionsFromFavoriteSetting = {};

    const overlayBgSelected = data.reportTypes.indexOf('overlay_bg') > -1;
    const overlayCgmSelected = data.reportTypes.indexOf('overlay_cgm') > -1;
    const summaryBgSelected = data.reportTypes.indexOf('summary_bg') > -1;
    const summaryCgmSelected = data.reportTypes.indexOf('summary_cgm') > -1;

    options.forEach((item) => {
      let newState = data.reportTypes.indexOf(item.reportTypeName) > -1;

      // unselect if data type not present on User
      if (newState && BgOnlyReports.indexOf(item.reportTypeName) > -1) {
        newState = newState && (editFavouriteMode || hasBg);
      } else if (newState && CgmOnlyReports.indexOf(item.reportTypeName) > -1) {
        newState = newState && (editFavouriteMode || hasCgm);
      }

      // select if other option should be selected
      newState = newState || (item.reportTypeName === 'overlay_bg' && !hasCgm && overlayBgSelected);
      newState = newState || (item.reportTypeName === 'overlay_cgm' && !hasBg && overlayCgmSelected);
      newState = newState || (item.reportTypeName === 'summary_bg' && !hasCgm && summaryBgSelected);
      newState = newState || (item.reportTypeName === 'summary_cgm' && !hasBg && summaryCgmSelected);

      selectionsFromFavoriteSetting[item.id] = newState;
    });

    return selectionsFromFavoriteSetting;
  }

  selectFavoriteReport(data) {
    trackPdfWizardFavoriteSelected();
    const selectionsFromFavoriteSetting = this.buildCorrectSelectionsForFavoriteSetting(data);

    Object.keys(selectionsFromFavoriteSetting).forEach((itemId) => {
      this.setState({
        [`${itemId}Checked`]: selectionsFromFavoriteSetting[itemId],
        [itemId]: selectionsFromFavoriteSetting[itemId],
      });
    });

    this.setState({
      color: data.color,
      activeFav: data,
      bgOverlayShowSelection: (
        data.reportTypes.indexOf('overlay_bg') > -1 ||
        data.reportTypes.indexOf('overlay_cgm') > -1
      ),
      bgSummaryShowSelection: (
        data.reportTypes.indexOf('summary_bg') > -1 ||
        data.reportTypes.indexOf('summary_cgm') > -1
      ),
    });
    this.props.actions.updatePdfWizardTimeframeThunk(data.numberOfDays);
  }

  handleOnClickViewSummary = () => {
    const {
      startDate,
      endDate,
      timeFrameIndexPdfWizard,
      timeFramePdfWizard,
      datepickerEnabled,
    } = this.props;
    const summaryPageDateRangeConfig = {
      startDate,
      endDate,
      startDatePdfWizard: startDate,
      endDatePdfWizard: endDate,
      timeFrameIndex: timeFrameIndexPdfWizard + 1,
      timeFrameIndexPdfWizard,
      timeframe: timeFramePdfWizard,
      timeFramePdfWizard,
      datepickerEnabled,
    };
    const url = `/summary?patient=${this.props.patientId}`;
    localStorage.setItem('createPdfModalDateRange', JSON.stringify(summaryPageDateRangeConfig));
    localStorage.setItem('expandDeviceOffsetBanner', 'true');
    window.location.assign(url);
  }

  renderPotentialDeviceTimeIssueBanner() {
    const { t, deviceTimeOffsets } = this.props;
    const deviceTimeOffsetsArrayIsNotEmpty = deviceTimeOffsets.length >= 1;
    if (deviceTimeOffsetsArrayIsNotEmpty) {
      return (
        <div className={Style.potentialDeviceTimeIssueBanner}>
          <Alert severity="error" dataAttributes={{ testid: 'pdf-wizard' }}>
            <div className={Style.childContainer}>
              <div>
                <span> {t('potentialDeviceTimeIssue')}</span><br />
                <span> {t('ensureDataExists')}</span>
              </div>
              <Button
                onClick={this.handleOnClickViewSummary}
                variation='link'
                dataAttributes={{ testid: 'pdf-wizard-view-summary' }}
              >
                {t('viewSummary')}
              </Button>
            </div>
          </Alert>
        </div>);
    }
    return null;
  }

  render = () => {
    if (!this.props.visible) {
      return null;
    }
    const { t } = this.props;
    const placeholder = isEmpty(this.props.favorites) ? t('noProfileAvailable') : t('noProfileSelected');
    const numOfDays = Math.round(
      (moment.utc(this.props.endDate).unix() - moment.utc(this.props.startDate).unix()) / SECONDS_IN_DAY,
    );
    const equalNumberOfDays = this.state.activeFav.numberOfDays === numOfDays;

    const selectionsFromFavoriteSetting = this.buildCorrectSelectionsForFavoriteSetting(this.state.activeFav);
    const equalReportType = options.every(
      (item) => this.state[item.id] === (selectionsFromFavoriteSetting[item.id]),
    );
    const equalColorType = this.state.activeFav.color === this.state.color; // NOSONAR

    const selectedFavorite =
      equalReportType &&
        equalColorType &&
        equalNumberOfDays &&
        PDF_TIMEFRAMES[this.props.timeFrameIndexPdfWizard].value !== CUSTOM_RANGE ?
        this.state.activeFav.name : '';
    return (
      <div>
        <GreyOverlay onClick={this.visibilityCallback} />
        <div class={classNames(Style.pdfWizard, { [Style.inEdit]: this.props.editFavouriteMode })}>
          {!this.props.editFavouriteMode && this.renderTitleNameAndUserInfo()}
          {this.props.editFavouriteMode && this.renderManageFavoriteName()}
          {!this.props.editFavouriteMode && this.props.currentUserIsProvider ?
            <SelectFavoriteReport
              placeholder={placeholder}
              selectFavoriteReport={this.selectFavoriteReport}
              favorites={this.props.favorites}
              selectedFavorite={selectedFavorite}
              signedInByToken={signedInByToken}
            /> : null}
          {this.renderReportTypeContainer()}
          {this.renderReportLengthAndPrintMode()}
          {this.renderCloseButton()}
          {this.renderCreateReport()}
        </div>
      </div>
    );
  }
}
export const TranslatedPdfWizard = translate('PdfWizard')(PdfWizard);
export default connect(mapStateToProps, mapDispatchToProps)(TranslatedPdfWizard);
