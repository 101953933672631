import { Dialog, DialogContent, Spinner } from '@glooko/common-ui';
import { useTranslation } from 'react-i18next';
import Style from './FileUploaderPopup.scss';

const FileUploaderPopupUploading = () => {
  const { t } = useTranslation('FileUploaderPopup');

  return (
    <Dialog dataAttributes={{ testid: 'file-uploader-uploading' }} open size="medium">
      <DialogContent dataAttributes={{ testid: 'file-uploader-uploading' }}>
        <div className={Style.spinner_container}>
          <Spinner
            dataAttributes={{ testid: 'file-uploader-uploading' }}
            label={t('uploadUploadingTitle')}
            labelPlacement="bottom"
            size="large"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploaderPopupUploading;
