import { ChangeEvent, useRef, useState, useEffect } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { Trans, useTranslation } from 'react-i18next';
import { openSummaryPage, sendData } from '../../../../services/fileuploadApi';
import FileUploaderPopupFailed from './FileUploaderPopupFailed';
import FileUploaderPopupUploading from './FileUploaderPopupUploading';
import FileUploaderPopupSuccess from './FileUploaderPopupSuccess';
import SupportLinks from '../../../../utils/support';
import FileUploaderPopupConfirm, { FileUploaderPopupConfirmProps } from './FileUploaderPopupConfirm';

export const FILE_UPLOAD_POPUP_START = 'FILE_UPLOAD_POPUP_START';
export const FILE_UPLOAD_POPUP_UPLOADING = 'FILE_UPLOAD_POPUP_UPLOADING';
export const FILE_UPLOAD_POPUP_FAILED = 'FILE_UPLOAD_POPUP_FAILED';
export const FILE_UPLOAD_POPUP_SUCCESS = 'FILE_UPLOAD_POPUP_SUCCESS';
export const FILE_UPLOAD_POPUP_CONFIRM = 'FILE_UPLOAD_POPUP_CONFIRM';

const MAX_FILE_SIZE_IN_MB = 100;

export interface FileUploaderPopupProps {
  acceptedExtensions: string[];
  closePopup: () => void;
  extractorName: string;
  fileParser?: FileUploaderPopupConfirmProps['fileParser'];
  providerName: string;
  supportArticleId: string;
}

const FileUploaderPopup = (props: FileUploaderPopupProps) => {
  const { t } = useTranslation('FileUploaderPopup');
  const {
    closePopup,
    extractorName,
    acceptedExtensions,
    fileParser,
    providerName,
    supportArticleId,
  } = props;

  const [file, setFile] = useState<File>();
  const [status, setStatus] = useState(FILE_UPLOAD_POPUP_START);
  const [fileTooLarge, setFileTooLarge] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const uploadFile = async () => {
    await sendData(file, extractorName)
      .then(() => setStatus(FILE_UPLOAD_POPUP_SUCCESS))
      .catch(() => setStatus(FILE_UPLOAD_POPUP_FAILED));
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFileTooLarge(false);
      if (bytesToMegabytes(files[0].size) > MAX_FILE_SIZE_IN_MB) {
        tryAgain();
        setFileTooLarge(true);
        return;
      }

      setFile(files[0]);
      setStatus(FILE_UPLOAD_POPUP_CONFIRM);
    } else {
      setStatus(FILE_UPLOAD_POPUP_FAILED);
    }
  };

  const tryAgain = () => setStatus(FILE_UPLOAD_POPUP_START);
  const confirm = () => setStatus(FILE_UPLOAD_POPUP_UPLOADING);
  const bytesToMegabytes = (bytes: number) => bytes / 1000000;

  useEffect(() => {
    if (status === FILE_UPLOAD_POPUP_UPLOADING) {
      uploadFile();
    }
  }, [status, uploadFile]);

  return (
    <>
      {status === FILE_UPLOAD_POPUP_CONFIRM && file ? (
        <FileUploaderPopupConfirm
          file={file}
          fileParser={fileParser}
          onConfirm={confirm}
          onDecline={tryAgain}
        />
      ) : null}

      {status === FILE_UPLOAD_POPUP_UPLOADING && (
        <FileUploaderPopupUploading />
      )}

      {status === FILE_UPLOAD_POPUP_FAILED && (
        <FileUploaderPopupFailed
          acceptedExtensions={acceptedExtensions}
          onClosePopup={closePopup}
          onTryAgain={tryAgain}
        />
      )}

      {status === FILE_UPLOAD_POPUP_SUCCESS && file ? (
        <FileUploaderPopupSuccess
          fileName={file!.name}
          onDone={closePopup}
          onViewData={openSummaryPage}
        />
      ) : null}

      {status === FILE_UPLOAD_POPUP_START && (
        <Dialog dataAttributes={{ testid: 'file-uploader' }} onClose={closePopup} open size="medium">
          <DialogHeader dataAttributes={{ testid: 'file-uploader' }}>
            <h2>{t('startTitle', { providerName })}</h2>
          </DialogHeader>

          <DialogContent dataAttributes={{ testid: 'file-uploader' }}>
            <ol>
              <li>
                <Trans
                  components={{
                    supportLink: <a href={SupportLinks.article(supportArticleId)} rel="noreferrer" target="_blank" />,
                  }}
                  i18nKey="startStep1"
                  t={t}
                  values={{ providerName }}
                />
              </li>

              <li>
                <Trans i18nKey="startStep2" t={t} />
              </li>

              <li>
                <Trans i18nKey="startStep3" t={t} />
              </li>

              <li>
                <Trans i18nKey="startStep4" t={t} />
              </li>
            </ol>

            <p>
              <em>
                <Trans
                  components={{
                    supportLink: <a href={SupportLinks.fileTicket()} rel="noreferrer" target="_blank" />,
                  }}
                  i18nKey='needHelp'
                  t={t}
                />
              </em>
            </p>

            {fileTooLarge ? <Alert severity="error">{t('fileTooLarge', { size: MAX_FILE_SIZE_IN_MB })}</Alert> : null}

            <input
              ref={fileInput}
              accept={acceptedExtensions.join(',')}
              aria-label="File Upload"
              onChange={onFileChange}
              role="button"
              style={{ display: 'none' }}
              type="file"
            />
          </DialogContent>

          <DialogActions dataAttributes={{ testid: 'file-uploader' }}>
            <Button dataAttributes={{ testid: 'file-uploader-cancel' }} onClick={closePopup} variation="secondary">{t('cancelButton')}</Button>
            <Button dataAttributes={{ testid: 'file-uploader-upload' }} onClick={() => fileInput.current?.click()}>{t('uploadButton')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default FileUploaderPopup;
