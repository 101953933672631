import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Readings from '~/services/Readings';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import OverviewGraphHoursContainer from
  '~/bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/OverviewGraphHoursContainer';
import WeekViewPdfStatistics from '../WeekViewPdfStatistics/WeekViewPdfStatistics';
import WeekViewAxis from './WeekViewAxis/WeekViewAxis';
import Style from './WeekViewPdfSingleDay.scss';
import { WEEK_VIEW_PDF_GRAPH_NAME, WEEK_VIEW_PDF_GRAPH_WIDTH, HEIGHT_OVERRIDE_FACTOR } from
  '../WeekViewPdfContainer/WeekViewPdfContainer';

const WeekViewPdfSingleDay = (props) => {
  const {
    hasPump,
    hasControlIQ,
    hasBasalIqData,
    hasCamapsData,
    hasOmnipod5,
    hasMedtronicClosedLoopData,
    hasInsulinData,
    startTimestamp,
    endTimestamp,
    meterUnits,
    statistics,
    graphConfig,
  } = props;
  const date = moment.utc(startTimestamp).format(TIME_FORMATS.DDDD_MMMM_D_YYYY);

  return (
    <div class={Style.WeekViewPdfSingleDay}>
      <div class={Style.date}>{date}</div>
      <WeekViewAxis
        hasInsulinData={hasInsulinData}
        hasPump={hasPump}
        hasControlIQ={hasControlIQ}
        hasBasalIqData={hasBasalIqData}
        hasOmnipod5={hasOmnipod5}
        hasMedtronicClosedLoopData={hasMedtronicClosedLoopData}
        startTimestamp={startTimestamp}
        endTimestamp={endTimestamp}
        meterUnits={meterUnits}
        heightOverrideFactor={HEIGHT_OVERRIDE_FACTOR}
        hasCamapsData={hasCamapsData}
        graphConfig={graphConfig}
      />
      <OverviewGraphHoursContainer
        graphTypeName={WEEK_VIEW_PDF_GRAPH_NAME}
        startTimestamp={startTimestamp}
        endTimestamp={endTimestamp}
        graphsWindowWidth={WEEK_VIEW_PDF_GRAPH_WIDTH}
        hasInsulinData={hasInsulinData}
        hasPump={hasPump}
        hasControlIQ={hasControlIQ}
        hasBasalIqData={hasBasalIqData}
        hasOmnipod5={hasOmnipod5}
        hasCamapsData={hasCamapsData}
        hasMedtronicClosedLoopData={hasMedtronicClosedLoopData}
        heightOverrideFactor={HEIGHT_OVERRIDE_FACTOR}
        hasExercise={false}
        weekView
        graphConfig={graphConfig}
      />
      <WeekViewPdfStatistics
        hasPump={hasPump}
        hasInsulinData={hasInsulinData}
        statistics={statistics}
        meterUnits={Readings.displayMeterUnits(meterUnits)}
        graphConfig={graphConfig}
      />
    </div>
  );
};

WeekViewPdfSingleDay.propTypes = {
  startTimestamp: PropTypes.string.isRequired,
  endTimestamp: PropTypes.string.isRequired,
  hasPump: PropTypes.bool,
  hasControlIQ: PropTypes.bool,
  hasBasalIqData: PropTypes.bool,
  hasOmnipod5: PropTypes.bool,
  hasMedtronicClosedLoopData: PropTypes.bool,
  hasCamapsData: PropTypes.bool,
  hasInsulinData: PropTypes.bool,
  meterUnits: PropTypes.string,
  statistics: PropTypes.object.isRequired,
  graphConfig: PropTypes.string.isRequired,
};

WeekViewPdfSingleDay.defaultProps = {
  startTimestamp: moment().toISOString(),
  endTimestamp: moment().toISOString(),
  hasPump: false,
  hasControlIQ: false,
  hasBasalIqData: false,
  hasOmnipod5: false,
  hasMedtronicClosedLoopData: false,
  hasCamapsData: false,
  hasInsulinData: false,
  meterUnits: 'mgdl',
  statistics: {},
};

export default WeekViewPdfSingleDay;
