import { filter, values, some } from 'lodash';
import moment from 'moment';
import {
  bgGraphSeriesNameProcessed,
  basalSeriesNameProcessed,
  pumpModeSeriesNameProcessed,
  pumpModeOp5SeriesNameProcessed,
  pumpModeGenericSeriesNameProcessed,
  pumpModeBasaliqSeriesNameProcessed,
  basalBarSeriesNameProcessed,
  bolusSeriesNameProcessed,
  carbsSeriesNameProcessed,
  exerciseHoursSeriesNameProcessed,
  insulinManualSeriesNameProcessed,
  insulinPumpSeriesNameProcessed,
  insulinManualAndPumpSeriesNameProcessed,
  pumpModeCamApsSeriesNameProcessed,
} from '~/bundles/shared/constants/pages/overview';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import { keyFromParams } from '~/redux/modules/graphs/graphs';
import { useGraphAxis } from 'bundles/graphs/hooks/useGraphAxis';
import UserHelper from '~/redux/modules/users/UserHelper';
import { GRAPH_NAME } from '../OverviewGraphHoursWithAxis/OverviewGraphHoursWithAxis';

const getMaxValues = (state, axisValues) => {
  const { bgAxisDisplayValue } = useGraphAxis();
  return axisValues.reduce((accumulator, currentItem) => {
    const safeCurrentItem = currentItem || {};

    return {
      exerciseAxisEndIndex: Math.max(accumulator.exerciseAxisEndIndex, safeCurrentItem.exerciseAxisEndIndex),
      bolusAxisEndIndex: Math.max(accumulator.bolusAxisEndIndex, safeCurrentItem.bolusAxisEndIndex),
      basalAxisEndIndex: Math.max(accumulator.basalAxisEndIndex, safeCurrentItem.basalAxisEndIndex),
      bgGraphAxisEndIndex: accumulator.bgGraphAxisEndIndex,
    };
  },
  {
    exerciseAxisEndIndex: 2,
    bolusAxisEndIndex: 2,
    basalAxisEndIndex: 2,
    bgGraphAxisEndIndex: bgAxisDisplayValue(UserHelper.displayUser(state).preference.meterUnits),
  },
  );
};

export const groupedSeries = (series) => ({
  bgGraphSeries: filter(series, (obj) => bgGraphSeriesNameProcessed.includes(obj.name)),
  basalSeries: filter(series, (obj) => basalSeriesNameProcessed.includes(obj.name)),
  pumpModeSeries: filter(series, (obj) => pumpModeSeriesNameProcessed.includes(obj.name)),
  pumpModeOp5Series: filter(series, (obj) => pumpModeOp5SeriesNameProcessed.includes(obj.name)),
  pumpModeGenericSeries: filter(series, (obj) => pumpModeGenericSeriesNameProcessed.includes(obj.name)),
  pumpModeBasaliqSeries: filter(series, (obj) => pumpModeBasaliqSeriesNameProcessed.includes(obj.name)),
  bolusSeries: filter(series, (obj) => bolusSeriesNameProcessed.includes(obj.name)),
  carbsSeries: filter(series, (obj) => carbsSeriesNameProcessed.includes(obj.name)),
  exerciseSeries: filter(series, (obj) => exerciseHoursSeriesNameProcessed.includes(obj.name)),
  insulinManualSeries: filter(series, (obj) => insulinManualSeriesNameProcessed.includes(obj.name)),
  insulinPumpSeries: filter(series, (obj) => insulinPumpSeriesNameProcessed.includes(obj.name)),
  insulinManualAndPumpSeries: filter(series, (obj) => insulinManualAndPumpSeriesNameProcessed.includes(obj.name)),
  basalBarSeries: filter(series, (obj) => basalBarSeriesNameProcessed.includes(obj.name)),
  pumpModeCamApsSeries: filter(series, (obj) => pumpModeCamApsSeriesNameProcessed.includes(obj.name)),
});

export const calculateAxisMaxFromProps = (seriesArray, meterUnits) => {
  const series = groupedSeries(seriesArray, meterUnits);
  return calculateAxisMaxFromGroupedSeries(series);
};

export const calculateAxisMaxFromGroupedSeries = (groupedSeriesData) => {
  const { exerciseAxisMax, basalAxisMax, bolusAxisMax } = useGraphAxis();
  const basalWithoutLgsPlgs = filter(groupedSeriesData.basalSeries, (obj) => obj.name !== 'lgsPlgs');
  return {
    exerciseAxisEndIndex: exerciseAxisMax(groupedSeriesData.exerciseSeries),
    bolusAxisEndIndex: bolusAxisMax(groupedSeriesData.bolusSeries),
    basalAxisEndIndex: basalAxisMax(basalWithoutLgsPlgs),
  };
};

export const getAxisMaxFromGroupedSeries = (state, series) => {
  const hoursGraphMax = calculateAxisMaxFromGroupedSeries(series);
  return getMaxValues(state, values([hoursGraphMax]));
};

/**
 * @param {any} [key=null]
 * @returns {
 *    exerciseAxisEndIndex: number,
 *    bolusAxisEndIndex: number,
 *    basalAxisEndIndex: number,
 *    bgGraphAxisEndIndex: number,
 *  }
 */
export const getAxisMaxFromRedux = (state, key = null) => {
  const hoursGraphMax = key ? [state.graphs.hoursGraphMax[key]] : state.graphs.hoursGraphMax;

  return getMaxValues(state, values(hoursGraphMax));
};

class HoursGraphHelpers {
  static hasDataInCache(state, id, seriesNames) {
    const { series } =
      GraphsHelper.retrieveFetchStatusAndSeries(state, keyFromParams({
        id,
        startTimestamp: moment.utc(state.page.startDate).startOf('day').toISOString(),
        endTimestamp: moment.utc(state.page.startDate).add(1, 'days').startOf('day').toISOString(),
      }));
    return some(series, (s) => (seriesNames.indexOf(s.name) > -1 && s.data.length > 0 && some(s.data, (p) => (p.yOrig || p.y) > 0)));
  }

  static showInsulinManualGraphOnDailyOverview(state) {
    return HoursGraphHelpers.hasDataInCache(state, GRAPH_NAME, insulinManualSeriesNameProcessed);
  }

  static showInsulinPumpGraphOnDailyOverview(state) {
    return HoursGraphHelpers.hasDataInCache(state, GRAPH_NAME, insulinPumpSeriesNameProcessed);
  }
}

export default HoursGraphHelpers;
