import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { OMNIPOD, CONTACT, DASH, UPLOAD, UPLOADER_DOWNLOAD, updateSelectedDevice } from
  '~/redux/modules/omnipod/omnipod';
import { UPLOADER_FEATURE } from '~/bundles/shared/constants/providerGroupSite';
import ProviderGroupSiteHelper from '~/redux/modules/providerGroupSite/ProviderGroupSiteHelper';
import ImageStore from '~/utils/ImageStore';
import UserHelper from '~/redux/modules/users/UserHelper';
import translate from '~/bundles/shared/components/WithTranslate/WithTranslate';
import PopUp from '~/bundles/shared/components/PopUpContainer/PopUp/PopUp';
import Style from './OmnipodDashSelectDialog.scss';

const mapStateToProps = (state, ownProps) => {
  const { switchModal, onClose } = ownProps;
  const hasUploaderFeature =
    ProviderGroupSiteHelper.hasSubscriptionFeature(state, UPLOADER_FEATURE);

  return {
    onClose,
    switchModal,
    hasUploaderFeature,
    currentUserIsPatient: UserHelper.currentUserIsPatient(state),
    selectedDevice: state.omnipod.selectedDevice,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updateSelectedDevice }, dispatch),
});

export class OmnipodDashSelectDialog extends Component {
  updateSelectedDevice(device) {
    this.props.actions.updateSelectedDevice(device);
  }

  switchModal() {
    const { switchModal, selectedDevice, currentUserIsPatient, hasUploaderFeature } = this.props;

    if (selectedDevice === OMNIPOD) {
      switchModal(UPLOAD);
    } else if (currentUserIsPatient || hasUploaderFeature) {
      switchModal(UPLOADER_DOWNLOAD);
    } else {
      switchModal(CONTACT);
    }
  }

  render() {
    const { t, selectedDevice } = this.props;
    const buttonActive = !!selectedDevice;

    const body = (
      <div className={Style.body}>
        <p className={Style.instructions}>{t('selectDevice')}</p>
        <div className={Style.devices}>
          <div className={Style.deviceWrapper}>
            <button className={Style.deviceBtn} onClick={() => this.updateSelectedDevice(OMNIPOD)}>
              <img className={Style.deviceImage} src={ImageStore.omnipodDevice} alt="Omnipod PDM" />
            </button>
            <p className={Style.deviceLabel}>{t('omnipod')}</p>
          </div>
          <div className={Style.deviceWrapper}>
            <button className={Style.deviceBtn} onClick={() => this.updateSelectedDevice(DASH)}>
              <img className={Style.deviceImage} src={ImageStore.dashDevice} alt="Insulet Dash" />
            </button>
            <p className={Style.deviceLabel}>{t('dash')}</p>
          </div>
        </div>
      </div>
    );

    const nextButtonClasses = classNames(
      Style.footerBtn, Style.footerNext, { [Style.footerNextActive]: buttonActive },
    );

    const footer = (
      <div className={Style.footer}>
        <button
          className={classNames(Style.footerBtn, Style.footerCancel)}
          onClick={this.props.onClose}
        >
          {t('cancel')}
        </button>
        <button
          className={nextButtonClasses}
          disabled={!buttonActive}
          onClick={() => this.switchModal()}
        >
          {t('next')}
        </button>
      </div>
    );

    return (
      <PopUp
        showPopUp
        showOverlay={false}
        title={t('whichDevice')}
        body={body}
        footer={footer}
        hidePopUp={this.props.onClose}
        style={{
          position: 'fixed',
          width: '600px',
          height: 'auto',
          marginLeft: '-300px',
          top: '22%',
        }}
      />
    );
  }
}

export const OmnipodDashSelectDialogWithTranslation =
  translate('OmnipodDashSelectDialog')(OmnipodDashSelectDialog);
export default connect(mapStateToProps, mapDispatchToProps)(OmnipodDashSelectDialogWithTranslation);
