import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { Trans, useTranslation } from 'react-i18next';
import Images from '../../../../utils/ImageStore';
import Style from './FileUploaderPopup.scss';
import SupportLinks from '../../../../utils/support';

export interface FileUploaderPopupSuccessProps {
  fileName: string;
  onDone: () => void;
  onViewData: () => void;
}

const FileUploaderPopupSuccess = (props: FileUploaderPopupSuccessProps) => {
  const { t } = useTranslation('FileUploaderPopup');
  const { onDone, onViewData, fileName } = props;

  return (
    <Dialog dataAttributes={{ testid: 'file-uploader-success' }} onClose={onDone} open size="medium">
      <DialogHeader dataAttributes={{ testid: 'file-uploader-success' }}>
        <h2>{t('uploadSuccessTitle')}</h2>
      </DialogHeader>

      <DialogContent dataAttributes={{ testid: 'file-uploader-success' }}>
        <div className={Style.success}>
          {t('uploadSuccessFile')}: {fileName}
          <img alt="" src={Images.integrationTickSuccess} />
        </div>

        <p>{t('uploadSuccessMsgLine1')}</p>

        <p>
          {t('uploadSuccessMsgLine2')}<br />
          {t('uploadSuccessMsgLine3')}
        </p>

        <p>
          <em>
            <Trans
              components={{
                supportLink: <a href={SupportLinks.fileTicket()} rel="noreferrer" target="_blank" />,
              }}
              i18nKey="needHelp"
              t={t}
            />
          </em>
        </p>
      </DialogContent>

      <DialogActions dataAttributes={{ testid: 'file-uploader-success' }}>
        <Button
          dataAttributes={{ testid: 'file-uploader-success-done' }}
          onClick={onDone}
          variation="secondary"
        >
          {t('uploadSuccessDoneButton')}
        </Button>

        <Button dataAttributes={{ testid: 'file-uploader-success-view-data' }} onClick={onViewData}>{t('uploadSuccessViewDataButton')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploaderPopupSuccess;
