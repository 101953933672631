import React from 'react';
import PropTypes from 'prop-types';
import Readings from '~/services/Readings';
import images from '~/utils/ImageStore';
import {
  OVERLAY_SPAGHETTI,
  OVERLAY_AGP,
  OVERLAY_SCATTER,
  OVERLAY_BOX_PLOT,
} from '~/redux/modules/graphs/graphs';
import { numberFormatterOneDecimal } from '~/utils/i18nFormats';
import { connect } from 'react-redux';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import UserHelper from '~/redux/modules/users/UserHelper';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import GraphsLegendPresenter from './GraphsLegendPresenter';
import Group from './GraphsLegendGroup';
import Item from './GraphsLegendItem';

const mapStateToProps = (state, ownProps) => {
  const { beforeMealNormalGlucoseMax, afterMealNormalGlucoseMax, normalGlucoseMin, meterUnits } =
    UserHelper.displayUser(state).preference;

  return {
    beforeMealMax: Readings.displayValue(
      Readings.denormalizedValue(beforeMealNormalGlucoseMax),
      meterUnits,
    ),
    afterMealMax: Readings.displayValue(
      Readings.denormalizedValue(afterMealNormalGlucoseMax),
      meterUnits,
    ),
    normalMin: Readings.displayValue(
      Readings.denormalizedValue(normalGlucoseMin),
      meterUnits,
    ),
    meterUnits,
    displayMeterUnits: Readings.displayMeterUnits(meterUnits),
    graphTypeSelected: ownProps.graphTypeSelected || GraphsHelper.selectedGraphType(state),
  };
};

class OverlayLegend extends React.Component {
  render() {
    const above400Value =
      numberFormatterOneDecimal(Readings.displayValue(400, this.props.meterUnits));
    const isAGP = this.props.graphTypeSelected === OVERLAY_AGP;
    const isBoxPlot = this.props.graphTypeSelected === OVERLAY_BOX_PLOT;
    const isScatter = this.props.graphTypeSelected === OVERLAY_SCATTER;
    const isSpaghetti = this.props.graphTypeSelected === OVERLAY_SPAGHETTI;
    const inPdf = this.props.inPdf;

    return (
      <GraphsLegendPresenter inSummaryPage={this.props.inSummaryPage} agpLegend={isAGP}>
        <Group visible={isAGP || isBoxPlot}>
          <Item
            visible
            inPdf={inPdf}
            src={images.GlucoseAfterMeal}
            label={this.props.t('targetRange', {
              normalMin: numberFormatterOneDecimal(this.props.normalMin),
              afterMealMax: numberFormatterOneDecimal(this.props.afterMealMax),
              meterUnits: this.props.displayMeterUnits,
            })}
          />
          <Item
            visible
            src={images.Median}
            label={this.props.t('median')}
          />
        </Group>
        <Group visible={isAGP || isBoxPlot}>
          <Item
            visible
            src={images.Percentile2575}
            label={this.props.t('middlePercentiles')}
          />
          <Item
            visible
            src={images.Percentile1090}
            label={this.props.t('endPercentiles')}
          />
        </Group>
        <Group visible={isAGP || isBoxPlot}>
          <Item
            visible
            src={images.LowestHighest}
            label={this.props.t('loHi')}
          />
        </Group>
        <Group visible={isScatter}>
          <Item
            visible
            src={images.GlucoseBeforeMeal}
            label={this.props.t('beforeMeal', {
              normalMin: numberFormatterOneDecimal(this.props.normalMin),
              beforeMealMax: numberFormatterOneDecimal(this.props.beforeMealMax),
              meterUnits: this.props.displayMeterUnits,
            })}
          />
          <Item
            visible
            src={images.GlucoseAfterMeal}
            label={this.props.t('afterMeal', {
              normalMin: numberFormatterOneDecimal(this.props.normalMin),
              afterMealMax: numberFormatterOneDecimal(this.props.afterMealMax),
              meterUnits: this.props.displayMeterUnits,
            })}
          />
          <Item
            visible
            src={images.Median}
            label={this.props.t('average')}
          />
        </Group>
        <Group visible={isScatter}>
          <Item
            visible
            src={images.GlucoseAboveRangeC}
            label={this.props.t('aboveRange')}
          />
          <Item
            visible
            src={images.GlucoseInTargetRangeC}
            label={this.props.t('inTargetRange')}
          />
          <Item
            visible
            src={images.GlucoseBelowRangeC}
            label={this.props.t('belowRange')}
          />
        </Group>
        <Group visible={isScatter}>
          <Item
            visible
            src={images.GlucoseBgReadings}
            label={this.props.t('bgReadings')}
          />
          <Item
            visible
            src={images.GlucosePumpBg}
            label={this.props.t('pumpBg')}
          />
          <Item
            visible
            src={images.GlucoseAbove400}
            label={this.props.t('above', { amount: above400Value })}
          />
          <Item
            visible
            src={images.GlucoseAbove400Pump}
            label={this.props.t('pumpAbove', { amount: above400Value })}
          />
        </Group>
        <Group visible={isSpaghetti}>
          <Item
            visible
            src={images.GlucoseAfterMeal}
            label={this.props.t('targetRange', {
              normalMin: numberFormatterOneDecimal(this.props.normalMin),
              afterMealMax: numberFormatterOneDecimal(this.props.afterMealMax),
              meterUnits: this.props.displayMeterUnits,
            })}
          />
          <Item
            visible
            src={images.CgmMonday}
            label={this.props.t('monday')}
          />
          <Item
            visible
            src={images.CgmTuesday}
            label={this.props.t('tuesday')}
          />
        </Group>
        <Group visible={isSpaghetti}>
          <Item
            visible
            src={images.CgmWednesday}
            label={this.props.t('wednesday')}
          />
          <Item
            visible
            src={images.CgmThursday}
            label={this.props.t('thursday')}
          />
          <Item
            visible
            src={images.CgmFriday}
            label={this.props.t('friday')}
          />
        </Group>
        <Group visible={isSpaghetti}>
          <Item
            visible
            src={images.CgmSaturday}
            label={this.props.t('saturday')}
          />
          <Item
            visible
            src={images.CgmSunday}
            label={this.props.t('sunday')}
          />
        </Group>
      </GraphsLegendPresenter>
    );
  }
}

OverlayLegend.propTypes = {
  normalMin: PropTypes.number.isRequired,
  beforeMealMax: PropTypes.number.isRequired,
  afterMealMax: PropTypes.number.isRequired,
  meterUnits: PropTypes.string.isRequired,
  displayMeterUnits: PropTypes.string.isRequired,
  inSummaryPage: PropTypes.bool,
  t: PropTypes.func,
  graphTypeSelected: PropTypes.oneOf([
    OVERLAY_SPAGHETTI,
    OVERLAY_AGP,
    OVERLAY_SCATTER,
    OVERLAY_BOX_PLOT,
  ]).isRequired,
  inPdf: PropTypes.bool,
};

OverlayLegend.defaultProps = {
  normalMin: 70,
  beforeMealMax: 130,
  afterMealMax: 180,
  meterUnits: Readings.MGDL,
  displayMeterUnits: '',
  graphTypeSelected: OVERLAY_AGP,
  inSummaryPage: false,
  t: (s) => s,
  inPdf: false,
};

export default translate('GraphsLegend')(
  connect(mapStateToProps, null)(OverlayLegend),
);
