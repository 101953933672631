/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, TextInput } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { PAGINATION_PAGE_SIZE } from '../../../shared/constants/tables';
import ChipList from '../../../shared/components/ChipList/ChipList';
import FilterSection from './FilterSection/FilterSection';
import FilterPatientsActions from './FilterPatientsActions/FilterPatientsActions';
import Styles from './FilterPatientsPresenter.scss';
import DropdownButton from '../../../../bundles/shared/components/DropdownButton/DropdownButton';

export class FilterPatientsPresenter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    filters: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      customTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      patientStatuses: PropTypes.arrayOf(PropTypes.shape({})),
      careProgramTags: PropTypes.arrayOf(PropTypes.shape({})),
      flags: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    currentFilters: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      customTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      patientStatuses: PropTypes.arrayOf(PropTypes.shape({})),
      careProgramTags: PropTypes.arrayOf(PropTypes.shape({})),
      flags: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    searchString: PropTypes.string.isRequired,
    includeDeactivated: PropTypes.bool.isRequired,
    showIncludeDeactivated: PropTypes.bool.isRequired,
    showFlags: PropTypes.bool.isRequired,
    showDuplicatesFilter: PropTypes.bool,
    isShowingDuplicates: PropTypes.bool,
    expanded: PropTypes.bool.isRequired,
    isApplyDisabled: PropTypes.bool.isRequired,
    paginationData: PropTypes.object.isRequired,
    onDropdownClick: PropTypes.func.isRequired,
    onFilterClick: PropTypes.func.isRequired,
    onSearchKeyUp: PropTypes.func.isRequired,
    onIncludeDeactivatedClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onApplyClick: PropTypes.func.isRequired,
    onClearFilter: PropTypes.func.isRequired,
    onClearAll: PropTypes.func.isRequired,
    isShowCareProgramsTags: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    showDuplicatesFilter: false,
    isShowingDuplicates: false,
  }

  constructor(props) {
    super(props);
    const { t } = props;
    this.flagTranslations = {
      dropout_risk: t('dropoutRisk'),
      hyper_rate: t('hyperRate'),
      hypo_rate: t('hypoRate'),
      marked_high: t('markedHigh'),
      marked_low: t('markedLow'),
    };
    this.patientStatusTranslations = {
      Activated: t('activated'),
      Invite_Pending: t('invitePending'),
      Not_Invited: t('notInvited'),
    };
  }

  filterData = (section) => this.filterFilters(this.props.currentFilters[section]);

  filterFilters = (filters) => {
    const { searchString } = this.props;
    const keywords = searchString.trim().split(' ');

    if (searchString === '') return filters;
    return filters.filter((x) => new RegExp(keywords.join('|'), 'gi').test(x.label));
  };

  searchableContentHeight = () => {
    const { currentFilters, showIncludeDeactivated } = this.props;

    const FILTER_SECTION_HEADER_HEIGHT = 20;
    const FILTER_HEIGHT = 24;
    const FILTER_SECTION_MARGIN_BOTTOM = 12;
    const FILTER_SECTION_WITHOUT_FILTERS = FILTER_SECTION_HEADER_HEIGHT +
                                           FILTER_SECTION_MARGIN_BOTTOM;
    const INCLUDE_DEACTIVATED_PATIENTS_HEIGHT = FILTER_SECTION_HEADER_HEIGHT +
                                                FILTER_HEIGHT +
                                                FILTER_SECTION_MARGIN_BOTTOM;

    const initialHeight = showIncludeDeactivated ? INCLUDE_DEACTIVATED_PATIENTS_HEIGHT : 0;

    return Object.entries(currentFilters).reduce((acc, dataPair) => {
      const dataLength = dataPair[1].length;
      const count = dataLength > 5 ? 5 : dataLength;

      if (dataLength === 0) return acc;

      return acc + FILTER_SECTION_WITHOUT_FILTERS + (count * FILTER_HEIGHT);
    }, initialHeight);
  };

  retrieveFlagTranslations = () => {
    const translatedFlags = [];
    this.filterData('flags').forEach((flag) => {
      const translatedFlag = { ...flag };
      if ((flag.originalId in this.flagTranslations) && flag.ownedByGlooko) {
        translatedFlag.label = this.flagTranslations[flag.originalId];
      }
      translatedFlags.push(translatedFlag);
    });
    return translatedFlags;
  }

  retrievePatientStatusTranslations = (filters) => {
    const translatedPatientStatuses = [];

    if (filters) {
      filters.forEach((status) => {
        const translatedStatus = { ...status };
        const id = status.id.split('-')[1];
        if ((id in this.patientStatusTranslations)) {
          translatedStatus.label = this.patientStatusTranslations[id];
        }
        translatedPatientStatuses.push(translatedStatus);
      });
    }
    return translatedPatientStatuses;
  }

  chipData = () => {
    const { t, filters, isShowingDuplicates } = this.props;
    const selectedTags = filters.tags.filter((e) => e.checked);
    const selectedCustomTags = filters.customTags.filter((e) => e.checked);
    const selectedPatientStatuses = this.retrievePatientStatusTranslations(filters.patientStatuses)
      .filter((e) => e.checked);
    const selectedCareProgramTags = filters.careProgramTags.filter((e) => e.checked);
    const selectedFlags = this.retrieveFlagTranslations().filter((e) => e.checked);
    const viewFilters = isShowingDuplicates ? [{ id: 'view-duplicates-0', label: t('duplicateAccounts') }] : [];

    const result = [];

    if (viewFilters.length > 0) {
      result.push({ label: t('view'), chips: viewFilters });
    }
    if (selectedTags.length > 0) {
      result.push({ label: t('providerTag'), chips: selectedTags });
    }
    if (selectedCustomTags.length > 0) {
      result.push({ label: t('customTag'), chips: selectedCustomTags });
    }
    if (selectedPatientStatuses.length > 0) {
      result.push({ label: t('glookoPatientAccount'), chips: selectedPatientStatuses });
    }
    if (selectedCareProgramTags.length > 0) {
      result.push({ label: t('careProgram'), chips: selectedCareProgramTags });
    }
    if (selectedFlags.length > 0) {
      result.push({ label: t('flags'), chips: selectedFlags });
    }
    return result;
  };

  paginationCounterRange() {
    const { currentPage, totalCount } = this.props.paginationData;

    if (currentPage < 0) {
      return null;
    }

    const first = totalCount ? ((currentPage - 1) * PAGINATION_PAGE_SIZE) + 1 : 0;
    const last = totalCount ? Math.min(currentPage * PAGINATION_PAGE_SIZE, totalCount) : 0;
    return first ? `${first}-${last}` : last;
  }

  renderCareProgram = () => {
    const { t, onFilterClick, isShowCareProgramsTags } = this.props;
    if (!isShowCareProgramsTags) return null;
    return (
      <FilterSection
        title={t('careProgram')}
        filters={this.filterData('careProgramTags')}
        onFilterClick={onFilterClick}
      />
    );
  };

  renderPatientStatuses = () => {
    const { t, currentFilters, onFilterClick,
    } = this.props;

    if (!currentFilters.patientStatuses) {
      return null;
    }

    const patientStatuses = this.filterFilters(
      this.retrievePatientStatusTranslations(currentFilters.patientStatuses),
    );

    return (
      <FilterSection
        title={t('glookoPatientAccount')}
        filters={patientStatuses}
        onFilterClick={onFilterClick}
      />
    );
  };

  renderFlags = () => {
    const { t, showFlags, onFilterClick } = this.props;
    const flags = this.retrieveFlagTranslations();
    const sortedFlags = [...flags]
      .sort((a, b) => a.label.localeCompare(b.label));

    if (!showFlags) return null;

    return (
      <FilterSection
        title={t('flags')}
        filters={sortedFlags}
        onFilterClick={onFilterClick}
      />
    );
  };

  renderIncludeDeactivated = () => {
    const {
      t,
      searchString,
      includeDeactivated,
      showIncludeDeactivated,
      onIncludeDeactivatedClick,
    } = this.props;

    if (!showIncludeDeactivated) {
      return null;
    }

    const filters = [{
      id: 'include_deactivated-0',
      label: t('includeDeactivatedPatients'),
      checked: includeDeactivated,
    }];
    const keywords = searchString.trim().split(' ');
    const match = new RegExp(keywords.join('|'), 'gi').test(t('includeDeactivatedPatients'));

    if (searchString !== '' && !match) return null;

    return (
      <FilterSection
        title={t('research')}
        filters={filters}
        onFilterClick={onIncludeDeactivatedClick}
      />
    );
  }

  renderDuplicates = () => {
    const {
      t,
      showDuplicatesFilter,
      isShowingDuplicates,
      onDuplicatesFilterClick,
    } = this.props;

    if (!showDuplicatesFilter) {
      return null;
    }

    const filters = [{
      id: 'show_duplicates-filter-0',
      label: t('duplicateAccounts'),
      checked: isShowingDuplicates,
    }];

    return (
      <FilterSection
        title={t('view')}
        filters={filters}
        onFilterClick={onDuplicatesFilterClick}
      />
    );
  }

  renderPaginationCounter() {
    const { totalCount } = this.props.paginationData;
    const range = this.paginationCounterRange();

    if (range === null) {
      return null;
    }

    return (
      <div className={Styles['page-counter']}>
        {this.props.t('paginationCounter', { range, totalCount })}
      </div>
    );
  }

  render() {
    const {
      t, expanded, onDropdownClick, onSearchKeyUp,
      onFilterClick, isApplyDisabled, onCancelClick, onApplyClick,
      showDuplicatesFilter, onShowDuplicatesClick, isShowingDuplicates,
      onClearAll, onClearFilter,
    } = this.props;

    return (
      <div className={Styles['filter-patients']}>
        <div className={Styles['filter-patients__dropdown']}>
          <DropdownButton text={t('filterPatients')} expanded={expanded} onClick={onDropdownClick}>
            <div className={Styles['search-filters']}>
              <div className={Styles['search-filters__search']}>
                <TextInput
                  type="search"
                  placeholder={t('searchFilters')}
                  inputProps={{ onKeyUp: onSearchKeyUp }}
                  dataAttributes={{ testid: 'filter-patients-search-filters' }}
                />
              </div>
              <div
                style={{ height: this.searchableContentHeight() }}
                className={Styles['search-filters__scrollable-content']}
              >
                {this.renderIncludeDeactivated()}
                <FilterSection
                  title={t('providerTag')}
                  filters={this.filterData('tags')}
                  onFilterClick={onFilterClick}
                />
                <FilterSection
                  title={t('customTag')}
                  filters={this.filterData('customTags')}
                  onFilterClick={onFilterClick}
                />
                {this.renderPatientStatuses()}
                {this.renderCareProgram()}
                {this.renderFlags()}
                {this.renderDuplicates()}
              </div>
              <FilterPatientsActions
                isApplyDisabled={isApplyDisabled}
                onCancelClick={onCancelClick}
                onApplyClick={onApplyClick}
              />
            </div>
          </DropdownButton>
        </div>
        {this.renderPaginationCounter()}

        {showDuplicatesFilter && !isShowingDuplicates &&
          <Button
            className={Styles.duplicateAccountsButton}
            dataAttributes={{ testid: 'poptracker-duplicate-accounts' }}
            variation="secondary"
            onClick={onShowDuplicatesClick}
          >{t('duplicateAccounts')}
          </Button>}

        <div className={Styles['selected-filters']}>
          <ChipList
            clearAllText={t('clearAll')}
            onClearAll={onClearAll}
            onClearChip={onClearFilter}
            chips={this.chipData()}
          />
        </div>
      </div>
    );
  }
}
export default translate('FilterPatientsPresenter')(FilterPatientsPresenter);
