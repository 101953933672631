import { connect } from 'react-redux';
import { keyFromParams } from 'redux/modules/graphs/graphs';
import { getAxisMaxFromRedux } from 'bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/HoursGraphHelpers';
import UserHelper from 'redux/modules/users/UserHelper';
import Readings from 'services/Readings';
import WithTranslate from 'bundles/shared/components/WithTranslate/WithTranslate';
import OverviewGraphHoursContainer from 'bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/OverviewGraphHoursContainer';
import AxisPresenter from 'bundles/graphs/components/AxisPresenter/AxisPresenter';
import classNames from 'classnames';
import GraphsHelper from 'redux/modules/graphs/GraphsHelper';
import { GRAPH_NAME_HOURS_EXERCISE } from 'bundles/shared/constants/exercise';
import { useGraphAxis } from 'bundles/graphs/hooks/useGraphAxis';
import { hasControlIqData, hasBasalIqData, hasOp5Data, hasMedtronicData, hasCamapsData } from '../../../../../../services/hasDeviceData';
import Style from './OverviewGraphHoursWithAxis.scss';

export const GRAPH_NAME = 'OverviewHours';

export interface OverviewGraphHoursWithAxisProps extends WithTranslationFunction {
  startDate: string;
  endDate: string;
  bolusAxisEndIndex: number;
  basalAxisEndIndex?: number;
  bgGraphAxisEndIndex: number;
  graphsWindowWidth: number;
  meterUnits: string;
  cgmDeviceDataBrand?: string;
  hasExercise: boolean;
  hasOmnipod5: boolean;
  hasControlIQ?: boolean;
  hasBasalIq?: boolean;
  hasCamaps?: boolean;
  hasPump: boolean;
  hasMedtronicClosedLoopData?: boolean;
  hasInsulinData?: boolean;
  exerciseSeries: GraphSeries[];
  exerciseFetchStatus: string;
}

export interface OverviewGraphHoursWithAxisState extends State {
  graphs: { [key: string]: { name: string; data: object[] }[] };
}

const mapStateToProps = (state: OverviewGraphHoursWithAxisState) => {
  const seriesKey = keyFromParams({
    startTimestamp: state.page.startDate,
    endTimestamp: state.page.endDate,
    id: GRAPH_NAME,
  });
  const seriesKeyExercise = keyFromParams({
    startTimestamp: state.page.startDate,
    endTimestamp: state.page.endDate,
    id: GRAPH_NAME_HOURS_EXERCISE,
  });

  const displayUser = UserHelper.displayUser(state);
  const overall = state.statistics.overall;

  const overviewHoursSeries = state.graphs[seriesKey] as { name: string, data: object[] }[];
  const cgmDeviceDataBrand = Array.isArray(overviewHoursSeries) && overviewHoursSeries.find((s) => s.name === 'cgmDeviceDataBrand')?.data;

  const { series: exerciseSeries, status: exerciseFetchStatus } =
    GraphsHelper.retrieveFetchStatusAndSeries(state, seriesKeyExercise);

  const hasExercise = GraphsHelper.showExerciseHourGraph(state);

  return {
    ...getAxisMaxFromRedux(state, seriesKey),
    meterUnits: displayUser.preference.meterUnits,
    hasInsulinData: UserHelper.hasPenDevice(state) || UserHelper.hasManualData(state),
    hasPump: UserHelper.hasPump(state),
    hasControlIQ: hasControlIqData(displayUser, overall),
    hasBasalIq: hasBasalIqData(displayUser, overall),
    cgmDeviceDataBrand,
    hasCamaps: hasCamapsData(overall),
    hasOmnipod5: hasOp5Data(displayUser, overall),
    hasMedtronicClosedLoopData: hasMedtronicData(displayUser, overall),
    startDate: state.page.startDate,
    endDate: state.page.endDate,
    hasExercise,
    exerciseSeries,
    exerciseFetchStatus,
  };
};

const WINDOW_DEFAULT_HEIGHT = 690;
const EXERCISE_GRAPH_HEIGHT = 72;

const OverviewGraphHoursWithAxis = (props: OverviewGraphHoursWithAxisProps) => {
  const { exerciseAxisMax } = useGraphAxis();
  const {
    hasInsulinData,
    hasPump,
    hasControlIQ,
    hasOmnipod5,
    hasMedtronicClosedLoopData,
    hasBasalIq,
    hasCamaps,
    cgmDeviceDataBrand,
    graphsWindowWidth,
    startDate,
    endDate,
    bgGraphAxisEndIndex,
    meterUnits,
    bolusAxisEndIndex,
    basalAxisEndIndex,
    exerciseSeries,
    exerciseFetchStatus,
    hasExercise,
    t,
  } = props;

  const exerciseAxisEnd = exerciseAxisMax(exerciseSeries);
  const title = (hasPump && !hasInsulinData) ? t('bolusTitle') : t('manualTitle');
  const axisTitle = (hasPump && hasInsulinData) ? t('insulinTitle') : title;
  const heightOverride = WINDOW_DEFAULT_HEIGHT -
    (Number(!hasControlIQ) * 40) -
    (Number(!hasOmnipod5) * 62) -
    (Number(!hasExercise) * EXERCISE_GRAPH_HEIGHT) -
    (Number(!hasPump) * 20);

  return (
    <div className={Style.OverviewGraphHoursWithAxis} style={{ height: heightOverride }}>
      <div className={Style.wrapper} style={{ width: graphsWindowWidth }}>
        <OverviewGraphHoursContainer
          key={startDate}
          cacheGraphs
          endTimestamp={endDate}
          exerciseAxisEnd={exerciseAxisEnd}
          exerciseFetchStatus={exerciseFetchStatus}
          exerciseSeries={exerciseSeries}
          graphsWindowWidth={graphsWindowWidth}
          hasBasalIqData={hasBasalIq}
          hasCamapsData={hasCamaps}
          hasControlIQ={hasControlIQ}
          hasExercise={hasExercise}
          hasInsulinData={hasInsulinData}
          hasMedtronicClosedLoopData={hasMedtronicClosedLoopData}
          hasOmnipod5={hasOmnipod5}
          hasPump={hasPump}
          startTimestamp={startDate}
        />

        {cgmDeviceDataBrand ? (
          <div className={Style.cgmDeviceDataLabel}>
            {t('cgmDeviceData', { brand: cgmDeviceDataBrand })}
          </div>
        ) : null}
      </div>

      <div className={Style.axis}>
        <div className={classNames(Style.borderBottom, Style.firstElem)} style={{ height: 32 }}>
          <div className={Style.first}>
            &nbsp;
          </div>
        </div>

        <div className={Style.borderBottom}>
          <AxisPresenter
            endIndex={bgGraphAxisEndIndex}
            startIndex={0}
            subtitle={Readings.displayMeterUnits(meterUnits)}
            tickStep={meterUnits === Readings.MMOLL ? 5 : 100}
            title={t('glucoseTitle')}
            windowHeight={250 + 1}
            windowWidth={176}
          />
        </div>

        <div className={Style.borderBottom}>
          <AxisPresenter
            endIndex={10}
            hideAxis
            startIndex={0}
            subtitle={`(${t('grams')})`}
            tickStep={5}
            title={t('carbsTitle')}
            windowHeight={40 + 1}
            windowWidth={113}
          />
        </div>

        <div className={Style.borderBottom}>
          <AxisPresenter
            endIndex={bolusAxisEndIndex}
            startIndex={0}
            subtitle={`(${t('units')})`}
            tickStep={bolusAxisEndIndex / 2}
            title={axisTitle}
            windowHeight={72 + 1}
            windowWidth={176}
          />
        </div>

        {hasPump && basalAxisEndIndex ? (
          <div className={Style.borderBottom}>
            <AxisPresenter
              endIndex={basalAxisEndIndex}
              startIndex={0}
              subtitle={`(${t('units')})`}
              tickStep={basalAxisEndIndex / 2}
              title={t('basalTitle')}
              windowHeight={72 + 1}
              windowWidth={176}
            />
          </div>
        ) : null}

        {(hasControlIQ || hasBasalIq) ? (
          <div className={Style.borderBottom}>
            <AxisPresenter
              endIndex={1}
              hideAxis
              startIndex={0}
              subtitle={`(${t('controlIQ')})`}
              tickStep={0.5}
              title={t('systemTitle')}
              windowHeight={39 + 1}
              windowWidth={176}
            />
          </div>
        ) : null}

        {hasMedtronicClosedLoopData ? (
          <div className={Style.borderBottom}>
            <AxisPresenter
              endIndex={1}
              hideAxis
              startIndex={0}
              subtitle={`(${t('medtronic', { model: '670G' })})`}
              tickStep={0.5}
              title={t('systemTitle')}
              windowHeight={39 + 1}
              windowWidth={176}
            />
          </div>
        ) : null}

        {hasOmnipod5 ? (
          <div className={Style.borderBottom}>
            <AxisPresenter
              endIndex={1}
              hideAxis
              startIndex={0}
              subtitle={`(${t('omnipod5')})`}
              tickStep={0.5}
              title={t('systemTitle')}
              windowHeight={39 + 1}
              windowWidth={176}
            />
          </div>
        ) : null}

        {hasOmnipod5 ? (
          <div className={Style.borderBottom}>
            <AxisPresenter
              endIndex={1}
              hideAxis
              startIndex={0}
              tickStep={0.5}
              title={t('basalBar')}
              windowHeight={20 + 1}
              windowWidth={176}
            />
          </div>
        ) : null}

        {hasExercise ? (
          <div className={classNames(Style.borderBottom, Style.lastElem)}>
            <AxisPresenter
              endIndex={exerciseAxisEnd}
              startIndex={0}
              subtitle={`(${t('hours')})`}
              tickStep={exerciseAxisEnd / 2}
              title={t('exerciseTitle')}
              windowHeight={72}
              windowWidth={176}
            />
          </div>
        ) : null}

        {hasCamaps ? (
          <div className={Style.borderBottom}>
            <AxisPresenter
              endIndex={1}
              hideAxis
              startIndex={0}
              subtitle={`(${t('CamAPSFX')})`}
              tickStep={0.5}
              title={t('systemTitle')}
              windowHeight={39 + 1}
              windowWidth={176}
            />
          </div>
        ) : null}

        <div style={{ clear: 'both' }} />
      </div>

      <div style={{ clear: 'both' }} />
    </div>
  );
};

export default WithTranslate('OverviewGraphHoursWithAxis')(connect(mapStateToProps)(OverviewGraphHoursWithAxis));
