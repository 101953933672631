import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ExecuteUnchangedApiCall } from 'services/apiClient';

export const useReferenceDeviceList = () => useQuery({
  queryKey: ['reference_device_list'],
  queryFn: () => ExecuteUnchangedApiCall('get', 'api/v2/reference/devices').then((res: AxiosResponse<ReferenceDeviceResponse>) => res.data),
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  retryOnMount: false,
  retry: false,
});
