import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { useCustomMrnInfo } from 'bundles/shared/hooks/useCustomMrnInfo';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import TimeUtils from '~/utils/TimeUtils/TimeUtils';
import { truncateText } from '~/utils/textHelper.js';
import Style from './PatientBlocks.scss';

class PatientBlocks extends React.Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    sortedPatients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectBlock: PropTypes.func.isRequired,
    showCreateNewPatientModal: PropTypes.func.isRequired,
    selectedBlock: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    t: () => {},
  };

  static formatLastSyncTimestamp(timestamp) {
    return TimeUtils.formatDate(
      get(timestamp, 'lastSyncTimestamp[1]', ''),
      TIME_FORMATS.MMM_DD_YYYY,
    );
  }

  static formatDobIfValid(dob) {
    return TimeUtils.isDateValid(dob, TIME_FORMATS.YYYY_MM_DD) ?
      TimeUtils.formatDate(dob, TIME_FORMATS.MM_DD_YYYY) : '';
  }

  selectBlock = (e) => {
    this.props.selectBlock(e, this.props.sortedPatients.length);
  }

  isSelected = (itemCode) => {
    const { selectedBlock } = this.props;
    return itemCode === selectedBlock.id;
  }

  handlePatientStatus = (status) => {
    const { t } = this.props;
    return status ? t('activated') : '';
  }

  // not used
  showCreateNewPatientModal = () => {
    this.props.showCreateNewPatientModal();
  }

  render() {
    const { t, firstName, lastName, dob, sortedPatients } = this.props;
    const { labelMrn } = useCustomMrnInfo(t('mrn'));
    return (
      <div class={Style.patientsContainer}>
        <div class={Style.patientsContainerTitle}>
          {t('assignToExisting')}
        </div>
        {sortedPatients.map((item, index) => (
          <div
            class={classNames({
              [Style.patientBlock]: true,
              [Style.isSelected]: this.isSelected(item.glookoCode),
            })}
            key={`${item.glookoCode}_${index}`}
          >
            <div
              class={Style.patientBlockTrigger}
              onClick={this.selectBlock}
              data-id={item.glookoCode}
              data-glookocode={item.glookoCode}
              data-dateofbirth={item.dateOfBirth}
              data-icpadetails={JSON.stringify(item)}
              role="presentation"
            />
            <div class={Style.flexColumn}>
              <div
                class={classNames({
                  [Style.patientBlockName]: true,
                  [Style.isSelected]: this.isSelected(item.glookoCode),
                  [Style.fixPadding]: !item.dateOfBirth &&
                                      !item.mrn &&
                                      !item.activated &&
                                      item.lastSyncTimestamp,
                })}
              >
                {item.firstName} {item.lastName}
              </div>
              <div
                class={classNames({
                  [Style.patientBlockDob]: true,
                  [Style.isSelected]: this.isSelected(item.glookoCode),
                  [Style.isEmpty]: !PatientBlocks.formatDobIfValid(
                    item.dateOfBirth,
                  ),
                })}
              >
                {PatientBlocks.formatDobIfValid(item.dateOfBirth)}
              </div>
              <div
                class={classNames({
                  [Style.patientBlockStatus]: true,
                  [Style.isSelected]: this.isSelected(item.glookoCode),
                  [Style.isEmpty]: !this.handlePatientStatus(item.activated),
                })}
              >{item.activated ? t('activated') : ''}
              </div>
            </div>
            <div
              class={classNames({
                [Style.patientBlockLastSync]: true,
                [Style.isSelected]: this.isSelected(item.glookoCode),
                [Style.isEmpty]: !PatientBlocks.formatLastSyncTimestamp(
                  item,
                ),
              })}
            >
              <div class={Style.labelGrey}>{t('lastSync')}</div>
              <div class={Style.labelValue}>
                {PatientBlocks.formatLastSyncTimestamp(item)}
              </div>
            </div>
            <div
              class={classNames({
                [Style.patientBlockMrn]: true,
                [Style.isSelected]: this.isSelected(item.glookoCode),
                [Style.fixMargin]: !item.dateOfBirth &&
                                   !item.lastSyncTimestamp &&
                                   !item.activated,
              })}
            >
              <div class={Style.labelGrey}>{truncateText(labelMrn, 10) || t('mrn')}</div>
              <div class={Style.mrnValue}>
                {item.mrn ? (t('mrnPrefix') + item.mrn) : '\u2014'} {/* this is unicode for emdash */}
              </div>
            </div>
          </div>
        ))}
        <div class={Style.patientsContainerTitle}>
          {t('createNewPatient')}
        </div>
        <div
          class={classNames({
            [Style.patientBlock]: true,
            [Style.isSelected]: this.isSelected('create'),
            [Style.isLast]: true,
            [Style.isLastSelected]: this.isSelected('create'),
          })}
          role="presentation"
        >
          <div
            class={Style.patientBlockTrigger}
            onClick={this.selectBlock}
            data-id="create"
            role="presentation"
          />
          <div class={Style.flexColumn}>
            <div
              class={classNames({
                [Style.patientBlockName]: true,
                [Style.isSelected]: this.isSelected('create'),
              })}
            >
              {firstName} {lastName}
            </div>
            <div
              class={classNames({
                [Style.patientBlockDob]: true,
                [Style.isSelected]: this.isSelected('create'),
              })}
            >
              {PatientBlocks.formatDobIfValid(dob)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('PatientBlocks')(PatientBlocks);
