import React from 'react';
import moment from 'moment';
import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTimestamps } from '~/redux/modules/page/page';
import OverviewBreadcrumbs from '~/bundles/graphs/components/OverviewTabContainer/OverviewBreadcrumbs/OverviewBreadcrumbs.jsx';
import PregnancyBadge from '~/bundles/shared/components/PregnancyBadge/PregnancyBadge.jsx';
import classNames from 'classnames';
import { trackWebTimeFrameShifted } from '~/services/eventLogging';
import UserHelper from '~/redux/modules/users/UserHelper';
import TimeUtils from '../../../../utils/TimeUtils/TimeUtils';
import Style from './TimeFrameArrowSelector.scss';

const mapStateToProps = (state) => {
  let endDateMax = state.page.endDateCommon;
  endDateMax = endDateMax ? moment(endDateMax) : moment.utc();
  const { dueDate } = state.users.currentUsers.currentPatient;

  return {
    startDate: moment.utc(state.page.startDate),
    endDate: moment.utc(state.page.endDate),
    availableTimeFrames: state.page.availableTimeFrames,
    timeFrameIndex: state.page.timeFrameIndex,
    currentUserIsProvider: !UserHelper.currentUserIsPatient(state),
    endDateMax,
    currentPatientDueDate: dueDate,
    hasPregnancyPackage: state.providerGroupSite.pregnancyPackage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updateTimestamps }, dispatch),
});

export class TimeFrameArrowSelector extends React.Component {
  static propTypes = {
    startDate: MomentPropTypes.momentObj.isRequired,
    endDate: MomentPropTypes.momentObj.isRequired,
    endDateMax: MomentPropTypes.momentObj,
    currentUserIsProvider: PropTypes.bool.isRequired,
    currentPatientDueDate: PropTypes.string,
    hasPregnancyPackage: PropTypes.bool,
    updateTimestampsCallback: PropTypes.func,
    actions: PropTypes.shape({
      updateTimestamps: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    startDate: moment.utc(),
    endDate: moment.utc,
    endDateMax: moment.utc(),
    currentUserIsProvider: false,
    currentPatientDueDate: undefined,
    hasPregnancyPackage: false,
    actions: {
      updateTimestamps: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.updateTimestamps = this.updateTimestamps.bind(this);
    this.buttonDisabled = this.buttonDisabled.bind(this);
  }

  updateTimestamps(direction) {
    const { startDate, endDate, actions, updateTimestampsCallback } = this.props;
    const diff = endDate.diff(startDate, 'milliseconds');
    const newEndDate = moment.utc(endDate).add((diff + 1) * direction, 'milliseconds');
    const newStartDate = moment.utc(newEndDate).subtract(diff, 'milliseconds');
    if (updateTimestampsCallback) updateTimestampsCallback(newStartDate, newEndDate);
    actions.updateTimestamps(newStartDate.toISOString(), newEndDate.toISOString());
    trackWebTimeFrameShifted();
  }

  buttonDisabled() {
    const { startDate, endDate, endDateMax, currentUserIsProvider } = this.props;
    const diff = endDate.diff(startDate, 'milliseconds');
    const endDateInRange = endDateMax.subtract(diff, 'milliseconds').isSameOrBefore(endDate);
    return (currentUserIsProvider && endDateInRange);
  }

  render() {
    const { startDate, endDate, currentPatientDueDate, hasPregnancyPackage } = this.props;
    const { formattedStartDate, formattedEndDate } = TimeUtils.formatDateForSummaryPage(startDate, endDate);

    return (
      <div class={Style.TimeFrameArrowSelector}>
        <div class={Style.TimeFrameButtons}>
          <button
            className={classNames({
              [Style.DateRangePicker__button]: true,
              [Style.DateRangePicker__button__caret_left]: true,
            })}
            data-testid="updateTimeStamps"
            onClick={() => this.updateTimestamps(-1)}
          />
          <button
            disabled={this.buttonDisabled()}
            className={classNames({
              [Style.DateRangePicker__button]: true,
              [Style.DateRangePicker__button__caret_right]: true,
            })}
            onClick={() => this.updateTimestamps(1)}
          />
        </div>
        <div class={Style.TimeFrameDates}>
          <div class={Style.TimeFrameDatesRow}>
            <div class={Style.dateRangeInline}>{formattedStartDate}</div>
            {formattedEndDate &&
              <div class={Style.dateRangeInline}>
                <div class={Style.dateRangeInline}>&nbsp;-&nbsp;</div>
                <div class={Style.dateRangeInline}>{formattedEndDate}</div>
              </div>}
          </div>
          <div class={Style.TimeFrameDatesRow}>
            <OverviewBreadcrumbs />
          </div>
        </div>
        <div className={Style.pregnancyBadgeContainer}>
          <PregnancyBadge
            startDate={startDate.toISOString()}
            endDate={endDate.toISOString()}
            patientDueDate={currentPatientDueDate}
            hasPregnancyPackage={hasPregnancyPackage}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeFrameArrowSelector);
