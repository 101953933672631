import { useTranslation } from 'react-i18next';
import useHumanize from 'hooks/useHumanize';
import { TIME_FORMATS } from 'utils/i18nFormats';
import classNames from 'classnames';
import TimeUtils from 'utils/TimeUtils/TimeUtils';
import Style from '../Tooltip.scss';
import ExerciseTooltipData from './ExerciseTooltipData/ExerciseTooltipData';

export interface ExerciseTooltipProps {
  point: {
    type: string;
    y: number;
    sourceName: string;
    timestamp: string;
    duration: number;
    distance?: number;
    calories?: number;
  }
  unit: string;
}

const ExerciseTooltip = (props: ExerciseTooltipProps) => {
  const { t } = useTranslation('ExerciseTooltip');
  const { point, unit } = props;
  const { humanizeDuration, humanizeDistance } = useHumanize();

  const distance = point.distance ? humanizeDistance(point.distance, unit) : undefined;
  const calories = point.calories ? Math.round(point.calories) : undefined;
  const date = TimeUtils.formatDate(point.timestamp, TIME_FORMATS.MMM_DD_YYYY);

  return (
    <section className={Style.Tooltip}>
      <header className={classNames(Style.header, Style.row)}>{date}</header>

      <div className={Style.row}>
        <div className={Style.title}>
          <span>{point.sourceName}</span>
          <span className={Style.date}>{TimeUtils.formatDate(point.timestamp, TIME_FORMATS.HH_MM)}</span>
        </div>

        <ExerciseTooltipData label={t('exerciseType')} value={point.type} />
        <ExerciseTooltipData label={t('duration')} value={humanizeDuration(point.duration)} />
        <ExerciseTooltipData label={t('distance')} value={distance} />
        <ExerciseTooltipData label={t('calories')} value={calories} />
      </div>
    </section>
  );
};

export default ExerciseTooltip;
