import { PATH_POP_INSIGHTS_API_URL } from
  '~/bundles/shared/constants/providerGroupSite';
import ExecuteApiCall, {
  ExecuteUnchangedApiCall,
  ExecuteAfterCancelPrevApiCall,
} from './apiClient';

export function fetchProviderGroupSite(payload) {
  return ExecuteApiCall('get', 'api/v3/session/primary_site', payload);
}

export function fetchPoptrackerFilters(payload) {
  return ExecuteApiCall('get', 'api/v3/population_tracker/search_filters', payload);
}

export function updateProviderGroupSiteSetting(payload) {
  return ExecuteApiCall('post', 'api/v3/session/primary_site', payload);
}

export function fetchProviderPatients(params) {
  return ExecuteUnchangedApiCall('get', 'api/v3/population_tracker/patients', {
    includeDeactivated: params.includeDeactivated,
    duplicates: params.duplicates,
    page: params.page,
    searchBy: params.searchBy,
    poptrackerSearchQuery: params.poptrackerSearchQuery,
    sortBy: params.sortBy,
    direction: params.direction,
    tags: params.tags,
    customTags: params.customTags,
    careProgramTags: params.careProgramTags,
    flags: params.flags,
    statuses: params.statuses,
    patientStatuses: params.patientStatuses,
  });
}

export function fetchPatients(payload) {
  return ExecuteUnchangedApiCall('get', 'api/v2/provider_group_site/users/search', payload);
}

export function fetchSuggestedResults() {
  return ExecuteUnchangedApiCall('get', 'api/v3/population_tracker/suggested_results');
}

export function fetchSearchResults(payload) {
  return ExecuteAfterCancelPrevApiCall('get', 'api/v3/population_tracker/search', payload);
}

export function fetchProfessionalTags() {
  return ExecuteApiCall('get', 'api/v3/population_tracker/professional/tags_and_statuses');
}

export function fetchPatientTags(patientId) {
  return ExecuteApiCall('get', 'api/v3/population_tracker/tags', { patient: patientId });
}

export function saveProviderTags(params) {
  return ExecuteApiCall('post', 'api/v3/population_tracker/tags/add_provider_tags', {
    patient: params.patient,
    providerIds: params.providerIds,
  });
}

export function deleteProviderTags(id, params) {
  return ExecuteApiCall('delete', `api/v3/population_tracker/tags/remove_provider_tags/${id}`, {
    providerIds: params.providerIds,
  });
}

export function saveCustomTags(params) {
  return ExecuteApiCall('post', 'api/v3/population_tracker/tags/add_custom_tags', {
    patient: params.patient,
    customTexts: params.customTexts,
  });
}

export function deleteCustomTags(id, params) {
  return ExecuteApiCall('delete', `api/v3/population_tracker/tags/remove_custom_tags/${id}`, {
    customTexts: params.customTexts,
  });
}

export function clearPopInsightsFilters() {
  return ExecuteApiCall('put', 'api/v3/population_tracker/filter/clear');
}

export function fetchPatientCarePrograms(payload) {
  return ExecuteApiCall('get', 'api/v3/provider_group_site/care_programs', payload);
}

export function fetchCurrentPatientCarePrograms(payload) {
  return ExecuteApiCall('get', 'api/v3/users/care_programs', payload);
}

export function fetchPatientsFlags(payload) {
  return ExecuteUnchangedApiCall('post', 'api/v1/at_risk/flags/get_user_tags', payload, PATH_POP_INSIGHTS_API_URL);
}

export function updateProviderGroupSite(pgsId) {
  return ExecuteApiCall('put', 'api/v3/provider_group_site/multi_proconnect', {
    pgsId: pgsId,
  });
}

export function createPatient(payload) {
  return ExecuteApiCall('post', 'api/v2/icpa/users', payload);
}

export function validateMrns(users) {
  return ExecuteApiCall('post', 'api/v2/population_tracker/validate_mrns', { users });
}
