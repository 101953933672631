import {
  FETCH_STATUS_FAILED,
  FETCH_STATUS_IN_PROGRESS,
} from '~/bundles/shared/constants/graphs';
import {
  updateDevices,
  updateDevicesStatus,
  updateDeviceTimeOffsets,
  updateDeviceTimeOffsetsFailed,
} from '~/redux/modules/deviceSettings/devices';
import {
  updateDevicesAndSettings,
  updateDevicesAndSettingsStatus,
} from '~/redux/modules/devicesAndSettings/devicesAndSettings';
import {
  updatePumpSettings,
  updatePumpSettingsStatus,
} from '~/redux/modules/deviceSettings/pumpSettings';
import throwIfStatusCodeNotExpected from '~/utils/throwIfStatusCodeNotExpected';
import {
  fetchDevicesAndSettings,
  fetchDevices,
  fetchPumpSettings,
  fetchMeterTimeOffsets,
} from '~/services/deviceSettingsApi';

export function fetchDevicesAndSettingsThunk() {
  return (dispatch) => {
    dispatch(updateDevicesAndSettingsStatus(FETCH_STATUS_IN_PROGRESS));

    return fetchDevicesAndSettings()
      .then((response) => response.data)
      .then(({ devices, deviceSettings }) => {
        dispatch(updateDevicesAndSettings({ devices, deviceSettings }));
      })
      .catch(() => {
        dispatch(updateDevicesAndSettingsStatus(FETCH_STATUS_FAILED));
      });
  };
}

export function fetchDevicesThunk(params) {
  return (dispatch) => {
    dispatch(updateDevicesStatus(FETCH_STATUS_IN_PROGRESS));

    return fetchDevices(params)
      .then((response) => response.data)
      .then((devices) => {
        dispatch(updateDevices(devices, params));
      })
      .catch(() => dispatch(updateDevicesStatus(FETCH_STATUS_FAILED)));
  };
}

export function fetchPumpSettingsThunk(params) {
  return (dispatch) => {
    dispatch(updatePumpSettingsStatus(FETCH_STATUS_IN_PROGRESS));

    return fetchPumpSettings(params)
      .then((response) => response.data)
      .then((pumpSettings) => {
        dispatch(updatePumpSettings(pumpSettings, params));
      })
      .catch(() => dispatch(updatePumpSettingsStatus(FETCH_STATUS_FAILED)));
  };
}

export function fetchDeviceTimeOffsetsThunk(params) {
  return (dispatch) => fetchMeterTimeOffsets(params)
    .then(throwIfStatusCodeNotExpected(200))
    .then((response) => response.data)
    .then((data) => {
      dispatch(updateDeviceTimeOffsets(data));
    })
    .catch(() => {
      dispatch(updateDeviceTimeOffsetsFailed());
    });
}
