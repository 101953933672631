import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import { connect } from 'react-redux';
import { ONE_DAY } from '~/bundles/shared/constants/time';
import PageHelper from '~/redux/modules/page/PageHelper';
import Tooltips from '~/bundles/shared/components/graphs/tooltips.jsx';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';
import UserHelper from '~/redux/modules/users/UserHelper';
import Style from './BolusGraph.scss';
import { useBolusGraph } from '../../../hooks/useBolusGraph';

const mapStateToProps = (state) => ({
  hours: PageHelper.currentTimeFrameLength(state) === ONE_DAY,
  insulinOnly: !UserHelper.hasPump(state),
  inPdf: state.pdf.inPDF,
});

export const BolusGraph = (props) => {
  const { yAxisMax, configOverride, hours, insulinOnly, inPdf, ...otherProps } = props;
  const { modifySeries, updateStrokeStyleForClippedItems, multipleYAxis } = useBolusGraph();

  useEffect(() => {
    updateStrokeStyleForClippedItems();
  });

  const tooltipFormatter = hours ? Tooltips.bolusTooltip : null;
  const yAxis = yAxisMax <= 2 ? { yAxis: { max: yAxisMax } } : multipleYAxis(yAxisMax);

  return (
    <div class={Style.BolusGraph}>
      <GraphPresenter
        tooltipPositioner={Tooltips.tooltipHoursPositioner}
        tooltipFormatter={tooltipFormatter}
        {...otherProps}
        configOverride={deepMerge(yAxis, configOverride)}
        modifySeries={modifySeries(yAxisMax, inPdf)}
      />
    </div>
  );
};

export default connect(mapStateToProps, null)(BolusGraph);

BolusGraph.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  yAxisMax: PropTypes.number.isRequired,
  configOverride: PropTypes.shape({}).isRequired,
  hours: PropTypes.bool.isRequired,
  insulinOnly: PropTypes.bool.isRequired,
  inPdf: PropTypes.bool.isRequired,
};

BolusGraph.defaultProps = {
  yAxisMax: 10,
  configOverride: {},
  hours: false,
  insulinOnly: false,
  inPdf: false,
};
