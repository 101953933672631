import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { DobPicker, Select } from '@glooko/common-ui';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import {
  displayOrderOfMedicalInfoFields,
  displayOrderOfCreateAccountFields,
  displayOrderOfInviteFields,
} from '~/utils/ICPAConfig';
import { truncateText } from '~/utils/textHelper.js';
import UserHelper from '~/redux/modules/users/UserHelper';
import Style from './CreateNewPatientModal.scss';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';

const mapStateToProps = (state) => ({
  euResident: UserHelper.currentUser(state).euResident,
  country: state.providerGroupSite.countryOfJurisdiction,
  authCode: state.providerGroupSite.authCode,
  icpaConfig: state.providerGroupSite.icpaConfig,
  mrnLabel: state.providerGroupSite.customMrnLabel,
  mrnPlaceholder: state.providerGroupSite.customMrnExamples,
});

const todOptions = (t) => {
  const options = [
    { value: 'type_1', label: t('typeOne') },
    { value: 'type_2', label: t('typeTwo') },
    { value: 'none', label: t('none') },
    { value: 'gestational', label: t('gestational') },
    { value: null, label: t('notSpecified') },
    { value: 'pre_diabetes', label: t('preDiabetes') },
    { value: 'lada', label: t('lada') },
    { value: 'other', label: t('other') },
  ];
  return options;
};

const genderOptions = (t) => {
  const options = [
    { value: null, label: t('notSelected') },
    { value: 'male', label: t('male') },
    { value: 'female', label: t('female') },
  ];
  return options;
};

const optOutEmailOptions = (t) => [
  { label: t('patientWillNotProvideEmail'), value: 'patient_is_not_interested_in_joining_glooko' },
  { label: t('patientDoesNotHaveEmail'), value: 'patient_does_not_have_an_email_address_to_provide' },
  { label: t('patientUnavailableEmail'), value: 'patient_email_unavailable_at_this_time,_this_will_be_added_later' },
];

const optOutPhoneOptions = (t) => [
  { label: t('patientWillNotProvidePhone'), value: 'patient_is_not_interested_in_being_invited_to_glooko_via_phone_number' },
  { label: t('patientDoesNotHavePhone'), value: 'patient_does_not_have_a_valid_phone_number_to_provide' },
  { label: t('patientUnavailablePhone'), value: 'patient_phone_number_unavailable_at_this_time,_this_will_be_added_later' },
];

class CreateNewPatientModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    isEmailEditable: PropTypes.bool.isRequired,
    visibilityCallback: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    returnPatientData: PropTypes.func.isRequired,
    country: PropTypes.string,
    authCode: PropTypes.string.isRequired,
    tod: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    reasonForEmailOptOut: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    reasonForPhoneOptOut: PropTypes.string.isRequired,
    t1dExchangeIdentifier: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    gender: PropTypes.string,
    createFormInvalid: PropTypes.bool.isRequired,
    mrn: PropTypes.string.isRequired,
    mrnLabel: PropTypes.string,
    mrnPlaceholder: PropTypes.string,
    patientView: PropTypes.bool,
    emailExisted: PropTypes.bool,
    mrnExisted: PropTypes.bool,
    mrnFormatValidationFailed: PropTypes.bool,
    participantIdExisted: PropTypes.bool,
    t1dExchangeIdentifierExisted: PropTypes.bool,
    optOutEmailInfo: PropTypes.bool,
    optOutPhoneInfo: PropTypes.bool,
    participantId: PropTypes.string,
    pgsCarePrograms: PropTypes.array.isRequired,
    euResident: PropTypes.bool,
    invalidFields: PropTypes.shape({
      email: PropTypes.bool.isRequired,
      lastName: PropTypes.bool.isRequired,
      firstName: PropTypes.bool.isRequired,
      phone: PropTypes.bool.isRequired,
      tod: PropTypes.bool.isRequired,
      dob: PropTypes.bool.isRequired,
      mrn: PropTypes.bool.isRequired,
      consent: PropTypes.bool.isRequired,
      participantId: PropTypes.bool.isRequired,
      t1dExchangeIdentifier: PropTypes.bool.isRequired,
      zipCode: PropTypes.bool.isRequired,
      gender: PropTypes.bool.isRequired,
      reasonForEmailOptOut: PropTypes.bool.isRequired,
      reasonForPhoneOptOut: PropTypes.bool.isRequired,
    }).isRequired,
    invalidFieldsMessages: PropTypes.shape({
      email: PropTypes.string,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      phone: PropTypes.string,
      tod: PropTypes.string,
      dob: PropTypes.string,
      mrn: PropTypes.string,
      consent: PropTypes.string,
      participantId: PropTypes.string,
      t1dExchangeIdentifier: PropTypes.string,
      zipCode: PropTypes.string,
      gender: PropTypes.string,
    }),
    icpaConfig: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string.isRequired,
      participantId: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      medicalRecordNumber: PropTypes.string,
      phoneNumber: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      typeOfDiabetes: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
      t1dExchangeIdentifier: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    formData: {
      firstName: '',
      lastName: '',
    },
    country: '',
    patientView: false,
    emailExisted: false,
    mrnExisted: false,
    mrnFormatValidationFailed: false,
    euResident: false,
    participantId: '',
    participantIdExisted: false,
    t1dExchangeIdentifierExisted: false,
    gender: null,
    reasonForEmailOptOut: '',
    reasonForPhoneOptOut: '',
    invalidFieldsMessages: {},
  };

  constructor(props) {
    super(props);
    const genderValue = this.getGender(this.props.gender) || '';
    const optOutEmailValue = this.getOptOutEmail(this.props.reasonForEmailOptOut) || '';
    const optOutPhoneValue = this.getOptOutPhone(this.props.reasonForPhoneOptOut) || '';
    this.state = {
      tod: this.getTod(this.props.tod) || '',
      consent: false,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      dob: this.props.dateOfBirth,
      mrn: this.props.mrn,
      mrnLabel: this.props.mrnLabel,
      mrnPlaceholder: this.props.mrnPlaceholder,
      participantId: this.props.participantId,
      email: this.props.email,
      phone: this.validateEmptyPhoneNumber(this.props.phone),
      t1dExchangeIdentifier: this.props.t1dExchangeIdentifier,
      zipCode: this.props.zipCode,
      gender: genderValue,
      invalidFields: this.props.invalidFields,
      emptySelection: { tod: false, gender: false },
      createFormInvalid: false,
      optOutEmailInfo: !isEmpty(this.props.reasonForEmailOptOut),
      optOutPhoneInfo: !isEmpty(this.props.reasonForPhoneOptOut),
      reasonForEmailOptOut: optOutEmailValue,
      reasonForPhoneOptOut: optOutPhoneValue,
      careProgram: '',
    };
  }

  componentDidMount() {
    this.returnFormDataObject();
  }

  componentDidUpdate(prevProps) {
    const { createFormInvalid, invalidFields } = this.props;
    if (createFormInvalid !== prevProps.createFormInvalid) {
      this.setFormInvalidState();
    }
    if (invalidFields !== prevProps.invalidFields) {
      this.setInvalidFields();
    }
    this.returnFormDataObject();
  }

  validateEmptyPhoneNumber = (phone) => {
    const result = phone && phone.trim().length === 0 ? phone.trim() : phone;
    return result;
  }

  getTod = (tod) => todOptions(this.props.t).filter((elem) => elem.value === tod)[0];

  getGender = (gender) => genderOptions(this.props.t).filter((elem) => elem.value === gender)[0];

  getOptOutEmail = (reasonForEmailOptOut) => optOutEmailOptions(this.props.t).filter((elem) => elem.value === reasonForEmailOptOut)[0];

  getOptOutPhone = (reasonForPhoneOptOut) => optOutPhoneOptions(this.props.t).filter((elem) => elem.value === reasonForPhoneOptOut)[0];

  setFormInvalidState = () => {
    const { createFormInvalid } = this.props;
    if (createFormInvalid) {
      this.setState({ createFormInvalid: true });
    }
  }

  setInvalidFields = () => {
    this.setState({
      invalidFields: this.props.invalidFields,
    });
  }

  validateFakeEmail(email) {
    return /fake$/i.test(email) ? '' : email;
  }

  addInvalidClassOnEmptyInput = (input, invalidClass) => {
    input.target.parentElement.classList.add(invalidClass);
    input.target.classList.add(invalidClass);
  }

  removeInvalidClassOnInput = (input, invalidClass) => {
    input.target.parentElement.classList.remove(invalidClass);
    input.target.classList.remove(invalidClass);
  }

  validateOnEmptyInput = (input) => {
    const invalidClass = Style.notValid;
    const hasInputValue = input.target.value.trim();
    if (!hasInputValue) {
      this.addInvalidClassOnEmptyInput(input, invalidClass);
      return;
    }
    this.removeInvalidClassOnInput(input, invalidClass);
  }

  validateEmptySelection = (selectRef, t) => {
    const noOptionSelect = selectRef.props.placeholder === t('select');
    const inputName = selectRef.props.name;
    if (noOptionSelect) {
      this.setState((prevState) => ({
        emptySelection: { ...prevState.emptySelection, [inputName]: true },
      }));
    } else {
      this.setState((prevState) => ({
        emptySelection: { ...prevState.emptySelection, [inputName]: false },
      }));
    }
  }

  handleOnKeyUp = (input) => {
    const { value } = input.target;
    const hasInputLengthGteOne = value.trim() && value.length >= 1;
    if (hasInputLengthGteOne) { this.removeInvalidClassOnInput(input, Style.notValid); }
  }

  visibilityCallback = () => {
    this.props.visibilityCallback(false);
  }

  dobDatepickerData = (dob) => {
    this.setState({
      dob,
      invalidFields: {
        ...this.state.invalidFields,
        dob: moment.utc(dob).format() === 'Invalid date',
      },
    });
  }

  updateFormData = (e) => {
    const item = e.target;
    this.setState({
      ...this.state,
      [item.name]: item.value,
    });
  }

  fieldOnFocus = (e) => {
    const item = e.target;
    this.setState({
      invalidFields: {
        ...this.state.invalidFields,
        [item.name]: false,
      },
    });
  }

  consentVisible = () => {
    const { country } = this.props;
    return country === 'can';
  }

  returnFormDataObject() {
    const {
      firstName,
      lastName,
      dob,
      mrn,
      email,
      tod,
      phone,
      consent,
      participantId,
      t1dExchangeIdentifier,
      zipCode,
      gender,
      optOutEmailInfo,
      reasonForEmailOptOut,
      optOutPhoneInfo,
      reasonForPhoneOptOut,
      careProgram,
    } = this.state;
    const formData = {
      firstName,
      lastName,
      dob,
      mrn,
      email,
      tod,
      phone,
      consent,
      participantId,
      t1dExchangeIdentifier,
      zipCode,
      gender,
      optOutEmailInfo,
      reasonForEmailOptOut: reasonForEmailOptOut.value,
      optOutPhoneInfo,
      reasonForPhoneOptOut: reasonForPhoneOptOut.value,
      careProgram: careProgram.value,
    };
    this.props.returnPatientData(formData);
  }

  handleTodSelect = (option) => {
    this.setState({
      tod: option,
      invalidFields: {
        ...this.state.invalidFields,
        tod: false,
      },
      emptySelection: { ...this.state.emptySelection, tod: false },
    });
  }

  handleGenderSelect = (option) => {
    this.setState({
      gender: option,
      invalidFields: {
        ...this.state.invalidFields,
        gender: false,
      },
      emptySelection: { ...this.state.emptySelection, gender: false },
    });
  }

  handleCareProgramSelect = (option) => {
    this.setState({
      careProgram: option,
    });
  }

  handleConsent = () => {
    this.setState({
      ...this.state,
      consent: !this.state.consent,
      invalidFields: {
        ...this.state.invalidFields,
        consent: false,
      },
    });
  }

  handleOptOutEmailReason = (option) => {
    this.setState({
      reasonForEmailOptOut: option,
      invalidFields: {
        ...this.state.invalidFields,
        reasonForEmailOptOut: false,
      },
    });
  }

  handleOptOutPhoneReason = (option) => {
    this.setState({
      reasonForPhoneOptOut: option,
      invalidFields: {
        ...this.state.invalidFields,
        reasonForPhoneOptOut: false,
      },
    });
  }

  checkInputIsRequired(input) {
    return this.props.icpaConfig[input] === 'required';
  }

  returnOptionalText(field) {
    const { icpaConfig } = this.props;
    return icpaConfig[field] === 'optional' ? '' : '*';
  }

  renderDatePicker(dob) {
    const { t } = this.props;

    const dobPlaceholders = { day: t('day'), month: t('month'), year: t('year') };

    return (
      <DobPicker
        name="dob"
        initialDate={this.props.dateOfBirth ? moment.utc(this.props.dateOfBirth).format('YYYY-MM-DD') : undefined}
        onChange={this.dobDatepickerData}
        placeholders={dobPlaceholders}
        locale={i18next.language}
      />
    );
  }

  renderFirstNameField(firstNameInvalid, t) {
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: firstNameInvalid,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="firstName">
          {this.returnOptionalText('firstName')} {t('firstName')}
        </label>
        <input
          class={classNames({
            [Style.inputField]: true,
            [Style.notValid]: firstNameInvalid,
          })}
          name="firstName"
          placeholder={t('patientFirstName')}
          onChange={this.updateFormData}
          value={this.state.firstName}
          onFocus={this.fieldOnFocus}
          onBlur={this.checkInputIsRequired('firstName') ? this.validateOnEmptyInput : undefined}
          onKeyUp={this.checkInputIsRequired('firstName') ? this.handleOnKeyUp : undefined}
        />
      </div>
    );
  }

  renderLastNameField(lastNameInvalid, t) {
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: lastNameInvalid,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="lastName">
          {this.returnOptionalText('lastName')} {t('lastName')}
        </label>
        <input
          class={classNames({
            [Style.inputField]: true,
            [Style.notValid]: lastNameInvalid,
          })}
          name="lastName"
          placeholder={t('patientLastName')}
          onChange={this.updateFormData}
          value={this.state.lastName}
          onFocus={this.fieldOnFocus}
          onBlur={this.checkInputIsRequired('lastName') ? this.validateOnEmptyInput : undefined}
          onKeyUp={this.checkInputIsRequired('lastName') ? this.handleOnKeyUp : undefined}
        />
      </div>
    );
  }

  renderParticipantIdField(participantIdInvalid, t) {
    const { participantIdExisted, authCode } = this.props;
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: participantIdInvalid,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="participantId">
          {this.returnOptionalText('participantId')} {t('participantId')}
        </label>
        <input
          name="participantId"
          class={classNames({
            [Style.inputField]: true,
            [Style.notValid]: participantIdInvalid,
          })}
          onChange={this.updateFormData}
          value={this.state.participantId}
          onFocus={this.fieldOnFocus}
          onBlur={this.checkInputIsRequired('participantId') ? this.validateOnEmptyInput : undefined}
          onKeyUp={this.checkInputIsRequired('participantId') ? this.handleOnKeyUp : undefined}
        />
        <p class={Style.errorMessage} hidden={!participantIdExisted}>
          {t('participantIdExistedMessage', { proconnectCode: authCode })}
        </p>
      </div>
    );
  }

  renderBirthDateField(dobInvalid, t) {
    const { dob } = this.state;
    const { invalidFieldsMessages } = this.props;
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: dobInvalid,
        })}
        style={{ marginBottom: '30px' }}
      >
        <label class={Style.fieldLabelTitle} htmlFor="birthdate">
          {this.returnOptionalText('dateOfBirth')} {t('birthdate')}
        </label>
        {this.renderDatePicker(dob)}
        {dobInvalid && invalidFieldsMessages && invalidFieldsMessages.dob &&
          <div class={Style.fieldErrorMessage}>
            {invalidFieldsMessages.dob}
          </div>}
      </div>
    );
  }

  optOutEmailChange = (event) => {
    this.setState({
      optOutEmailInfo: event.target.checked,
      email: '',
      reasonForEmailOptOut: '',
    });
  }

  optOutPhoneChange = (event) => {
    this.setState({
      optOutPhoneInfo: event.target.checked,
      phone: '',
      reasonForPhoneOptOut: '',
    });
  }

  renderEmailField(emailInvalid, t) {
    const { authCode, emailExisted, isEmailEditable } = this.props;
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: emailInvalid,
          [Style.disabled]: this.state.optOutEmailInfo,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="email">
          {this.returnOptionalText('email')} {t('email')}
        </label>
        <input
          name="email"
          class={classNames({
            [Style.inputField]: true,
            [Style.notValid]: emailInvalid && !this.state.optOutEmailInfo,
            [Style.disabled]: this.state.optOutEmailInfo,
          })}
          placeholder="email@example.com"
          onChange={this.updateFormData}
          value={this.validateFakeEmail(this.state.email)}
          onFocus={this.fieldOnFocus}
          onBlur={(this.checkInputIsRequired('email') && !this.state.optOutEmailInfo) ? this.validateOnEmptyInput : undefined}
          onKeyUp={(this.checkInputIsRequired('email') && !this.state.optOutEmailInfo) ? this.handleOnKeyUp : undefined}
          disabled={isEmailEditable || this.state.optOutEmailInfo}
        />
        <p class={Style.errorMessage} hidden={!emailExisted}>
          {t('emailExistedMessage', { proconnectCode: authCode })}
        </p>
      </div>
    );
  }

  renderOptOutInfo(t, visible, tag, value) {
    const isEmail = tag === 'Email';
    const optOutChange = isEmail ? this.optOutEmailChange : this.optOutPhoneChange;
    const disabledOptOut = isEmail && this.props.isEmailEditable;
    const labelText = isEmail ? t('optOutEmailInfo') : t('optOutPhoneInfo');
    return (
      <div
        class={Style.checkOptOutContainer}
        hidden={!visible}
        key={`optOutInfoContainer_${tag}`}
      >
        <input
          disabled={disabledOptOut}
          name={`optOut${tag}Info`}
          checked={value}
          type="checkbox"
          id={`checkbox_optOut_${tag}`}
          onChange={optOutChange}
        />
        <label
          class={classNames({
            [Style.checkboxLabel]: true,
            [Style.disabled]: disabledOptOut,
          })}
          htmlFor={`checkbox_optOut_${tag}`}
        >
          {labelText}
        </label>
      </div>
    );
  }

  renderReasonForInfo(reasonForInfoInvalid, t, visible, tag) {
    const { reasonForEmailOptOut, reasonForPhoneOptOut } = this.state;
    const isEmail = tag === 'Email';
    const value = isEmail ? reasonForEmailOptOut : reasonForPhoneOptOut;
    const onChangeReason = isEmail ? this.handleOptOutEmailReason : this.handleOptOutPhoneReason;
    return (
      <div hidden={!visible} key={`reasonForOptOutContainer_${tag}`} style={{ marginBottom: 20 }}>
      * <div class={classNames({
            [Style.detailedDescription]: true,
            [Style.detailedDescriptionTitle]: true,
        })}
      >
          {isEmail ? t('selectReasonEmailTitle') : t('selectReasonPhoneTitle')}
        </div>
        <div
          class={classNames({
            [Style.detailedDescription]: true,
            [Style.lightDetailed]: true,
          })}
        >
          {t('selectReasonDescription')}
        </div>
        <div data-testid={`reason-for-${tag.toLowerCase()}-opt-out`}>
          <Select
            isDisabled={isEmail && this.props.isEmailEditable}
            name={`reasonFor${tag}OptOut`}
            size='large'
            defaultValue={value}
            options={isEmail ? optOutEmailOptions(t) : optOutPhoneOptions(t)}
            onChange={onChangeReason}
            placeholder={(isEmail ? reasonForEmailOptOut.label : reasonForPhoneOptOut.label) || t('select')}
            menuShouldScrollIntoView={false}
          />
        </div>
      </div>
    );
  }

  renderMrnField(mrnInvalid, t) {
    const { mrnLabel } = this.props;
    const { mrnPlaceholder } = this.props;
    const { mrnExisted } = this.props;
    const { mrnFormatValidationFailed } = this.props;
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: mrnInvalid,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="mrn">
          {this.returnOptionalText('medicalRecordNumber')} {truncateText(mrnLabel, 10) || t('mrn')}
        </label>
        <input
          class={classNames({
            [Style.inputField]: true,
            [Style.notValid]: mrnInvalid,
            [Style.truncateWithEllipsis]: true,
          })}
          name="mrn"
          onChange={this.updateFormData}
          value={this.state.mrn}
          placeholder={mrnPlaceholder}
          onFocus={this.fieldOnFocus}
          onBlur={this.checkInputIsRequired('medicalRecordNumber') ? this.validateOnEmptyInput : undefined}
          onKeyUp={this.checkInputIsRequired('medicalRecordNumber') ? this.handleOnKeyUp : undefined}
        />
        <p className={Style.errorMessage} hidden={!mrnExisted}>
          {t('mrnExistedMessage')}
        </p>
        <p className={Style.errorMessage} hidden={!mrnFormatValidationFailed}>
          {t('mrnInvalidMessage')}
        </p>
      </div>
    );
  }

  renderT1dExchangeIdentifierField(t1dExchangeIdentifierInvalid, t) {
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: t1dExchangeIdentifierInvalid,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="t1dExchangeIdentifier">
          {this.returnOptionalText('t1dExchangeIdentifier')} {t('t1dExchangeIdentifier')}
        </label>
        <input
          class={classNames({
            [Style.inputField]: true,
            [Style.notValid]: t1dExchangeIdentifierInvalid,
          })}
          name="t1dExchangeIdentifier"
          onChange={this.updateFormData}
          value={this.state.t1dExchangeIdentifier}
          onFocus={this.fieldOnFocus}
          onBlur={this.checkInputIsRequired('t1dExchangeIdentifier') ? this.validateOnEmptyInput : undefined}
          onKeyUp={this.checkInputIsRequired('t1dExchangeIdentifier') ? this.handleOnKeyUp : undefined}
        />
        <p class={Style.errorMessage} hidden={!this.props.t1dExchangeIdentifierExisted}>
          {t('t1dExchangeIdentifierExisted')}
        </p>
      </div>
    );
  }

  renderPhoneNumberField(phoneInvalid, t) {
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: phoneInvalid,
          [Style.disabled]: this.state.optOutPhoneInfo,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="phone">
          {this.returnOptionalText('phoneNumber')} {t('phone')}
        </label>
        <input
          name="phone"
          class={classNames({
            [Style.inputField]: true,
            [Style.notValid]: phoneInvalid && !this.state.optOutPhoneInfo,
            [Style.disabled]: this.state.optOutPhoneInfo,
          })}
          value={this.state.phone}
          placeholder={t('phoneExample')}
          onChange={this.updateFormData}
          onFocus={this.fieldOnFocus}
          onBlur={(this.checkInputIsRequired('phoneNumber') && !this.state.optOutPhoneInfo) ? this.validateOnEmptyInput : undefined}
          onKeyUp={(this.checkInputIsRequired('phoneNumber') && !this.state.optOutPhoneInfo) ? this.handleOnKeyUp : undefined}
          disabled={this.state.optOutPhoneInfo}

        />
      </div>
    );
  }

  renderTodField(todInvalid, t) {
    const { emptySelection } = this.state;
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: todInvalid || emptySelection.tod,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="tod">
          {this.returnOptionalText('typeOfDiabetes')} {t('tod')}
        </label>
        <Select
          name="tod"
          onChange={this.handleTodSelect}
          options={todOptions(t)}
          placeholder={this.state.tod.label || t('select')}
          size='large'
          onFocus={this.fieldOnFocus}
          onBlur={
            this.checkInputIsRequired('typeOfDiabetes') ?
              () => this.validateEmptySelection(this.todRef, t) :
              undefined
            }
          menuShouldScrollIntoView={false}
        />
      </div>
    );
  }

  renderZipCodeField(zipCodeInvalid, t) {
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: zipCodeInvalid,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="zipCode">
          {this.returnOptionalText('zipCode')} {t('zipCode')}
        </label>
        <input
          class={classNames({
            [Style.inputField]: true,
            [Style.notValid]: zipCodeInvalid,
          })}
          name="zipCode"
          onChange={this.updateFormData}
          value={this.state.zipCode}
          onFocus={this.fieldOnFocus}
          onBlur={this.checkInputIsRequired('zipCode') ? this.validateOnEmptyInput : undefined}
          onKeyUp={this.checkInputIsRequired('zipCode') ? this.handleOnKeyUp : undefined}
        />
      </div>
    );
  }

  renderGenderField(genderInvalid, t) {
    const { gender } = this.props.icpaConfig;
    const { emptySelection } = this.state;
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
          [Style.notValid]: genderInvalid || emptySelection.gender,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="gender">
          {this.returnOptionalText('gender')} {t('gender')}
        </label>
        <Select
          name='gender'
          onChange={this.handleGenderSelect}
          options={genderOptions(t)}
          placeholder={this.state.gender.label || t('select')}
          size='large'
          onFocus={this.fieldOnFocus}
          onBlur={
            gender === 'required' ?
              () => this.validateEmptySelection(this.genderRef, t) :
              undefined
          }
          menuShouldScrollIntoView={false}
        />
      </div>
    );
  }

  renderCareProgramField(t, options) {
    const carePrograms = options.map((option) => ({
      label: option.nameUiDisplay,
      value: option.id,
    }));
    const careProgramOptions = [
      {
        label: t('none'),
        value: null,
      },
      ...carePrograms,
    ];
    return (
      <div
        class={classNames({
          [Style.fieldContainer]: true,
        })}
      >
        <label class={Style.fieldLabelTitle} htmlFor="careProgram">
          {t('careProgram')}
        </label>
        <Select
          name='careProgram'
          options={careProgramOptions}
          isSearchable
          size='large'
          onChange={this.handleCareProgramSelect}
          onFocus={this.fieldOnFocus}
          placeholder={this.state.careProgram.value === null ? t('select') : this.state.careProgram.label || t('select')}
          menuShouldScrollIntoView={false}
        />
      </div>
    );
  }

  getIcpaConfigFields(displayOrderOfFields, inputElements) {
    const {
      icpaConfig,
      pgsCarePrograms,
    } = this.props;
    const requiredFields = [];
    const optionalFields = [];
    displayOrderOfFields.forEach((i) => {
      if (icpaConfig[i] === 'required' || i === 'optOutEmailInfo' || i === 'reasonForEmailInfo' ||
        i === 'optOutPhoneInfo' ||
        i === 'reasonForPhoneInfo') {
        requiredFields.push(inputElements[i]());
      } else if (icpaConfig[i] === 'optional' || (i === 'careProgram' && !isEmpty(pgsCarePrograms))) {
        optionalFields.push(inputElements[i]());
      }
    });
    return [...requiredFields, ...optionalFields];
  }

  renderCreateAccountFields() {
    const {
      t,
      createFormInvalid,
    } = this.props;
    const {
      firstName,
      lastName,
      dob,
      tod,
      zipCode,
    } = this.state.invalidFields;
    const firstNameInvalid = createFormInvalid && firstName;
    const lastNameInvalid = createFormInvalid && lastName;
    const todInvalid = createFormInvalid && tod;
    const dobInvalid = createFormInvalid && dob;
    const zipCodeInvalid = createFormInvalid && zipCode;

    const inputElements = {
      firstName: () => this.renderFirstNameField(firstNameInvalid, t),
      lastName: () => this.renderLastNameField(lastNameInvalid, t),
      dateOfBirth: () => this.renderBirthDateField(dobInvalid, t),
      typeOfDiabetes: () => this.renderTodField(todInvalid, t),
      zipCode: () => this.renderZipCodeField(zipCodeInvalid, t),
    };

    return this.getIcpaConfigFields(displayOrderOfCreateAccountFields, inputElements);
  }

  renderInvitePatientFields() {
    const {
      t,
      emailExisted,
      createFormInvalid,
      icpaConfig,
    } = this.props;
    const {
      email,
      phone,
      reasonForEmailOptOut,
      reasonForPhoneOptOut,
    } = this.state.invalidFields;
    const emailInvalid = (createFormInvalid && email) || emailExisted;
    const phoneInvalid = createFormInvalid && phone;
    const reasonEmailInvalid = createFormInvalid && reasonForEmailOptOut;
    const reasonPhoneInvalid = createFormInvalid && reasonForPhoneOptOut;
    const inputElements = {
      email: () => this.renderEmailField(emailInvalid, t),
      optOutEmailInfo: () => this.renderOptOutInfo(
        t,
        icpaConfig['email'] === 'required',
        'Email',
        this.state.optOutEmailInfo,
      ),
      reasonForEmailInfo: () => this.renderReasonForInfo(reasonEmailInvalid, t, this.state.optOutEmailInfo, 'Email'),
      phoneNumber: () => this.renderPhoneNumberField(phoneInvalid, t),
      optOutPhoneInfo: () => this.renderOptOutInfo(
        t,
        icpaConfig['phoneNumber'] === 'required',
        'Phone',
        this.state.optOutPhoneInfo,
      ),
      reasonForPhoneInfo: () => this.renderReasonForInfo(reasonPhoneInvalid, t, this.state.optOutPhoneInfo, 'Phone'),
    };
    return this.getIcpaConfigFields(displayOrderOfInviteFields, inputElements);
  }

  renderMedicalInfoFields() {
    const {
      t,
      mrnExisted,
      mrnFormatValidationFailed,
      participantIdExisted,
      createFormInvalid,
      pgsCarePrograms,
    } = this.props;
    const {
      mrn,
      participantId,
      t1dExchangeIdentifier,
      gender,
    } = this.state.invalidFields;
    const mrnInvalid = (createFormInvalid && mrn) || mrnExisted || mrnFormatValidationFailed;
    const participantIdInvalid = (createFormInvalid && participantId) || participantIdExisted;
    const genderInvalid = createFormInvalid && gender;
    const t1dExchangeIdentifierInvalid = createFormInvalid && t1dExchangeIdentifier;

    const inputElements = {
      medicalRecordNumber: () => this.renderMrnField(mrnInvalid, t),
      participantId: () => this.renderParticipantIdField(participantIdInvalid, t),
      gender: () => this.renderGenderField(genderInvalid, t),
      careProgram: () => this.renderCareProgramField(t, pgsCarePrograms),
      t1dExchangeIdentifier: () => this.renderT1dExchangeIdentifierField(t1dExchangeIdentifierInvalid, t),
    };
    return this.getIcpaConfigFields(displayOrderOfMedicalInfoFields, inputElements);
  }

  renderSpecificToResident(t) {
    return (
      <div class={Style.consentTextContainer}>
        <div class={Style.consentText}>{t('consentFirst')}</div>
        <a
          class={Style.consentLink}
          href="https://www.glooko.com/privacy/"
          target="_blank"
        >
          {t('moreInfo')}
        </a>
        <div class={Style.consentText}>{t('consentSecond')} {t('consentPleaseContact')}</div>
        <a
          class={Style.consentLink}
          href="https://support.glooko.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          support.glooko.com
        </a>
      </div>
    );
  }

  render() {
    if (!this.props.visible) {
      return null;
    }
    const { t, createFormInvalid } = this.props;
    const { consent } = this.state.invalidFields;
    const consentInvalid = createFormInvalid && consent;
    return (
      <div>
        {!isEmpty(this.renderCreateAccountFields()) &&
          <div>
            <div class={Style.titleOfDescription}>
              {t('createAccountTitle')}
            </div>
            <div class={Style.detailedDescription}>
              {t('createDescriptionSecondary')}
            </div>
          </div>}
        <div class={Style.createNewPatientContainer} />
        {this.renderCreateAccountFields().map((elem) => (
          <div key={elem.props.children[1].props.name}>{elem}</div>
        ))}
        {!isEmpty(this.renderInvitePatientFields()) &&
          <div>
            <div class={Style.titleOfDescription}>
              {t('inviteTitle')}
            </div>
            <div class={Style.detailedDescription}>
              {t('inviteDetails')}
            </div>
          </div>}
        {this.renderInvitePatientFields().map((elem) => {
          if (elem.key === 'reasonForOptOutContainer_Email') {
            return (<div key={elem.props.children[3].props.children.props.name}>{elem}</div>);
          } else if (elem.key === 'optOutInfoContainer_Email') {
            return (<div key={elem.props.children[0].props.name} hidden={elem.props.hidden}>{elem}</div>);
          }
          if (elem.key === 'reasonForOptOutContainer_Phone') {
            return (<div key={elem.props.children[3].props.children.props.name}>{elem}</div>);
          } else if (elem.key === 'optOutInfoContainer_Phone') {
            return (<div key={elem.props.children[0].props.name} hidden={elem.props.hidden}>{elem}</div>);
          }
          return (<div key={elem.props.children[1].props.name}>{elem}</div>);
        })}
        {!isEmpty(this.renderMedicalInfoFields()) &&
          <div class={Style.titleOfDescription}>
            {t('medicalTitle')}
          </div>}
        {this.renderMedicalInfoFields().map((elem) => (
          <div key={elem.props.children[1].props.name}>{elem}</div>
        ))}
        <div class={Style.consentSection} hidden={!this.consentVisible()}>
          <div
            class={classNames({
              [Style.unchecked]: true,
              [Style.checked]: this.state.consent,
              [Style.invalidConsent]: consentInvalid,
            })}
            role="presentation"
            onClick={this.handleConsent}
          />
          {this.renderSpecificToResident(t)}
        </div>
      </div>
    );
  }
}

export const TranslatedCreateNewPatientModal = translate('CreateNewPatientModal')(CreateNewPatientModal);
export default connect(mapStateToProps)(TranslatedCreateNewPatientModal);
