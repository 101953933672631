import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deepMerge from 'deepmerge';
import Readings from '~/services/Readings';
import { TYPE_CGM } from '~/bundles/shared/constants/readings';
import Colors from '~/../assets/styles/export/colors';
import Sizes from '~/../assets/styles/export/sizes';
import PageHelper from '~/redux/modules/page/PageHelper';
import Tooltips from '~/bundles/shared/components/graphs/tooltips.jsx';
import { SECONDS_IN_DAY, ONE_DAY } from '~/bundles/shared/constants/time';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';
import UserHelper from '~/redux/modules/users/UserHelper';
import Style from './BGGraph.scss';

const mapStateToProps = (state, ownProps) => {
  const { beforeMealNormalGlucoseMax, afterMealNormalGlucoseMax, normalGlucoseMin, meterUnits } =
    UserHelper.displayUser(state).preference;
  const showBeforeMaxLine =
    (ownProps.showBeforeMaxLine === null) ?
      state.page.readingsType !== TYPE_CGM :
      ownProps.showBeforeMaxLine;

  return {
    showBeforeMaxLine,
    beforeMealMax: Readings.displayValue(
      Readings.denormalizedValue(beforeMealNormalGlucoseMax),
      meterUnits,
    ),
    afterMealMax: Readings.displayValue(
      Readings.denormalizedValue(afterMealNormalGlucoseMax),
      meterUnits,
    ),
    normalMin: Readings.displayValue(
      Readings.denormalizedValue(normalGlucoseMin),
      meterUnits,
    ),
    meterUnits,
    percentilesWidth: PageHelper.currentTimeframe(state).percentilesWidth,
    hours: PageHelper.currentTimeFrameLength(state) === ONE_DAY,
  };
};

@connect(mapStateToProps, null)
export default class BGGraph extends React.Component {
  render() {
    const {
      normalMin, afterMealMax, beforeMealMax, yAxisMax,
      showBeforeMaxLine, configOverride, meterUnits, percentilesWidth, hours,
      ...config
    } = this.props;
    const xAxisMax = SECONDS_IN_DAY;
    const tooltipFormatter = hours ? Tooltips.glucoseTooltip : Tooltips.scatterTooltip;

    return (
      <div class={Style.BGGraph}>
        <GraphPresenter
          tooltipPositioner={Tooltips.tooltipHoursPositioner}
          tooltipFormatter={tooltipFormatter}
          {...config}
          configOverride={deepMerge({
            reactProps: { xAxisMax, meterUnits },
            chart: {
              paddingTop: Sizes.bgGraphPaddingTopBottom,
              paddingBottom: Sizes.bgGraphPaddingTopBottom,
            },
            plotOptions: {
              series: {
                pointWidth: percentilesWidth,
              },
            },
            yAxis: {
              max: yAxisMax,
              plotBands: [{
                color: Colors.calendarDayBand,
                from: normalMin,
                to: afterMealMax,
                zIndex: 2,
              }],
              plotLines: [{
                color: Colors.bgAfterMax,
                width: 1,
                value: afterMealMax,
                zIndex: 2,
              }, {
                color: Colors.bgNormalMin,
                width: 1,
                value: normalMin,
                zIndex: 2,
              },
              ...((showBeforeMaxLine) ? [{
                color: Colors.bgBeforeMax,
                width: 1,
                dashStyle: 'dash',
                value: beforeMealMax,
                zIndex: 2,
              },
              ] : []
              ),
              ],
            },
          }, configOverride)}
        />
      </div>
    );
  }
}

BGGraph.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  normalMin: PropTypes.number.isRequired,
  beforeMealMax: PropTypes.number.isRequired,
  afterMealMax: PropTypes.number.isRequired,
  configOverride: PropTypes.shape({}),
  yAxisMax: PropTypes.number.isRequired,
  showBeforeMaxLine: PropTypes.bool,
  hours: PropTypes.bool.isRequired,
  meterUnits: PropTypes.number,
  percentilesWidth: PropTypes.number,
};

BGGraph.defaultProps = {
  normalMin: Readings.NORMAL_MIN,
  beforeMealMax: Readings.BEFORE_MAX,
  afterMealMax: Readings.AFTER_MAX,
  configOverride: {},
  yAxisMax: 400,
  showBeforeMaxLine: null,
  hours: false,
  meterUnits: undefined,
  percentilesWidth: undefined,
};
