import { connect } from 'react-redux';
import images from '~/utils/ImageStore';
import colors from '~/../assets/styles/export/colors';
import SystemStats from './SystemStats';
import translate from '../../../../shared/components/WithTranslate/WithTranslate';
import { hasCamapsData } from '../../../../../services/hasDeviceData';

const mapStateToProps = (state, ownProps) => {
  const data = state.statistics.overall;
  const { t } = ownProps;

  const hasData = hasCamapsData(data);

  if (!hasData) {
    return {};
  }

  return {
    systemLabel: t('systemNameCamaps'),
    activeTime: data.camapsPumpModeDurationString,
    data: [
      {
        label: t('camapsAutoOn'),
        image: images.pumpAutomaticMode,
        value: data.camapsPumpModeAutomaticPercentage,
        activeTime: data.camapsPumpModePerModeDurationStrings.automatic,
        subModes: [
          {
            label: t('camapsBoost'),
            image: images.camapsBoost,
            value: data.camapsPumpModeBoostPercentage,
            activeTime: data.camapsPumpModePerModeDurationStrings.boost,
          },
          {
            label: t('camapsEaseOff'),
            image: images.camapsEaseOff,
            value: data.camapsPumpModeEaseOffPercentage,
            activeTime: data.camapsPumpModePerModeDurationStrings.easeOff,
          },
        ],
      },
      {
        label: t('camapsAutoOff'),
        image: images.pumpManualMode,
        value: data.camapsPumpModeManualPercentage,
        activeTime: data.camapsPumpModePerModeDurationStrings.manual,
      },
      {
        label: t('camapsAutoAttempting'),
        image: images.camapsAutoAttemptingLegend,
        value: data.camapsPumpModeAttemptingPercentage,
        activeTime: data.camapsPumpModePerModeDurationStrings.attempting,
      },
    ],
    chartData: [
      {
        color: colors.systemModeGraphLineAuto,
        value: data.camapsPumpModeAutomaticPercentage,
      },
      {
        color: colors.systemModeGraphLineManual,
        value: data.camapsPumpModeManualPercentage,
      },
      {
        color: colors.pumpAttemptingModeSecondary,
        value: data.camapsPumpModeAttemptingPercentage,
      },
    ],
  };
};

export default translate('SystemStats')(connect(mapStateToProps)(SystemStats));
