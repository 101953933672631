export const useCustomMrnInfo = (defaultLabel) => {
  const store = window.glookoStore;
  let providerGroupSite;

  if (store) {
    const state = store.getState();
    providerGroupSite = state.providerGroupSite;
  }

  return {
    labelMrn: providerGroupSite?.customMrnLabel ?? defaultLabel,
  };
};
