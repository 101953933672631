import React from 'react';
import PropTypes from 'prop-types';
import translate from '~/bundles/shared/components/WithTranslate/WithTranslate';
import { getTextDirection } from '../../../../../utils/I18n/I18n';
import { GRAPH_CONFIG } from '~/bundles/shared/components/graphs/hoursGraphConfig';
import Style from './WeekViewPdfStatistics.scss';

const singleStat = (name, value, unit, stat) => {
  if (stat === 'readingsPerDay' && !value) return null;

  const suffix = unit ? ` ${unit}` : '';
  const nbsp = (e) => e.toString().replace(/ /g, '\u00a0');

  return (
    <div class={Style.singleStat} key={stat}>
      <span class={Style.statName}>{nbsp(name)}:</span>&nbsp;
      <span class={Style.result}>{nbsp(`${value}${suffix}`)}</span>
    </div>
  );
};

function processStatistics(stats, t) {
  // Brake down manual insulin if no insulin from device
  if (!stats.totalPumpInsulinPerDay) {
    stats.bolusUnitsPerDay = stats.otherBolusUnitsPerDay;
    stats.bolusPercentage = stats.otherBolusPercentage;
    stats.basalUnitsPerDay = stats.otherBasalUnitsPerDay;
    stats.basalPercentage = stats.otherBasalPercentage;
    stats.premixedUnitsPerDay = stats.otherPremixedUnitsPerDay;
    stats.premixedPercentage = stats.otherPremixedPercentage;
    stats.numOfBolusesPerDay = stats.numOfOtherBolusesPerDay;
    stats.manualInsulinPerDay = 0;
  }

  const insulinItem = (type) => t('insulinValue', {
    value: stats[`${type}UnitsPerDay`] || 0,
    units: t('units'),
    percentage: stats[`${type}Percentage`] || 0,
    percent: t('percent'),
  });

  stats['bolus'] = insulinItem('bolus');
  stats['basal'] = insulinItem('basal');

  if (stats['premixedUnitsPerDay']) stats['premixed'] = insulinItem('premixed');

  return stats;
}

function clearOptional(stats) {
  const optionalKeys = [
    'premixed', 'manualInsulinPerDay',
  ];

  return (e) => (optionalKeys.includes(e) ? !!stats[e] : true);
}

const WeekViewPdfStatistics = (props) => {
  const { meterUnits, graphConfig, t } = props;
  const statistics = processStatistics({ ...props.statistics }, t);
  const permanentStats = [
    'seriousLowPercentage', 'lowPercentageWithoutSeriousLow', 'inRangePercentage',
    'highPercentageWithoutSeriousHigh', 'seriousHighPercentage', 'median', 'averageBg',
    'readingsPerDay', 'carbsPerDay', 'numOfBolusesPerDay', 'bolus', 'basal',
    'premixed', 'manualInsulinPerDay',
  ];
  const permanentStatTranslations = {
    seriousLowPercentage: t('seriousLowPercentage'),
    lowPercentageWithoutSeriousLow: t('lowPercentage'),
    inRangePercentage: t('inRangePercentage'),
    highPercentageWithoutSeriousHigh: t('highPercentage'),
    seriousHighPercentage: t('seriousHighPercentage'),
    median: statistics.egv ? t('medianGlucoseCGM') : t('medianGlucoseBG'),
    averageBg: statistics.egv ? t('averageGlucoseCGM') : t('averageGlucoseBG'),
    basal: t('basal'),
    bolus: t('bolus'),
    premixed: t('premixed'),
    manualInsulinPerDay: t('manualInsulinPerDay'),
    carbsPerDay: t('carbsPerDay'),
    numOfBolusesPerDay: t('numOfBolusesPerDay'),
    readingsPerDay: t('readingsPerDay'),
  };
  const percent = t('percent');
  const statUnits = {
    seriousLowPercentage: percent,
    lowPercentageWithoutSeriousLow: percent,
    inRangePercentage: percent,
    highPercentageWithoutSeriousHigh: percent,
    seriousHighPercentage: percent,
    median: meterUnits,
    averageBg: meterUnits,
    bolus: null,
    basal: null,
    premixed: null,
    manualInsulinPerDay: t('units'),
    bolusPercentage: percent,
    carbsPerDay: 'g',
    numOfBolusesPerDay: null,
    readingsPerDay: null,
  };

  const statsHeight = GRAPH_CONFIG[graphConfig]?.heights?.stats;
  const style = {
    height: statsHeight ? `${statsHeight}px` : undefined,
  };

  return (
    <div class={Style.WeekViewPdfStatistics} style={style}>
      <div class={Style.titleContainer}>
        <span class={Style.title}>{t('stats')}</span>
      </div>
      <div class={Style.statList} dir={getTextDirection()}>
        {permanentStats.filter(clearOptional(statistics)).map((stat) => singleStat(
          permanentStatTranslations[stat], statistics[stat], statUnits[stat], stat,
        ))}
      </div>
    </div>
  );
};

WeekViewPdfStatistics.propTypes = {
  hasPump: PropTypes.bool.isRequired,
  graphConfig: PropTypes.string.isRequired,
  meterUnits: PropTypes.string.isRequired,
  statistics: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('WeekViewPdfStatistics')(WeekViewPdfStatistics);
