import { merge, omitBy, isNil, has } from 'lodash';
import { GraphSelectionOptions } from 'types/global';
import I18n from 'utils/I18n/I18n';

const t = I18n.getFixedT(null, 'UserHelper');

const WHITELISTED_I18N_KEYS = [
  'gestational', 'lada', 'not_specified', 'none', 'other', 'pre_diabetes', 'type_1', 'type_2', 'bg', 'bg_manual',
  'cgm', 'and', 'en', 'de', 'fr', 'fr-CA', 'el', 'es', 'es-US', 'it', 'nl', 'sv', 'tr', 'ar', 'da', 'fi', 'pl', 'pt-PT',
  'sk', 'tr', 'cs', 'en-GB', 'hr', 'nb-NO',
];

class UserHelper {
  static displayUser(state: State) {
    const { currentUser, currentPatient } = state.users.currentUsers;

    return currentPatient || currentUser;
  }

  static currentUser(state: State) {
    return state.users.currentUsers.currentUser;
  }

  static currentPatient(state: State) {
    return state.users.currentUsers.currentPatient;
  }

  static currentDisplayUserPatternsEnabled(state: State) {
    return UserHelper.displayUser(state).patternsEnabled;
  }

  static userFullName(state: State) {
    const userHelper = UserHelper.displayUser(state);
    return `${userHelper.firstName} ${userHelper.lastName}`;
  }

  static userDateOfBirth(state: State) {
    const userHelper = UserHelper.displayUser(state);
    return userHelper.dateOfBirth;
  }

  static userTypeOfDiabetes(state: State) {
    const userHelper = UserHelper.displayUser(state);
    return userHelper.diabetesType;
  }

  static currentPatientMRN(state: State) {
    const { currentPatient } = state.users.currentUsers;
    const hasMrn = has(currentPatient, 'mrn');
    return hasMrn ? currentPatient.mrn : null;
  }

  static CurrentUserHidePatientList(state: State) {
    return UserHelper.currentUser(state).hidePatientList;
  }

  static displayUserIsPatient(state: State) {
    return UserHelper.displayUser(state).userType.toLowerCase() === 'patient';
  }

  static currentUserIsPatient(state: State) {
    return UserHelper.currentUser(state).userType.toLowerCase() === 'patient';
  }

  static currentUserCountry(state: State) {
    return UserHelper.currentUser(state).countryOfResidence;
  }

  static lastSyncTimestampStore(state: State) {
    return UserHelper.displayUser(state).lastSyncTimestamps;
  }

  static lastSyncTimestamp(state: State) {
    return UserHelper.lastSyncTimestampStore(state).lastSyncTimestamp;
  }

  static lastKioskSync(state: State) {
    return UserHelper.lastSyncTimestampStore(state).kiosk;
  }

  static currentUserIsMinor(state: State) {
    return UserHelper.currentUser(state).isMinor;
  }

  static currentPatientIsMinor(state: State) {
    return UserHelper.currentPatient(state)?.isMinor;
  }

  static currentPatientGuardianEmail(state: State) {
    return UserHelper.currentPatient(state)?.guardianEmail;
  }

  static getGuardianEmail(state: State) {
    return UserHelper.currentUser(state).guardianEmail;
  }

  static lastBgSyncTimestamp(state: State) {
    const { pump, meter } = UserHelper.lastSyncTimestampStore(state);
    if (pump && meter) {
      return pump > meter ? pump : meter;
    }
    return pump ?? meter;
  }

  static lastCgmSyncTimestamp(state: State) {
    return UserHelper.lastSyncTimestampStore(state).cgmDevice;
  }

  static syncedExerciseApps(state: State) {
    return UserHelper.displayUser(state).syncedExerciseApps;
  }

  static hasPump(state: State) {
    return !!UserHelper.lastSyncTimestampStore(state).pump;
  }

  static hasMeter(state: State) {
    return !!UserHelper.lastSyncTimestampStore(state).meter;
  }

  static hasCgmDevice(state: State) {
    return !!UserHelper.lastSyncTimestampStore(state).cgmDevice;
  }

  static hasPenDevice(state: State) {
    return !!UserHelper.lastSyncTimestampStore(state).insulinPen;
  }

  static hasBG(state: State) {
    return !!UserHelper.hasPump(state) || !!UserHelper.hasMeter(state);
  }

  static hasManualData(state: State) {
    return UserHelper.displayUser(state).insulinData;
  }

  static userPreference(state: State) {
    return UserHelper.displayUser(state).preference;
  }

  static userPendingConsents(state: State) {
    return UserHelper.currentUser(state).pendingConsents;
  }

  static setGraphSelectionsLocally(options: GraphSelectionOptions) {
    if (!localStorage) return {};
    const oldOptions = UserHelper.getLocalGraphSelections();
    const newOptions = omitBy(options, isNil);
    const mergedOptions = merge(oldOptions, newOptions);
    localStorage.setItem('graph_selections', JSON.stringify(mergedOptions));
    return mergedOptions;
  }

  static getLocalGraphSelections() {
    if (!localStorage) return {};
    const storedValues = JSON.parse(localStorage.getItem('graph_selections') ||
      '{}');
    return omitBy(storedValues, isNil);
  }

  static removeLocalGraphSelections() {
    if (!localStorage) return;
    localStorage.removeItem('graph_selections');
  }

  static retrieveLabel(key: string) {
    const throwError = () => {
      // eslint-disable-next-line no-process-env
      if (process.env.DEBUG === 'true') {
        throw new Error('UserHelper.retrieveLabel', { cause: key });
      }
      return '';
    };
    return WHITELISTED_I18N_KEYS.includes(key) ? t(key) : throwError();
  }

  static hasClosedLoopDevice(state: State) {
    return UserHelper.displayUser(state).hasClosedLoopDevice;
  }
}

export default UserHelper;
window.UserHelpersetGraphSelectionsLocally = UserHelper.setGraphSelectionsLocally;
