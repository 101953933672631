import PropTypes from 'prop-types';
import { findIndex, map } from 'lodash';
import { TYPE_BG } from '../readings';
import {
  ALL_TIMEFRAMES,
  CUSTOM_RANGE,
  ONE_WEEK,
  TWO_WEEKS,
  THIRTY_DAYS,
  NINETY_DAYS,
} from '../time';

export const PAGE_CALENDAR = 'PAGE_CALENDAR';
export const PAGE_SUMMARY = 'PAGE_SUMMARY';
export const PAGE_IGLUCOSE_NO_NAV = 'PAGE_IGLUCOSE_NO_NAV';
export const PAGE_IGLUCOSE_NAV = 'PAGE_IGLUCOSE_NAV';
export const PAGE_INITIAL = 'PAGE_INITIAL';
export const PAGE_INSIGHTS = 'PAGE_INSIGHTS';
export const PAGE_LOGBOOK = 'PAGE_LOGBOOK';
export const PAGE_OVERLAY = 'PAGE_OVERLAY';
export const PAGE_OVERVIEW = 'PAGE_OVERVIEW';
export const PAGE_WEEK_VIEW = 'PAGE_WEEK_VIEW';
export const PAGE_POPTRACKER = 'PAGE_POPTRACKER';
export const PAGE_PROTRACKER = 'PAGE_PROTRACKER';
export const PAGE_SETTINGS = 'PAGE_SETTINGS';
export const PAGE_HISTORY = 'PAGE_HISTORY';
export const PAGE_HYPO = 'PAGE_HYPO';
export const PAGE_DEVICES = 'PAGE_DEVICES';
export const PAGE_APPS = 'PAGE_APPS';
export const PAGE_OP5CONNECT = 'PAGE_OP5CONNECT';
export const PAGE_GRAPHS_BETA = 'PAGE_GRAPHS_BETA';

const selectTimeframes =
  (values) => values.map((v) => ALL_TIMEFRAMES.find((frame) => frame.value === v));

export const PAGES = [
  {
    id: PAGE_INITIAL,
    showControllerBar: false,
    showNavBar: false,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 4, // 30 days
    timeFrameIndexPdfWizard: findIndex(ALL_TIMEFRAMES, (timeframe) => timeframe.value === TWO_WEEKS),
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: true,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_OVERVIEW,
    showControllerBar: true,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 2, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: true,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: true,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_GRAPHS_BETA,
    showControllerBar: false,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 2, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: true,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: true,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_OVERLAY,
    showControllerBar: true,
    showNavBar: true,
    availableTimeFrames: selectTimeframes([
      ONE_WEEK,
      TWO_WEEKS,
      THIRTY_DAYS,
      NINETY_DAYS,
      CUSTOM_RANGE,
    ]),
    timeFrameIndex: 1, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: true,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_CALENDAR,
    showControllerBar: true,
    showNavBar: true,
    availableTimeFrames: selectTimeframes([TWO_WEEKS, CUSTOM_RANGE]),
    timeFrameIndex: 0, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_WEEK_VIEW,
    showControllerBar: true,
    showNavBar: true,
    availableTimeFrames: selectTimeframes([ONE_WEEK, CUSTOM_RANGE]),
    timeFrameIndex: 0, // 1 week
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: 7,
  },
  {
    id: PAGE_SUMMARY,
    showControllerBar: true,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 2, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: true,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: true,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_LOGBOOK,
    showControllerBar: true,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 2, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: true,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_HISTORY,
    showControllerBar: true,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 2, // 2 weeks
    readingsTypeEnabled: false,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: false,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: true,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_POPTRACKER,
    showControllerBar: false,
    showNavBar: false,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 1,
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    readingsType: TYPE_BG,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_PROTRACKER,
    showControllerBar: false,
    showNavBar: false,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 1,
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    readingsType: TYPE_BG,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_INSIGHTS,
    showControllerBar: true,
    showNavBar: true,
    availableTimeFrames: selectTimeframes([
      THIRTY_DAYS,
      NINETY_DAYS,
      CUSTOM_RANGE,
    ]),
    timeFrameIndex: 0, // 30 days
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_SETTINGS,
    showControllerBar: false,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 2, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_HYPO,
    showControllerBar: false,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 1, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_IGLUCOSE_NO_NAV,
    showControllerBar: false,
    showNavBar: false,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 1, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_IGLUCOSE_NAV,
    showControllerBar: false,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 1, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_DEVICES,
    showControllerBar: false,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 1, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_APPS,
    showControllerBar: false,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 1, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
  {
    id: PAGE_OP5CONNECT,
    showControllerBar: false,
    showNavBar: true,
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 2, // 2 weeks
    readingsTypeEnabled: true,
    readingsTypeSelectorEnabled: false,
    profileFeaturesEnabled: true,
    exerciseTypeSelectorEnabled: false,
    noToggleBg: false,
    customRangeDaysLimit: null,
  },
];

export const PAGE_TO_PAGES_INDEX = PAGES.reduce(
  (accumulator, currentValue, currentIndex) => ({
    ...accumulator,
    [currentValue.id]: currentIndex,
  }),
  {},
);

export const PAGE_PROPTYPES = PropTypes.oneOf(map(PAGES, (p) => p.id));
