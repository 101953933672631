import moment from 'moment';
import i18next from '~/utils/I18n/I18n';
import { TIME_FORMATS } from '../../utils/i18nFormats';

class TimeUtils {
  static exerciseDuration(tick) {
    const hours = (tick / 3600);
    const rHours = Math.floor(hours);
    const minutes = (hours - rHours) * 60;
    const rMinutes = Math.round(minutes);
    return { rHours, rMinutes };
  }

  static formatDuration(
    seconds, ignoreSeconds = false, short = true, delimiter = ' ', t = (string) => string,
  ) {
    const { rHours, rMinutes } = this.exerciseDuration(seconds);

    if (seconds < 60 && !ignoreSeconds) {
      return (short ? `${seconds}${delimiter}${t('s')}` : `${seconds}${delimiter}${t('sec')}`);
    }
    if (rHours === 0) {
      return (short ? `${rMinutes}${delimiter}${t('m')}` : `${rMinutes}${delimiter}${t('min')}`);
    }
    return (short ? `${rHours}${delimiter}${t('h')} ${rMinutes}${delimiter}${t('m')}` :
      `${rHours}${delimiter}${t('hr')} ${rMinutes}${delimiter}${t('min')}`);
  }

  static diffTimestamps(start, end, hanging = true) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return hanging ? (endDate - startDate) / 1000 :
      (endDate.setSeconds(0) - startDate.setSeconds(0)) / 1000;
  }

  static generateTimestampNoTZ() {
    return `${moment().format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
  }

  static generateUtcTimestampNoTZ() {
    return `${moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
  }

  static toLocalTimestamp(utcTimestamp) {
    return moment.utc(utcTimestamp).local();
  }

  static iso8601WithoutTZ(value, format) {
    return `${moment(value, format).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
  }

  static formatTime(hours, minutes, ampm) {
    const lang = i18next.language === 'en';
    return `${hours}:${minutes}${lang ? ` ${ampm}` : ''}`;
  }

  static isDateValid(date, format) {
    return moment.utc(date, format, true).isValid();
  }

  static formatDate(date, format) {
    return date ? moment.utc(date).format(format) : '';
  }

  static formatLocalDate(date, format) {
    return date ? moment(date).format(format) : '';
  }

  static isSameYear(startDate, endDate) {
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();
    return startYear && endYear ? startYear === endYear : false;
  }

  static localizeWeekdayAbbrevationFromEnglish(abbrevation) {
    const currentLanguage = moment().locale();
    const localizedAbbrevation = moment().locale('en').day(abbrevation);
    return localizedAbbrevation.locale(currentLanguage).format(TIME_FORMATS.ddd);
  }

  static formatDateForSummaryPage(startDate, endDate) {
    let formattedStartDate;
    let formattedEndDate;
    const sameDay = startDate.isSame(endDate, 'day');
    const sameYear = startDate.isSame(endDate, 'year');

    if (sameDay) {
      formattedStartDate = startDate.format(TIME_FORMATS.DDD_MMM_DD_YYYY);
      formattedEndDate = null;
    } else if (sameYear) {
      formattedStartDate = startDate.format(TIME_FORMATS.DDD_MMM_DD);
      formattedEndDate = endDate.format(TIME_FORMATS.DDD_MMM_DD_YYYY);
    } else {
      formattedStartDate = startDate.format(TIME_FORMATS.DDD_MMM_DD_YYYY);
      formattedEndDate = endDate.format(TIME_FORMATS.DDD_MMM_DD_YYYY);
    }

    return { formattedStartDate, formattedEndDate };
  }

  static isOfAge(dob, majorityAge) {
    const minAge = majorityAge || 16;
    const dobDate = dob.split('T')[0];
    const nowDate = moment().format().split('T')[0];
    return moment(nowDate).diff(dobDate, 'years', false) >= minAge;
  }
}

export default TimeUtils;
