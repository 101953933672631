import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { Trans, useTranslation } from 'react-i18next';
import Style from './FileUploaderPopup.scss';
import Images from '../../../../utils/ImageStore';
import SupportLinks from '../../../../utils/support';

export interface FileUploaderPopupFailedProps {
  acceptedExtensions: string[];
  onClosePopup: () => void;
  onTryAgain: () => void;
}

const FileUploaderPopupFailed = (props: FileUploaderPopupFailedProps) => {
  const { t } = useTranslation('FileUploaderPopup');
  const { onClosePopup, onTryAgain, acceptedExtensions } = props;

  const extensionsString = acceptedExtensions.join(' / ');

  return (
    <Dialog dataAttributes={{ testid: 'file-uploader-failed' }} onClose={onClosePopup} open size="medium">
      <DialogHeader dataAttributes={{ testid: 'file-uploader-failed' }}>
        <h2>{t('uploadErrorTitle')}</h2>
      </DialogHeader>

      <DialogContent dataAttributes={{ testid: 'file-uploader-failed' }}>
        <div className={Style.failed}>
          {t('uploadErrorMsgTitle')}
          <img alt="" src={Images.integrationTickFailure} />
        </div>

        <p>{t('uploadErrorMsgLine1', { extensionsString })}</p>

        <p>
          <em>
            <Trans
              components={{
                supportLink: <a href={SupportLinks.fileTicket()} rel="noreferrer" target="_blank" />,
              }}
              i18nKey="needHelp"
              t={t}
            />
          </em>
        </p>
      </DialogContent>

      <DialogActions dataAttributes={{ testid: 'file-uploader-failed' }}>
        <Button
          dataAttributes={{ testid: 'file-uploader-failed-upload-error-close' }}
          onClick={onClosePopup}
          variation="secondary"
        >
          {t('uploadErrorCloseButton')}
        </Button>

        <Button
          dataAttributes={{ testid: 'file-uploader-failed-upload-error-try-again' }}
          onClick={onTryAgain}
        >
          {t('uploadErrorTryAgainButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploaderPopupFailed;
