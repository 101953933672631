import { connect } from 'react-redux';
import useHumanize from 'hooks/useHumanize';
import UserHelper from 'redux/modules/users/UserHelper';
import WithTranslate from 'bundles/shared/components/WithTranslate/WithTranslate';
import Style from '../OverviewTooltipPresenter.scss';

const mapStateToProps = (state: State) => ({
  distanceUnit: UserHelper.userPreference(state).runningDistanceUnit,
});

export interface ExerciseDurationRowProps extends WithTranslationFunction {
   exercise: GraphExercise;
   distanceUnit: string;
}

const ExerciseDurationRow = (props: ExerciseDurationRowProps) => {
  const { exercise, t, distanceUnit } = props;
  const { humanizeDistance, humanizeDuration } = useHumanize();

  return (
    <div className={Style.exerciseRow}>
      <div className={Style.exerciseColumn}>
        <h3>{exercise.type}</h3>
        <span>{t('exerciseType')}</span>
      </div>

      <div className={Style.exerciseColumn}>
        <h3>{humanizeDuration(exercise.duration)}</h3>
        <span>{t('duration')}</span>
      </div>

      {exercise.distance ? (
        <div className={Style.exerciseColumn}>
          <h3>{humanizeDistance(exercise.distance, distanceUnit)}</h3>
          <span>{t('distance')}</span>
        </div>
      ) : null}
    </div>
  );
};

export default WithTranslate('OverviewTooltip')(connect(mapStateToProps)(ExerciseDurationRow));
