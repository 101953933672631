import originalAxios from 'axios';
import queryString from 'query-string';
import UserHelper from '~/redux/modules/users/UserHelper';
import axios from './apiClient/axios';
import envConfig from '../../config/GlobalEnvConfig';

const MainStore = () => require('~/redux/store/MainStore').default;
let request;

function ExecuteApiCallGeneric(method, endpoint, payload, baseUrl = envConfig.apiUrl, isCsvRequest = false) {
  const options = {
    method,
    url: `${baseUrl}/${endpoint}`,
    params: {},
    data: {},
  };

  if (isCsvRequest) {
    options.responseType = 'blob';
  }

  switch (method) {
    case 'delete': {
      Object.assign(options, { params: payload });
      break;
    }
    case 'post':
    case 'put': {
      Object.assign(options, { data: payload });
      break;
    }
    case 'get': {
      Object.assign(options.params, payload);
      break;
    }
    default:
      break;
  }

  return axios(options);
}

function ExecuteApiCallModifiedWithHeaders(method, endpoint, payload, baseUrl = envConfig.apiUrl) {
  const options = {
    method,
    url: `${baseUrl}/${endpoint}`,
    params: {},
    data: {},
  };

  switch (method) {
    case 'post':
    case 'put': {
      Object.assign(options, { headers: payload.headers, data: payload.data });
      break;
    }
    default:
      break;
  }

  return axios(options);
}

function ExecuteApiCallGenericAfterCancelPrev(method, endpoint, payload = {}) {
  const once = (options = {}) => {
    switch (method) {
      case 'get': {
        Object.assign(options.params, payload);
        break;
      }
      default:
        break;
    }
    if (request) {
      request.cancel('Only one request allowed at a time.');
    }
    request = originalAxios.CancelToken.source();
    options.cancelToken = request.token;

    return originalAxios(options);
  };

  const options = {
    method,
    url: `${envConfig.apiUrl}/${endpoint}`,
    params: {},
    data: {},
  };

  return once(options);
}

function withDefaultParamsInPayloadWhen(withDefaultParams, payloadArg) {
  return withDefaultParams ? {
    patient: UserHelper.displayUser(MainStore().getState()).glookoCode,
    ...payloadArg,
  } : payloadArg;
}

export default function ExecuteApiCall(method, endpoint, payloadArg = {}) {
  const payload = withDefaultParamsInPayloadWhen(method === 'get', payloadArg);

  return ExecuteApiCallGeneric(method, endpoint, payload);
}

export function ExecuteUnchangedApiCall(method, endpoint, payload = {}, baseUrl) {
  return ExecuteApiCallGeneric(method, endpoint, payload, baseUrl);
}

export function ExecuteAfterCancelPrevApiCall(method, endpoint, payload = {}) {
  return ExecuteApiCallGenericAfterCancelPrev(method, endpoint, payload);
}

export function ExecuteFileUploadApiCall(method, endpoint, payload, baseUrl) {
  return ExecuteApiCallModifiedWithHeaders(method, endpoint, payload, baseUrl);
}

export function ExecuteApiCallForCSV(method, endpoint, payloadArg = {}) {
  const isCsvRequest = true;
  return ExecuteApiCallGeneric(method, endpoint, payloadArg, envConfig.apiUrl, isCsvRequest);
}

export function OpenPageGeneric(endpoint, params, target) {
  const queryParams = queryString.stringify(params);
  const win = window.open([
    '/',
    endpoint.replace(/^\//, ''),
    (queryParams.length ? '?' : ''),
    queryParams,
  ].join(''),
  target);

  win.focus();
}

export function OpenPageGenericWithLocale(endpoint, params, target) {
  const parsed = queryString.parse(location.search);
  return OpenPageGeneric(endpoint, {
    ...params,
    ...(parsed.locale ? {
      locale: parsed.locale,
    } : {}),
  },
  target);
}
