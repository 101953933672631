import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import UserHelper from 'redux/modules/users/UserHelper';
import { Trans, useTranslation } from 'react-i18next';

export interface FileUploaderPopupConfirmProps {
  file: File;
  onConfirm: () => void;
  onDecline: () => void;
  fileParser?: (file: File, setData: (data: FileInfo) => void) => Promise<void>;
}

export type FileInfo = {
  name?: string;
  startDate?: string;
  endDate?: string;
}

const mapStateToProps = (state: State) => ({
  currentUserName: UserHelper.userFullName(state),
  currentUserIsPatient: UserHelper.currentUserIsPatient(state),
});

const FileUploaderPopupConfirm = (props: FileUploaderPopupConfirmProps & ReturnType<typeof mapStateToProps>) => {
  const { t } = useTranslation('FileUploaderPopup');
  const { onConfirm, onDecline, file, currentUserName, currentUserIsPatient, fileParser } = props;
  const [parsedUploadData, setParsedUploadData] = useState<FileInfo>();

  useEffect(() => {
    fileParser?.(file, setParsedUploadData);
  }, []);

  return (
    <Dialog dataAttributes={{ testid: 'file-uploader-confirm' }} onClose={onDecline} open size="medium">
      <DialogHeader dataAttributes={{ testid: 'file-uploader-confirm' }}>
        <h2>{t('uploadConfirmTitle')}</h2>
      </DialogHeader>

      <DialogContent dataAttributes={{ testid: 'file-uploader-confirm' }}>

        <p>
          {parsedUploadData?.name ? (
            <><Trans i18nKey="uploadConfirmName" t={t} values={{ name: parsedUploadData.name }} /><br /></>
          ) : null}

          {parsedUploadData?.startDate && parsedUploadData?.endDate ? (
            <>
              <Trans
                i18nKey="uploadConfirmPeriod"
                t={t}
                values={{
                  startDate: parsedUploadData.startDate,
                  endDate: parsedUploadData.endDate,
                }}
              />

              <br />
            </>
          ) : null}

          <Trans i18nKey="uploadConfirmCurrentPatient" t={t} values={{ name: currentUserName }} />
        </p>

        {currentUserIsPatient ? (
          <p>
            <em>
              <Trans
                components={{
                  supportLink: <a href="https://glooko.com/privacy/" rel="noreferrer" target="_blank" />,
                }}
                i18nKey="privacyNotice"
                t={t}
              />
            </em>
          </p>
        ) : null}
      </DialogContent>

      <DialogActions dataAttributes={{ testid: 'file-uploader-confirm' }}>
        <Button
          dataAttributes={{ testid: 'file-uploader-cancel' }}
          onClick={onDecline}
          variation="secondary"
        >
          {t('cancelButton')}
        </Button>

        <Button dataAttributes={{ testid: 'file-uploader-confirm' }} onClick={onConfirm}>{t('confirmButton')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect<ReturnType<typeof mapStateToProps>, unknown, FileUploaderPopupConfirmProps, State>(mapStateToProps)(FileUploaderPopupConfirm);
