import envConfig from '../../../../config/GlobalEnvConfig';

export const API_URL = envConfig.apiUrl;
export const PATH_ROOT = '/';
export const PATH_ADMIN = '/admin';
export const PATH_DASHBOARD = '/dashboard';
export const PATH_DEVICES = '/devices';
export const PATH_GRAPHS = '/graphs';
export const PATH_GRAPHS_BETA = '/graphs_beta';
export const PATH_HISTORY = '/history';
export const PATH_HYPOMAP = '/hypo';
export const PATH_INSIGHTS = '/pump_insights';
export const PATH_LOGBOOK = '/logbook';
export const PATH_SETTINGS = '/settings';
export const PATH_SIGN_OUT = '/users/sign_out';
export const PATH_SUMMARY = '/summary';
export const PATH_PROTRACKER = '/professionals';
export const PATH_GLAD = envConfig.gladUrl;
export const PATH_JOIN = envConfig.regionalJoinUrl;
export const PATH_POP_INSIGHTS = envConfig.popInsightsUrl;
export const PATH_IGLUCOSE_CONNECT = '/iglucose/connect';
export const PATH_IGLUCOSE_MANAGE_DEVICES = '/iglucose/manage_devices';
export const PATH_VALIDIC = 'validic';
export const PATH_APPS = '/apps';
export const PATH_INSULET_CLOUD_REGISTRATION = '/op5-registration';
export const PATH_INSULET_CONFIRM_CLINIC = '/op5-confirm-clinic';
export const PATH_INSULET_DATACENTER_CHECK = '/op5-datacenter-check';
export const PATH_SIGN_IN = '/users/sign_in';
