/* eslint-disable camelcase */
import { isFinite } from 'lodash';
import i18next, { getTextDirection } from './I18n/I18n';
import { Globalize, GlobalizeFn } from './Globalize';

const NUMBER_FORMATTER_OVERRIDES = {
  ar: 'en',
};

const skipNotNumbers = (func) => ((value) => (isFinite(value) ? func(value) : value));

const numberFormatterCache = {};
const numberFormatterExactCache = {};
const getNumberFormatter = (options, exact = false) => {
  const lang = i18next.language;
  let cache;
  let key;

  if (exact) {
    cache = numberFormatterExactCache[lang] || {};
    key = `${options.minimumFractionDigits}-${options.maximumFractionDigits}`;
  } else {
    cache = numberFormatterCache[lang] || {};
    key = options.maximumFractionDigits;
  }

  cache[key] = cache[key] || globalizeNumberFormatter(lang, options);

  return cache[key];
};
const globalizeInstance = (lang) => {
  const override = NUMBER_FORMATTER_OVERRIDES[lang];
  return override ? GlobalizeFn(override) : Globalize;
};
const globalizeNumberFormatter = (lang, options) => globalizeInstance(lang).numberFormatter(options);
const globalizeNumberParser = (lang, options) => globalizeInstance(lang).numberParser(options);
const numberFormatterMaxDecimal = (maxDecimal) => skipNotNumbers((val) => {
  const options = {
    style: 'decimal',
    maximumFractionDigits: maxDecimal,
  };

  return getNumberFormatter(options)(val);
});

const numberFormatterExaclyDecimal = (decimals) => (val) => getNumberFormatter({
  useGrouping: false,
  minimumFractionDigits: decimals,
  maximumFractionDigits: decimals,
}, true)(val);

export const numberFormatterOneDecimal = numberFormatterMaxDecimal(1);
export const numberFormatterThreeDecimals = numberFormatterMaxDecimal(3);
export const numberFormatterExactlyOneDecimal = numberFormatterExaclyDecimal(1);
export const numberFormatterExactlyTwoDecimals = numberFormatterExaclyDecimal(2);
export const numberFormatterExactlyThreeDecimals = numberFormatterExaclyDecimal(3);
export const formatDecimalSkipGrouping = skipNotNumbers(
  Globalize.numberFormatter({ useGrouping: false }));
export const formatNumber = skipNotNumbers(globalizeNumberFormatter(i18next.language, {}));
export const parseNumber = globalizeNumberParser(i18next.language, {});
window.i18nFormatHelper = {
  formatNumber, parseNumber, numberFormatterOneDecimal, numberFormatterThreeDecimals,
};

export const TIME_FORMAT_CONFIG = {
  DO_YYYY: {
    en: 'Do, YYYY',
    de: 'Do YYYY',
    el: 'Do YYYY',
    'fr-CA': 'D YYYY',
    'es-US': 'D YYYY',
    tr: 'Do YYYY',
    hr: 'Do YYYY',
    'en-GB': 'Do, YYYY',
    fr: 'Do YYYY',
    es: 'D YYYY',
    it: 'D YYYY',
    fi: 'Do YYYY',
    sk: 'Do YYYY',
    pl: 'Do YYYY',
    nl: 'Do YYYY',
    sv: 'Do YYYY',
    cs: 'Do YYYY',
    'nb-NO': 'Do YYYY',
    da: 'Do YYYY',
    'pt-PT': 'Do YYYY',
    ar: 'DD YYYY',
  },
  YYYY: {
    en: 'YYYY',
    de: 'YYYY',
    el: 'YYYY',
    'fr-CA': 'YYYY',
    'es-US': 'YYYY',
    tr: 'YYYY',
    hr: 'YYYY',
    'en-GB': 'YYYY',
    fr: 'YYYY',
    es: 'YYYY',
    it: 'YYYY',
    fi: 'YYYY',
    sk: 'YYYY',
    pl: 'YYYY',
    nl: 'YYYY',
    sv: 'YYYY',
    cs: 'YYYY',
    'nb-NO': 'YYYY',
    da: 'YYYY',
    'pt-PT': 'YYYY',
    ar: 'YYYY',
  },
  DD: {
    en: 'dd',
    de: 'dd',
    el: 'dd',
    'fr-CA': 'dd',
    'es-US': 'dd',
    tr: 'dd',
    hr: 'dd',
    'en-GB': 'dd',
    fr: 'dd',
    es: 'dd',
    it: 'dd',
    fi: 'dd',
    sk: 'dd',
    pl: 'dd',
    nl: 'dd',
    sv: 'dd',
    cs: 'dd',
    'nb-NO': 'dd',
    da: 'dd',
    'pt-PT': 'dd',
    ar: 'dd',
  },
  DD_YYYY: {
    en: 'DD, YYYY',
    de: 'DD. YYYY',
    el: 'DD YYYY',
    'fr-CA': 'DD YYYY',
    'es-US': 'DD YYYY',
    tr: 'DD. YYYY',
    hr: 'DD. YYYY',
    'en-GB': 'DD, YYYY',
    fr: 'DD YYYY',
    es: 'DD YYYY',
    it: 'DD YYYY',
    fi: 'DD. YYYY',
    sk: 'DD. YYYY',
    pl: 'DD. YYYY',
    nl: 'DD YYYY',
    sv: 'DD YYYY',
    cs: 'DD. YYYY',
    'nb-NO': 'DD YYYY',
    da: 'DD. YYYY',
    'pt-PT': 'DD YYYY',
    ar: 'DD YYYY',
  },
  DDDD_MMM_DO: {
    en: 'dddd, MMM Do',
    de: 'dddd, Do MMM',
    el: 'dddd, Do MMM',
    'fr-CA': 'dddd, Do MMM',
    'es-US': 'dddd, D MMM',
    tr: 'dddd, Do MMM',
    hr: 'dddd, Do MMM',
    'en-GB': 'dddd, Do MMM',
    fr: 'dddd, Do MMM',
    es: 'dddd, D MMM',
    it: 'dddd, D MMM',
    fi: 'dddd, Do MMM',
    sk: 'dddd, Do MMM.',
    pl: 'dddd, Do MMM',
    nl: 'dddd, Do MMM',
    sv: 'dddd, Do MMM',
    cs: 'dddd, Do MMM',
    'nb-NO': 'dddd Do. MMM',
    da: 'dddd, Do. MMM',
    'pt-PT': 'dddd, D MMM',
    ar: 'dddd, DD MMM',
  },
  DDDD_MMM_DO_YYYY: {
    en: 'dddd, MMM Do, YYYY',
    de: 'dddd, Do MMM YYYY',
    el: 'dddd, Do MMM YYYY',
    'fr-CA': 'dddd, Do MMM YYYY',
    'es-US': 'dddd, D MMM YYYY',
    tr: 'dddd, Do MMM YYYY',
    hr: 'dddd, Do MMM YYYY',
    'en-GB': 'dddd, Do MMM, YYYY',
    fr: 'dddd, Do MMM YYYY',
    es: 'dddd, D MMM YYYY',
    it: 'dddd, D MMM YYYY',
    fi: 'dddd, Do MMM YYYY',
    sk: 'dddd, Do MMM. YYYY',
    pl: 'dddd, Do MMM YYYY',
    nl: 'dddd, Do MMM YYYY',
    sv: 'dddd, Do MMM YYYY',
    cs: 'dddd, Do MMM YYYY',
    'nb-NO': 'dddd Do. MMM YYYY',
    da: 'dddd, Do. MMM YYYY',
    'pt-PT': 'dddd, D MMM YYYY',
    ar: 'dddd, DD MMM YYYY',
  },
  DDD_MMM_DD_YYYY: {
    en: 'ddd, MMM Do, YYYY',
    de: 'ddd, DD. MMM YYYY',
    el: 'ddd, DD MMM YYYY',
    'fr-CA': 'ddd, DD MMM YYYY',
    'es-US': 'ddd, DD MMM. YYYY',
    tr: 'ddd, DD. MMM YYYY',
    hr: 'ddd, DD. MMM YYYY',
    'en-GB': 'ddd, DD MMM, YYYY',
    fr: 'ddd, DD MMM YYYY',
    es: 'ddd, DD MMM YYYY',
    it: 'ddd, DD MMM YYYY',
    fi: 'ddd, DD. MMM YYYY',
    sk: 'ddd, DD. MMM. YYYY',
    pl: 'ddd, DD. MMM YYYY',
    nl: 'ddd, DD MMM YYYY',
    sv: 'ddd, DD MMM YYYY',
    cs: 'ddd, DD. MMM YYYY',
    'nb-NO': 'ddd, DD. MMM YYYY',
    da: 'ddd, DD. MMM YYYY',
    'pt-PT': 'ddd, DD MMM YYYY',
    ar: 'ddd, DD MMM YYYY',
  },
  DDD_MMM_DD: {
    en: 'ddd, MMM Do',
    de: 'ddd, DD. MMM',
    el: 'ddd, DD MMM',
    'fr-CA': 'ddd, DD MMM',
    'es-US': 'ddd, DD MMM.',
    tr: 'ddd, DD. MMM',
    hr: 'ddd, DD. MMM',
    'en-GB': 'ddd, DD MMM',
    fr: 'ddd, DD MMM',
    es: 'ddd, DD MMM',
    it: 'ddd, DD MMM',
    fi: 'ddd, DD. MMM',
    sk: 'ddd, DD. MMM.',
    pl: 'ddd, DD. MMM',
    nl: 'ddd, DD MMM',
    sv: 'ddd, DD MMM',
    cs: 'ddd, DD. MMM',
    'nb-NO': 'ddd, DD. MMM',
    da: 'ddd, DD. MMM',
    'pt-PT': 'ddd, DD MMM',
    ar: 'ddd, DD MMM',
  },
  DDDD_MMM_D_YYYY: {
    en: 'dddd, MMMM D, YYYY',
    de: 'dddd, D. MMMM YYYY',
    el: 'dddd, D MMMM YYYY',
    'fr-CA': 'dddd, D MMMM YYYY',
    'es-US': 'dddd, D [de] MMMM [de] YYYY',
    tr: 'dddd, D MMMM YYYY',
    hr: 'dddd, D MMMM YYYY',
    'en-GB': 'dddd, D MMMM, YYYY',
    fr: 'dddd, D MMMM YYYY',
    es: 'dddd, D [de] MMMM [de] YYYY',
    it: 'dddd, D MMMM YYYY',
    fi: 'dddd, D MMMM YYYY',
    sk: 'dddd, D MMMM YYYY',
    pl: 'dddd, D MMMM YYYY',
    nl: 'dddd, D MMMM YYYY',
    sv: 'dddd, D MMMM YYYY',
    cs: 'dddd, D MMMM YYYY',
    'nb-NO': 'dddd D. MMMM YYYY',
    da: 'dddd, D. MMM YYYY',
    'pt-PT': 'dddd, D [de] MMMM [de] YYYY',
    ar: 'dddd, DD MMMM YYYY',
  },
  DDDD_MMM_DO_YYYY_DASH: {
    en: 'dddd - MMM Do, YYYY',
    de: 'dddd - Do MMM YYYY',
    el: 'dddd - Do MMM YYYY',
    'fr-CA': 'dddd D MMM YYYY',
    'es-US': 'dddd - D MMM YYYY',
    tr: 'dddd - Do MMM YYYY',
    hr: 'dddd - Do MMM YYYY',
    'en-GB': 'dddd - Do MMM, YYYY',
    fr: 'dddd - Do MMM YYYY',
    es: 'dddd - D MMM YYYY',
    it: 'dddd - D MMM YYYY',
    fi: 'dddd - Do MMM YYYY',
    sk: 'dddd - Do MMM. YYYY',
    pl: 'dddd - Do MMM YYYY',
    nl: 'dddd - Do MMM YYYY',
    sv: 'dddd - Do MMM YYYY',
    cs: 'dddd - Do MMM YYYY',
    'nb-NO': 'dddd - Do MMM YYYY',
    da: 'dddd - Do. MMM YYYY',
    'pt-PT': 'dddd - D MMM YYYY',
    ar: 'dddd - DD MMM YYYY',
  },
  H: {
    en: 'h',
    de: 'H',
    el: 'H',
    'fr-CA': 'H',
    'es-US': 'h',
    tr: 'H',
    hr: 'H',
    'en-GB': 'H',
    fr: 'H',
    es: 'H',
    it: 'H',
    fi: 'H',
    sk: 'H',
    pl: 'H',
    nl: 'H',
    sv: 'H',
    cs: 'H',
    'nb-NO': 'H',
    da: 'H',
    'pt-PT': 'H',
    ar: 'H',
  },
  HH: {
    en: 'h',
    de: 'HH',
    el: 'HH',
    'fr-CA': 'HH',
    'es-US': 'h',
    tr: 'HH',
    hr: 'HH',
    'en-GB': 'HH',
    fr: 'HH',
    es: 'HH',
    it: 'HH',
    fi: 'HH',
    sk: 'HH',
    pl: 'HH',
    nl: 'HH',
    sv: 'HH',
    cs: 'HH',
    'nb-NO': 'HH',
    da: 'HH',
    'pt-PT': 'HH',
    ar: 'HH',
  },
  HA: {
    en: 'ha',
    de: 'H',
    el: 'H',
    'fr-CA': 'H',
    'es-US': 'h a',
    tr: 'H',
    hr: 'H',
    'en-GB': 'H',
    fr: 'H',
    es: 'H',
    it: 'H',
    fi: 'H',
    sk: 'H',
    pl: 'H',
    nl: 'H',
    sv: 'H',
    cs: 'H',
    'nb-NO': 'H',
    da: 'H',
    'pt-PT': 'H',
    ar: 'H',
  },
  H_A: {
    en: 'h a',
    de: 'H',
    el: 'H',
    'fr-CA': 'H',
    'es-US': 'h a',
    tr: 'H',
    hr: 'H',
    'en-GB': 'H',
    fr: 'H',
    es: 'H',
    it: 'H',
    fi: 'H',
    sk: 'H',
    pl: 'H',
    nl: 'H',
    sv: 'H',
    cs: 'H',
    'nb-NO': 'H',
    da: 'H',
    'pt-PT': 'H',
    ar: 'H',
  },
  H_AA: {
    en: 'h A',
    de: 'H',
    el: 'H',
    'fr-CA': 'H',
    'es-US': 'h a',
    tr: 'H',
    hr: 'H',
    'en-GB': 'H',
    fr: 'H',
    es: 'H',
    it: 'H',
    fi: 'H',
    sk: 'H',
    pl: 'H',
    nl: 'H',
    sv: 'H',
    cs: 'H',
    'nb-NO': 'H',
    da: 'H',
    'pt-PT': 'H',
    ar: 'H',
  },
  H_MM_A: {
    en: 'h:mma',
    de: 'HH:mm',
    el: 'HH:mm',
    'fr-CA': 'HH:mm',
    'es-US': 'h:mm a',
    tr: 'HH:mm',
    hr: 'HH:mm',
    'en-GB': 'HH:mm',
    fr: 'HH:mm',
    es: 'HH:mm',
    it: 'HH:mm',
    fi: 'HH:mm',
    sk: 'HH:mm',
    pl: 'HH:mm',
    nl: 'HH:mm',
    sv: 'HH:mm',
    cs: 'HH:mm',
    'nb-NO': 'HH:mm',
    da: 'HH:mm',
    'pt-PT': 'HH:mm',
    ar: 'HH:mm',
  },
  H_MM__A: {
    en: 'h:mm A',
    de: 'HH:mm',
    el: 'HH:mm',
    'fr-CA': 'HH:mm',
    'es-US': 'h:mm a',
    tr: 'HH:mm',
    hr: 'HH:mm',
    'en-GB': 'HH:mm',
    fr: 'HH:mm',
    es: 'HH:mm',
    it: 'HH:mm',
    fi: 'HH:mm',
    sk: 'HH:mm',
    pl: 'HH:mm',
    nl: 'HH:mm',
    sv: 'HH:mm',
    cs: 'HH:mm',
    'nb-NO': 'HH:mm',
    da: 'HH:mm',
    'pt-PT': 'HH:mm',
    ar: 'HH:mm',
  },
  HH_MM: {
    en: 'hh:mm',
    de: 'HH:mm',
    el: 'HH:mm',
    'fr-CA': 'HH:mm',
    'es-US': 'hh:mm',
    tr: 'HH:mm',
    hr: 'HH:mm',
    'en-GB': 'HH:mm',
    fr: 'HH:mm',
    es: 'HH:mm',
    it: 'HH:mm',
    fi: 'HH:mm',
    sk: 'HH:mm',
    pl: 'HH:mm',
    nl: 'HH:mm',
    sv: 'HH:mm',
    cs: 'HH:mm',
    'nb-NO': 'HH:mm',
    da: 'HH:mm',
    'pt-PT': 'HH:mm',
    ar: 'HH:mm',
  },
  M_D: {
    en: 'M/D',
    de: 'D.M',
    el: 'DD/MM',
    'fr-CA': 'DD/MM',
    'es-US': 'DD/MM',
    tr: 'D.M',
    hr: 'D.M',
    'en-GB': 'DD/MM',
    fr: 'DD/MM',
    es: 'DD/MM',
    it: 'DD/MM',
    fi: 'D.M',
    sk: 'D.M',
    pl: 'D.M',
    nl: 'DD/MM',
    sv: 'DD/MM',
    cs: 'D.M',
    'nb-NO': 'DD.MM',
    da: 'D.M',
    'pt-PT': 'DD.MM',
    ar: 'M.D',
  },
  MM_DD_YY: {
    en: 'MM/DD/YY',
    de: 'DD.MM.YY',
    el: 'DD/MM/YY',
    'fr-CA': 'YY-MM-DD',
    'es-US': 'DD/MM/YY',
    tr: 'DD.MM.YY',
    hr: 'DD.MM.YY',
    'en-GB': 'DD/MM/YY',
    fr: 'DD/MM/YY',
    es: 'DD/MM/YY',
    it: 'DD/MM/YY',
    fi: 'DD.MM.YY',
    sk: 'DD.MM.YY',
    pl: 'DD.MM.YY',
    nl: 'DD/MM/YY',
    sv: 'YY/MM/DD',
    cs: 'DD.MM.YY',
    'nb-NO': 'DD.MM.YY',
    da: 'DD.MM.YY',
    'pt-PT': 'DD.MM.YY',
    ar: 'YY.MM.DD',
  },
  ddd: {
    en: 'ddd',
    de: 'ddd',
    el: 'ddd',
    'fr-CA': 'ddd',
    'es-US': 'ddd',
    tr: 'ddd',
    hr: 'ddd',
    'en-GB': 'ddd',
    fr: 'ddd',
    es: 'ddd',
    it: 'ddd',
    fi: 'ddd',
    sk: 'ddd',
    pl: 'ddd',
    nl: 'ddd',
    sv: 'ddd',
    cs: 'ddd',
    'nb-NO': 'ddd',
    da: 'ddd',
    'pt-PT': 'ddd',
    ar: 'ddd',
  },
  MM_DD_YYYY: {
    en: 'MM/DD/YYYY',
    de: 'DD.MM.YYYY',
    el: 'DD/MM/YYYY',
    'fr-CA': 'YYYY-MM-DD',
    'es-US': 'DD/MM/YYYY',
    tr: 'DD.MM.YYYY',
    hr: 'DD.MM.YYYY',
    'en-GB': 'DD/MM/YYYY',
    fr: 'DD/MM/YYYY',
    es: 'DD/MM/YYYY',
    it: 'DD/MM/YYYY',
    fi: 'DD.MM.YYYY',
    sk: 'DD.MM.YYYY',
    pl: 'DD.MM.YYYY',
    nl: 'DD/MM/YYYY',
    sv: 'YYYY/MM/DD',
    cs: 'DD.MM.YYYY',
    'nb-NO': 'DD.MM.YYYY',
    da: 'DD.MM.YYYY',
    'pt-PT': 'DD.MM.YYYY',
    ar: 'YYYY.MM.DD',
  },
  DOB_FORMAT: {
    en: 'MM / DD / YYYY',
    de: 'DD . MM . YYYY',
    el: 'DD / MM / YYYY',
    'fr-CA': 'YYYY - MM - DD',
    'es-US': 'DD / MM / YYYY',
    tr: 'DD . MM . YYYY',
    hr: 'DD . MM . YYYY',
    'en-GB': 'DD / MM / YYYY',
    fr: 'DD / MM / YYYY',
    es: 'DD / MM / YYYY',
    it: 'DD / MM / YYYY',
    fi: 'DD . MM . YYYY',
    sk: 'DD . MM . YYYY',
    pl: 'DD . MM . YYYY',
    nl: 'DD / MM / YYYY',
    sv: 'YYYY / MM / DD',
    cs: 'DD . MM . YYYY',
    'nb-NO': 'DD . MM . YYYY',
    da: 'DD . MM . YYYY',
    'pt-PT': 'DD . MM . YYYY',
    ar: 'YYYY . MM . DD',
  },
  MMM_DD: {
    en: 'MMM D',
    de: 'D. MMM',
    el: 'D MMM',
    'fr-CA': 'D MMM',
    'es-US': 'D MMM',
    tr: 'D. MMM',
    hr: 'D. MMM',
    'en-GB': 'D MMM',
    fr: 'D MMM',
    es: 'D MMM',
    it: 'D MMM',
    fi: 'D. MMM',
    sk: 'D. MMM.',
    pl: 'D. MMM',
    nl: 'D MMM',
    sv: 'D MMM',
    cs: 'D. MMM',
    'nb-NO': 'D. MMM',
    da: 'D. MMM',
    'pt-PT': 'D MMM',
    ar: 'MMM D',
  },
  MMM_DD_YYYY: {
    en: 'MMM D, YYYY',
    de: 'DD. MMM YYYY',
    el: 'D MMM YYYY',
    'fr-CA': 'DD MMM YYYY',
    'es-US': 'D MMM. YYYY',
    tr: 'DD. MMM YYYY',
    hr: 'DD. MMM YYYY',
    'en-GB': 'D MMM, YYYY',
    fr: 'D MMM YYYY',
    es: 'D MMM YYYY',
    it: 'D MMM YYYY',
    fi: 'DD. MMM YYYY',
    sk: 'DD. MMM. YYYY',
    pl: 'DD. MMM YYYY',
    nl: 'DD MMM YYYY',
    sv: 'DD MMM YYYY',
    cs: 'DD. MMM YYYY',
    'nb-NO': 'DD MMM YYYY',
    da: 'DD. MMM YYYY',
    'pt-PT': 'D MMM YYYY',
    ar: 'DD MMM YYYY',
  },
  MMM_DO: {
    en: 'MMM Do',
    de: 'Do MMM',
    el: 'D MMM',
    'fr-CA': 'D MMM',
    'es-US': 'D MMM.',
    tr: 'Do. MMM',
    hr: 'Do. MMM',
    'en-GB': 'D MMM',
    fr: 'D MMM',
    es: 'D MMM',
    it: 'D MMM',
    fi: 'Do. MMM',
    sk: 'Do. MMM.',
    pl: 'Do. MMM',
    nl: 'Do MMM',
    sv: 'Do MMM',
    cs: 'Do. MMM',
    'nb-NO': 'Do. MMM',
    da: 'Do. MMM',
    'pt-PT': 'D MMM',
    ar: 'MMM Do',
  },
  MMM_DO_YYYY: {
    en: 'MMM Do, YYYY',
    de: 'Do MMM YYYY',
    el: 'D MMM YYYY',
    'fr-CA': 'D MMM YYYY',
    'es-US': 'D MMM. YYYY',
    tr: 'Do. MMM YYYY',
    hr: 'Do. MMM YYYY',
    'en-GB': 'D MMM, YYYY',
    fr: 'D MMM YYYY',
    es: 'D MMM YYYY',
    it: 'D MMM YYYY',
    fi: 'Do. MMM YYYY',
    sk: 'Do. MMM. YYYY',
    pl: 'Do. MMM YYYY',
    nl: 'Do MMM YYYY',
    sv: 'D MMM YYYY',
    cs: 'Do. MMM YYYY',
    'nb-NO': 'Do. MMM YYYY',
    da: 'Do. MMM YYYY',
    'pt-PT': 'D MMM YYYY',
    ar: 'DD MMM YYYY',
  },
  MMM_DO_YYYY_H_MMA: {
    en: 'MMM Do, YYYY - h:mma',
    de: 'Do MMM YYYY - HH:mm',
    el: 'D MMM YYYY - HH:mm',
    'fr-CA': 'D MMM YYYY - HH:mm',
    'es-US': 'D MMM YYYY - HH:mm',
    tr: 'D MMM YYYY - HH.mm',
    hr: 'Do MMM YYYY - HH:mm',
    'en-GB': 'D MMM YYYY - HH:mm',
    fr: 'D MMM YYYY - HH:mm',
    es: 'D MMM YYYY - HH:mm',
    it: 'D MMM YYYY - HH:mm',
    fi: 'Do MMM YYYY - HH:mm',
    sk: 'D. M. YYYY - HH:mm',
    pl: 'Do MMM YYYY - HH:mm',
    nl: 'D MMM YYYY - HH:mm',
    sv: 'D MMM YYYY - HH:mm',
    cs: 'D. M. YYYY - HH:mm',
    'nb-NO': 'DD. MMM YYYY - HH:mm',
    da: 'Do MMM YYYY - HH:mm',
    'pt-PT': 'D MMM YYYY - HH:mm',
    ar: 'DD MMM YYYY - HH:mm',
  },
  MMMM_DO_YYYY_H_MMA: {
    en: 'MMMM Do, YYYY - h:mma',
    de: 'Do MMM YYYY - HH:mm',
    el: 'D MMM YYYY - HH:mm',
    'fr-CA': 'D MMM YYYY - HH:mm',
    'es-US': 'D MMM YYYY - HH:mm',
    tr: 'D MMMM YYYY - HH.mm',
    hr: 'Do MMM YYYY. - HH:mm',
    'en-GB': 'D MMM YYYY - HH:mm',
    fr: 'D MMM YYYY - HH:mm',
    es: 'D MMM YYYY - HH:mm',
    it: 'D MMM YYYY - HH:mm',
    fi: 'Do MMM YYYY - HH:mm',
    sk: 'D. M. YYYY - HH:mm',
    pl: 'Do MMM YYYY - HH:mm',
    nl: 'D MMM YYYY - HH:mm',
    sv: 'D MMM YYYY - HH:mm',
    cs: 'D. M. YYYY - HH:mm',
    'nb-NO': 'DD. MMM YYYY - HH:mm',
    da: 'Do MMM YYYY - HH:mm',
    'pt-PT': 'D MMM YYYY - HH:mm',
    ar: 'DD MMM YYYY - HH:mm',
  },
  MMM_DD_YYYY_HH_MM_A: {
    en: 'MMM/DD YYYY hh:mm a',
    de: 'MMM/DD YYYY H:mm a',
    el: 'MMM/DD YYYY H:mm a',
    'fr-CA': 'MMM/DD YYYY H:mm a',
    'es-US': 'MMM/DD YYYY hh:mm a',
    tr: 'MMM/DD YYYY H:mm a',
    hr: 'MMM/DD YYYY H:mm a',
    'en-GB': 'MMM/DD YYYY H:mm a',
    fr: 'MMM/DD YYYY H:mm a',
    es: 'MMM/DD YYYY H:mm a',
    it: 'MMM/DD YYYY H:mm a',
    fi: 'MMM/DD YYYY H:mm a',
    sk: 'MMM/DD YYYY H:mm a',
    pl: 'MMM/DD YYYY H:mm a',
    nl: 'MMM/DD YYYY H:mm a',
    sv: 'MMM/DD YYYY H:mm a',
    cs: 'MMM/DD YYYY H:mm a',
    'nb-NO': 'MMM/DD YYYY H:mm a',
    da: 'MMM/DD YYYY H:mm a',
    'pt-PT': 'MMM/DD YYYY H:mm a',
    ar: 'DD MMM YYYY H:mm a',
  },
  MMM_D_YYYY_H_MM_A: {
    en: 'MMM D, YYYY h:mm A',
    de: 'MMM/DD YYYY H:mm A',
    el: 'MMM/DD YYYY H:mm A',
    'fr-CA': 'MMM/DD YYYY H:mm A',
    'es-US': 'MMM/DD YYYY hh:mm A',
    tr: 'MMM/DD YYYY H:mm A',
    hr: 'MMM/DD YYYY H:mm A',
    'en-GB': 'MMM/DD YYYY H:mm A',
    fr: 'MMM/DD YYYY H:mm A',
    es: 'MMM/DD YYYY H:mm A',
    it: 'MMM/DD YYYY H:mm A',
    fi: 'MMM/DD YYYY H:mm A',
    sk: 'MMM/DD YYYY H:mm A',
    pl: 'MMM/DD YYYY H:mm A',
    nl: 'MMM/DD YYYY H:mm A',
    sv: 'MMM/DD YYYY H:mm A',
    cs: 'MMM/DD YYYY H:mm A',
    'nb-NO': 'MMM/DD YYYY H:mm A',
    da: 'MMM/DD YYYY H:mm A',
    'pt-PT': 'MMM/DD YYYY H:mm A',
    ar: 'DD MMM YYYY HH:mm',
  },
  MMMM_D_YYYY: {
    en: 'MMMM D, YYYY',
    de: 'D. MMM YYYY',
    el: 'D MMMM YYYY',
    'fr-CA': 'D MMM YYYY',
    'es-US': 'D MMMM YYYY',
    tr: 'D MMM YYYY',
    hr: 'D MMM YYYY',
    'en-GB': 'D MMMM, YYYY',
    fr: 'D MMMM YYYY',
    es: 'D [de] MMMM [de] YYYY',
    it: 'D MMMM YYYY',
    fi: 'D MMM YYYY',
    sk: 'D MMM. YYYY',
    pl: 'D MMM YYYY',
    nl: 'D MMM YYYY',
    sv: 'D MMM YYYY',
    cs: 'D MMM YYYY',
    'nb-NO': 'DD. MMM YYYY',
    da: 'D. MMM YYYY',
    'pt-PT': 'D [de] MMMM [de] YYYY',
    ar: 'YYYY MMMM DD',
  },
  DDDD_MMMM_D_YYYY: {
    en: 'dddd, MMMM D, YYYY',
    de: 'dddd, D. MMM YYYY',
    el: 'dddd, D MMMM YYYY',
    'fr-CA': 'dddd, D MMM YYYY',
    'es-US': 'dddd, D MMMM YYYY',
    tr: 'dddd, D MMM YYYY',
    hr: 'dddd, D MMM YYYY',
    'en-GB': 'dddd, D MMMM, YYYY',
    fr: 'dddd, D MMMM YYYY',
    es: 'dddd, D [de] MMMM [de] YYYY',
    it: 'dddd, D MMMM YYYY',
    fi: 'dddd, D MMM YYYY',
    sk: 'dddd, D MMM. YYYY',
    pl: 'dddd, D MMM YYYY',
    nl: 'dddd, D MMM YYYY',
    sv: 'dddd, D MMM YYYY',
    cs: 'dddd, D MMM YYYY',
    'nb-NO': 'dddd, DD. MMM YYYY',
    da: 'dddd, D. MMM YYYY',
    'pt-PT': 'dddd, D [de] MMMM [de] YYYY',
    ar: 'dddd, YYYY MMMM DD',
  },
  YYYY_MM_DD: {
    en: 'YYYY-MM-DD',
    de: 'YYYY-MM-DD',
    el: 'YYYY-MM-DD',
    'fr-CA': 'YYYY-MM-DD',
    'es-US': 'YYYY-MM-DD',
    tr: 'YYYY-MM-DD',
    hr: 'YYYY-MM-DD',
    'en-GB': 'YYYY-MM-DD',
    fr: 'YYYY-MM-DD',
    es: 'YYYY-MM-DD',
    it: 'YYYY-MM-DD',
    fi: 'YYYY-MM-DD',
    sk: 'YYYY-MM-DD',
    pl: 'YYYY-MM-DD',
    nl: 'YYYY-MM-DD',
    sv: 'YYYY-MM-DD',
    cs: 'YYYY-MM-DD',
    'nb-NO': 'YYYY-MM-DD',
    da: 'YYYY-MM-DD',
    'pt-PT': 'YYYY-MM-DD',
    ar: 'YYYY-MM-DD',

  },
  HH_MM_A_MMM_D_YYYY: {
    en: 'h:mm a MMM D, YYYY',
    de: 'HH:mm D. MMM YYYY',
    el: 'D MMM YYYY',
    'fr-CA': 'HH:mm D MMM YYYY',
    'es-US': 'h:mm a D MMM YYYY',
    tr: 'HH:mm D MMM YYYY',
    hr: 'HH:mm D MMM YYYY',
    'en-GB': 'HH:mm D MMM, YYYY',
    fr: 'HH:mm D MMM YYYY',
    es: 'HH:mm D [de] MMM [de] YYYY',
    it: 'HH:mm D MMM YYYY',
    fi: 'HH:mm D MMM YYYY',
    sk: 'HH:mm D MMM YYYY',
    pl: 'HH:mm D MMM YYYY',
    nl: 'HH:mm D MMM YYYY',
    sv: 'HH:mm D MMM YYYY',
    cs: 'HH:mm D MMM YYYY',
    'nb-NO': 'HH:mm D MMM YYYY',
    da: 'HH:mm D. MMM YYYY',
    'pt-PT': 'HH:mm D [de] MMM [de] YYYY',
    ar: 'HH:mm DD MMM YYYY',
  },
  ONE_PAGER_DEVICES_SYNC_DATE_FORMAT: {
    en: 'h:mm A, MMM D, YYYY',
    de: 'HH:mm D. MMM YYYY',
    el: 'D MMM YYYY',
    'fr-CA': 'HH:mm D MMM YYYY',
    'es-US': 'h:mm A, MMM D, YYYY',
    tr: 'HH:mm D MMM YYYY',
    hr: 'HH:mm D MMM YYYY',
    'en-GB': 'HH:mm D MMM, YYYY',
    fr: 'HH:mm D MMM YYYY',
    es: 'HH:mm D [de] MMM [de] YYYY',
    it: 'HH:mm D MMM YYYY',
    fi: 'HH:mm D MMM YYYY',
    sk: 'HH:mm D MMM YYYY',
    pl: 'HH:mm D MMM YYYY',
    nl: 'HH:mm D MMM YYYY',
    sv: 'HH:mm D MMM YYYY',
    cs: 'HH:mm D MMM YYYY',
    'nb-NO': 'HH:mm D MMM YYYY',
    da: 'HH:mm D. MMM YYYY',
    'pt-PT': 'HH:mm D [de] MMM [de] YYYY',
    ar: 'HH:mm, DD MMM YYYY',
  },
  RANGE_SAME_MONTH_START: {
    en: 'MMM Do',
    de: 'DD.',
    el: 'DD.',
    'fr-CA': 'DD',
    'es-US': 'DD',
    tr: 'DD.',
    hr: 'DD.',
    'en-GB': 'DD',
    fr: 'DD',
    es: 'DD',
    it: 'DD',
    fi: 'DD.',
    sk: 'DD.',
    pl: 'DD.',
    nl: 'DD',
    sv: 'DD',
    cs: 'DD.',
    'nb-NO': 'DD',
    da: 'DD.',
    'pt-PT': 'DD',
    ar: 'DD',
  },
  RANGE_SAME_MONTH_END: {
    en: 'Do, YYYY',
    de: 'DD. MMM YYYY',
    el: 'DD MMM YYYY',
    'fr-CA': 'DD MMM YYYY',
    'es-US': 'DD MMM YYYY',
    tr: 'DD. MMM YYYY',
    hr: 'DD. MMM YYYY',
    'en-GB': 'DD MMM, YYYY',
    fr: 'DD MMM YYYY',
    es: 'DD MMM YYYY',
    it: 'DD MM YYYY',
    fi: 'DD. MMM YYYY',
    sk: 'DD. MMM. YYYY',
    pl: 'DD. MMM YYYY',
    nl: 'DD MMM YYYY',
    sv: 'DD MM YYYY',
    cs: 'DD. MMM YYYY',
    'nb-NO': 'DD. MMM YYYY',
    da: 'DD. MMM YYYY',
    'pt-PT': 'DD MMM YYYY',
    ar: 'DD MMM YYYY',
  },
  RANGE_CROSS_MONTH_START: {
    en: 'MMM Do',
    de: 'DD. MMM',
    el: 'DD MMM',
    'fr-CA': 'DD MMM',
    'es-US': 'DD MMM',
    tr: 'DD. MMM',
    hr: 'DD. MMM',
    'en-GB': 'DD MMM',
    fr: 'DD MMM',
    es: 'DD MMM',
    it: 'DD MMM',
    fi: 'DD. MMM',
    sk: 'DD. MMM.',
    pl: 'DD. MMM',
    nl: 'DD MMM',
    sv: 'DD MMM',
    cs: 'DD. MMM',
    'nb-NO': 'DD. MMM',
    da: 'DD. MMM',
    'pt-PT': 'DD MMM',
    ar: 'DD MMM',
  },
  RANGE_CROSS_MONTH_END: {
    en: 'MMM Do, YYYY',
    de: 'DD. MMM YYYY',
    el: 'DD MMM YYYY',
    'fr-CA': 'DD MMM YYYY',
    'es-US': 'DD MMM YYYY',
    tr: 'DD. MMM YYYY',
    hr: 'DD. MMM YYYY',
    'en-GB': 'DD MMM, YYYY',
    fr: 'DD MMM YYYY',
    es: 'DD MMM YYYY',
    it: 'DD MMM YYYY',
    fi: 'DD. MMM YYYY',
    sk: 'DD. MMM. YYYY',
    pl: 'DD. MMM YYYY',
    nl: 'DD MMM YYYY',
    sv: 'DD MMM YYYY',
    cs: 'DD. MMM YYYY',
    'nb-NO': 'DD. MMM YYYY',
    da: 'DD. MMM YYYY',
    'pt-PT': 'DD MMM YYYY',
    ar: 'DD MMM YYYY',
  },
  RANGE_HOURS_END: {
    en: '13',
    de: '25',
    el: '25',
    'fr-CA': '25',
    'es-US': '25',
    tr: '25',
    hr: '25',
    'en-GB': '25',
    fr: '25',
    es: '25',
    it: '25',
    fi: '25',
    sk: '25',
    pl: '25',
    nl: '25',
    sv: '25',
    cs: '25',
    'nb-NO': '25',
    da: '25',
    'pt-PT': '25',
    ar: '25',
  },
  HOURS_AND_MINUTES: {
    en: 'HH:mm',
    de: 'HH:mm',
    el: 'HH:mm',
    'fr-CA': 'HH:mm',
    'es-US': 'HH:mm',
    tr: 'HH:mm',
    hr: 'HH:mm',
    'en-GB': 'HH:mm',
    fr: 'HH:mm',
    es: 'HH:mm',
    it: 'HH:mm',
    fi: 'HH:mm',
    sk: 'HH:mm',
    pl: 'HH:mm',
    nl: 'HH:mm',
    sv: 'HH:mm',
    cs: 'HH:mm',
    'nb-NO': 'HH:mm',
    da: 'HH:mm',
    'pt-PT': 'HH:mm',
    ar: 'HH:mm',
  },
  CALENDAR_HOURS: {
    en: 'h A',
    de: 'HH:mm',
    el: 'HH:mm',
    'fr-CA': 'HH:mm',
    'es-US': 'h A',
    tr: 'HH:mm',
    hr: 'HH:mm',
    'en-GB': 'HH:mm',
    fr: 'HH:mm',
    es: 'HH:mm',
    it: 'HH:mm',
    fi: 'HH:mm',
    sk: 'HH:mm',
    pl: 'HH:mm',
    nl: 'HH:mm',
    sv: 'HH:mm',
    cs: 'HH:mm',
    'nb-NO': 'HH:mm',
    da: 'HH:mm',
    'pt-PT': 'HH:mm',
    ar: 'HH:mm',
  },
};

export const TIME_FORMATS = (() => new Proxy(TIME_FORMAT_CONFIG, {
  get(target, property) {
    const language = i18next.language || 'en';
    if ((property in target) && (language in target[property])) {
      return target[property][language];
    }
    throw new Error(`Time format '${property}' in ${language} is not defined`);
  },
}))();

export const MASK_FORMAT_CONFIG = {
  SEARCH_MASK: {
    en: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
    de: [/\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, /\d/, /\d/],
    el: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
    'fr-CA': [/\d/, /\d/, /\d/, /\d/, ' ', '-', ' ', /\d/, /\d/, ' ', '-', ' ', /\d/, /\d/],
    'es-US': [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
    hr: [/\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, /\d/, /\d/],
    tr: [/\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, /\d/, /\d/],
    'en-GB': [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
    fr: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
    es: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
    it: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
    fi: [/\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, /\d/, /\d/],
    sk: [/\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, /\d/, /\d/],
    pl: [/\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, /\d/, /\d/],
    nl: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
    sv: [/\d/, /\d/, /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/],
    cs: [/\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, /\d/, /\d/],
    'nb-NO': [/\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, /\d/, /\d/],
    da: [/\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, ' ', '.', ' ', /\d/, /\d/, /\d/, /\d/],
    'pt-PT': [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
    ar: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
  },
};

export const MASK_FORMATS = (() => {
  if (typeof Proxy === 'undefined') {
    const locale = i18next.language;
    const MASK_FORMAT_LOCALIZED = {};

    Object.keys(MASK_FORMAT_CONFIG).forEach((key) => {
      MASK_FORMAT_LOCALIZED[key] = MASK_FORMAT_CONFIG[key][locale];
    });
    return MASK_FORMAT_LOCALIZED;
  }
  return new Proxy(MASK_FORMAT_CONFIG, {
    get(target, property) {
      const language = i18next.language || 'en';
      if ((property in target) && (language in target[property])) {
        return target[property][language];
      }
      throw new Error(`Mask format '${property}' in ${language} is not defined`);
    },
  });
})();

export const roundToPrefix = (options) => {
  const { suffixLetter, step, numberFormatter } = options;
  return (value) => {
    const divided = (value / 1.0) / step;
    return `${numberFormatter(divided)}${suffixLetter}`;
  };
};

export const roundToKilosOneDecimal = roundToPrefix({
  suffixLetter: 'k',
  step: 1000,
  numberFormatter: numberFormatterOneDecimal,
});

export const localizedLowerCase = (string) => {
  const lang = i18next.language;
  return lang === 'tr' ? string.toLocaleLowerCase(lang) : string.toLowerCase();
};

export const localizedUpperCase = (string) => {
  const lang = i18next.language;
  return lang === 'tr' ? string.toLocaleUpperCase(lang) : string.toUpperCase();
};

export const localizedPercentage = (val) => {
  const isRTL = getTextDirection() === 'rtl';
  let percentText = '%';
  if (i18next.language === 'fr-CA') {
    percentText = ' %';
  }
  return isRTL ? `${percentText}${val}` : `${val}${percentText}`;
};
