export const WEEK_VIEW_MEDIUM = 'weekViewMedium';
export const WEEK_VIEW_PDF_SMALL = 'weekViewPdfSmall';
export const WEEK_VIEW_PDF_MEDIUM = 'weekViewPdfMedium';
export const WEEK_VIEW_PDF_LARGE = 'weekViewPdfLarge';

const WEEK_VIEW_MEDIUM_CONFIG = {
  heights: {
    header: 33,
    glucose: 80, // 81px, -1px due to border
    carbs: 39, // 40px, -1px due to border
    insulin: 44, // 45px, -1px due to border
    basal: 44, // 45px, -1px due to border
    system: 39, // 40px, -1px due to border
  },
  pointStyle: {
    carbs: 'top: -3px;',
  },
};

const WEEK_VIEW_MEDIUM_PDF_CONFIG = {
  ...WEEK_VIEW_MEDIUM_CONFIG,
  heights: {
    ...WEEK_VIEW_MEDIUM_CONFIG.heights,
    stats: 39, // 40px, -1px due to border
  },
};

export const GRAPH_CONFIG = {
  [WEEK_VIEW_MEDIUM]: WEEK_VIEW_MEDIUM_CONFIG,
  [WEEK_VIEW_PDF_SMALL]: {
    ...WEEK_VIEW_MEDIUM_CONFIG,
    heights: {
      ...WEEK_VIEW_MEDIUM_CONFIG.heights,
      glucose: 54, // 55px, -1px due to border
      insulin: 39, // 40px, -1px due to border
    },
    legendPositioning: {
      maxGraphsPerPage: 4,
      samePageLegendMaxGraphs: 3,
    },
  },
  [WEEK_VIEW_PDF_MEDIUM]: {
    ...WEEK_VIEW_MEDIUM_PDF_CONFIG,
    heights: {
      ...WEEK_VIEW_MEDIUM_PDF_CONFIG.heights,
      glucose: 156, // 157px, -1px due to border
    },
    legendPositioning: {
      maxGraphsPerPage: 3,
      samePageLegendMaxGraphs: 2,
    },
  },
  [WEEK_VIEW_PDF_LARGE]: {
    ...WEEK_VIEW_MEDIUM_PDF_CONFIG,
    heights: {
      ...WEEK_VIEW_MEDIUM_PDF_CONFIG.heights,
      glucose: 386, // 387px, -1px due to border
    },
    legendPositioning: {
      maxGraphsPerPage: 2,
      samePageLegendMaxGraphs: 1,
    },
  },
};
