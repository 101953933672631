import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendGuardianEmail } from '~/redux/thunks/users';
import UserHelper from '~/redux/modules/users/UserHelper';
import GuardianConsentPending from './GuardianConsentPending';

const mapStateToProps = (state) => {
  const currentUser = UserHelper.currentUser(state);

  return {
    isMinor: currentUser.isMinor,
    guardianEmail: currentUser.guardianEmail,
    guardianConsented: currentUser.guardianConsented !== undefined ?
      currentUser.guardianConsented : true,
    sendGuardianEmailStatus: state.users.sendGuardianEmailStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ sendGuardianEmail }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuardianConsentPending);
